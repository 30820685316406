import { ReportChangeEventSource } from '@/generated/enums'
import { ZERO_UUID } from '@/utils/constants'

export const handleSelfUnassignAll = async (
  getAssignedAlerts,
  updateSpoofingReportsMutation,
  refreshTable,
  userId,
  toast,
) => {
  try {
    const { data } = await getAssignedAlerts({
      variables: {
        assigneeId: userId,
      },
    })

    if (data?.assigned_alerts?.length > 0) {
      const alertIds = data.assigned_alerts.map((report) => report.id)
      await updateSpoofingReportsMutation({
        variables: {
          input: {
            report_ids: alertIds,
            update_source: ReportChangeEventSource.UI,
            internal_assignee_id: ZERO_UUID,
            is_internal: true,
          },
        },
      })
      refreshTable()
      toast({
        title: 'Alerts Unassigned',
        description: `Successfully unassigned ${alertIds.length} alerts`,
        status: 'success',
        isClosable: true,
      })
    } else {
      toast({
        title: 'No Alerts to Unassign',
        description: 'You have no alerts currently assigned to you',
        status: 'info',
        isClosable: true,
      })
    }
  } catch (error) {
    toast({
      title: 'Error unassigning alerts',
      description: error.message,
      status: 'error',
      isClosable: true,
    })
  }
}
