import { ReportFilter } from '../../hooks/report_table_filters'
import { useClearReportSearch } from '../../pages/reports'

import { DeletableButton } from '../doppel_design/deletable_button'

const SearchFilterToken = ({
  setSelectedReportFilters,
  selectedReportFilters,
  filterType,
  dropdownIcon,
}) => {
  const maybeFilterSelected = selectedReportFilters.filter(
    (filter: ReportFilter) => filter.filterType == filterType,
  )
  const isFilterSelected = maybeFilterSelected.length != 0

  const clearReportSearch = useClearReportSearch()

  return (
    <DeletableButton
      icon={dropdownIcon}
      label={isFilterSelected ? maybeFilterSelected[0].value : filterType}
      onDelete={clearReportSearch}
    ></DeletableButton>
  )
}

export default SearchFilterToken
