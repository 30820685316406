import {
  Flex,
  Box,
  HStack,
  useDisclosure,
  MenuButton,
  Checkbox,
  VStack,
  Heading,
  Stack,
  ButtonGroup,
  useToast,
} from '@chakra-ui/react'
import ReportsSearchBar from '@/components/collection_reports_table/reports_search_bar'
import RefreshButton from '@/components/tables/refresh_button'
import UploadNewReportContainer from '@/components/web2/upload_new_report_container'
import { useAlertsTable } from '@/hooks/alerts_table'
import { useRequestCSVExport } from '@/components/csv/request_csv_export_button'
import { Dropdown } from '@/components/doppel_design/dropdown'
import { IoEllipsisVertical } from 'react-icons/io5'
import DoppelIconButton from '@/components/shared/doppel_icon_button'
import DoppelModal from '@/components/doppel_design/doppel_modal'
import BulkAssignAlertsModal from '@/components/internal/bulk_assign_alerts_modal'
import { useIsUserAuthorizedForAction } from '@/utils/permissions'
import { Permission } from '@/generated/enums'
import { getStatusLabel, ReportFilterType, ReportType } from '@/utils/constants'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { userProductsAtom } from '@/atoms/permissions'
import BulkMoveAlertsModal from '@/components/alerts/bulk_move_alerts_modal'
import {
  useReportStatuses,
  useSearchKey,
  useSelectedAutocompleteReportId,
  useSelectedReportFilters,
  useSelectedReportStatus,
  useSelectedReportType,
  useSelectedSortingMechanism,
} from '@/pages/reports'
import { useRouter } from 'next/router'
import {
  getOrgData,
  useIsEmployeeView,
  useOrgID,
  useUserID,
} from '@/hooks/id_token_claims'
import {
  useSelectedPage,
  useSelectedPageSize,
  useSelectedSpoofReport,
} from '@/hooks/spoof_report_table_filters'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { alertsTableResultAtom } from '@/utils/detailed_view/alerts_table_context'
import { useEffect, useState } from 'react'
import UnifiedReportsTableFilters from '../admin/unified_reports_table_filters'
import SpoofReportsTableFilterBar from './spoof_reports_table_filter_bar'
import UploadNewBulkReportContainer from './upload_new_bulk_report_container'
import {
  useGetAssignedAlertsLazyQuery,
  useUpdateSpoofingReportsMutation,
} from '@/generated/graphql'
import { handleSelfUnassignAll } from '@/utils/alert_assignment_utils'

type AlertsToolbarProps = {
  isOrgUnifiedView?: boolean
  displayAlertsPageActions?: boolean
}

export const AlertsToolbar: React.FC<AlertsToolbarProps> = ({
  isOrgUnifiedView,
  displayAlertsPageActions = false,
}) => {
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const router = useRouter()
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [userId] = useUserID()
  const [selectedPage, setSelectedPage] = useSelectedPage()
  const [selectedPageSize] = useSelectedPageSize()
  const [selectedSpoofReport, setSelectedSpoofReport] = useSelectedSpoofReport()
  const [reportStatuses, setReportStatuses] = useReportStatuses()
  const [selectedReportStatus, setSelectedReportStatus] = useSelectedReportStatus()
  const [selectedReportType] = useSelectedReportType()
  const [selectedSortingMechanism, setSelectedSortingMechanism] =
    useSelectedSortingMechanism()
  const [isEmployeeView] = useIsEmployeeView()
  const [searchKey] = useSearchKey()
  const [selectedAutocompleteReportId] = useSelectedAutocompleteReportId()
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  const setAlertsTableResult = useSetAtom(alertsTableResultAtom)
  const isUserAuthorizedForCsvExport = useIsUserAuthorizedForAction(
    Permission.ALERT_CSV_READ,
  )
  const userProducts = useAtomValue(userProductsAtom)
  const isRbacEnabled = useFeatureFlagEnabled('rbac')

  const [availableQueues] = useReportStatuses()
  const [selectedQueues, setSelectedQueues] = useState(availableQueues)
  useEffect(() => setSelectedQueues(availableQueues), [availableQueues])

  const [getAssignedAlerts] = useGetAssignedAlertsLazyQuery()
  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation()
  const toast = useToast()

  const handleSelfUnassignAllClick = async () => {
    await handleSelfUnassignAll(
      getAssignedAlerts,
      updateSpoofingReportsMutation,
      refreshTable,
      userId,
      toast,
    )
  }

  const { autocompleteClickCallback, createSpoofingReportsWhereClause, refreshTable } =
    useAlertsTable(
      isOrgUnifiedView,
      selectedReportFilters,
      router,
      orgId,
      orgData,
      userId,
      selectedPage,
      setSelectedPage,
      selectedSpoofReport,
      setSelectedSpoofReport,
      setReportStatuses,
      selectedReportStatus,
      selectedSortingMechanism,
      isEmployeeView,
      searchKey,
      selectedAutocompleteReportId,
      selectedReportExposureType,
      setAlertsTableResult,
      selectedReportType,
      selectedPageSize,
      userProducts,
      isRbacEnabled,
    )

  const {
    isOpen: isOpenCSV,
    onOpen: onOpenCSV,
    onClose: onCloseCSV,
    onSubmit: onSubmitCSV,
    currentProduct,
  } = useRequestCSVExport(
    createSpoofingReportsWhereClause(isOrgUnifiedView),
    selectedReportType,
    availableQueues,
    selectedQueues,
    selectedReportFilters,
    orgData,
    isOrgUnifiedView,
  )

  const {
    isOpen: isOpenBulkAssign,
    onOpen: onOpenBulkAssign,
    onClose: onCloseBulkAssign,
  } = useDisclosure()

  const {
    isOpen: isOpenBulkMove,
    onOpen: onOpenBulkMove,
    onClose: onCloseBulkMove,
  } = useDisclosure()

  const UploadAlertButtons = () => {
    const isUserAuthorizedForAlertUpload = useIsUserAuthorizedForAction(
      Permission.ALERT_CREATE,
    )

    if (!isUserAuthorizedForAlertUpload) return null

    if (selectedReportType === ReportType.DARK_WEB) {
      return (
        <ButtonGroup isAttached>
          <UploadNewReportContainer refreshFunc={refreshTable} />

          <UploadNewBulkReportContainer refreshFunc={refreshTable} />
        </ButtonGroup>
      )
    }
    return (
      <>
        <UploadNewReportContainer refreshFunc={refreshTable} />
      </>
    )
  }

  const actionInfoMapping = Object.fromEntries(
    [
      isUserAuthorizedForCsvExport
        ? [
            'request_csv_export',
            { label: 'Request CSV Export', action: onOpenCSV, isInternal: false },
          ]
        : null,
      isEmployeeView
        ? [
            'bulk_assign',
            { label: 'Bulk Assign Alerts', action: onOpenBulkAssign, isInternal: true },
          ]
        : null,
      isEmployeeView
        ? [
            'self_unassign_all',
            {
              label: 'Self Unassign All Alerts',
              action: handleSelfUnassignAllClick,
              isInternal: true,
            },
          ]
        : null,
      isEmployeeView
        ? [
            'bulk_move',
            { label: 'Bulk Move Alerts', action: onOpenBulkMove, isInternal: true },
          ]
        : null,
    ].filter(Boolean),
  )

  const csvModalBody = (
    <Stack spacing={8} width="100%">
      <Box minW="200px">
        <Heading as="h3" mb={4} size="sm">
          Select queues to include
        </Heading>

        <VStack align="start">
          {availableQueues.map((status) => (
            <Checkbox
              isChecked={selectedQueues.includes(status)}
              key={status}
              onChange={(e) => {
                // Toggle the selected status
                if (e.target.checked) {
                  setSelectedQueues([...selectedQueues, status])
                } else {
                  setSelectedQueues(selectedQueues.filter((s) => s !== status))
                }
              }}
            >
              {getStatusLabel(status, currentProduct)}
            </Checkbox>
          ))}
        </VStack>
      </Box>

      <Box>
        <Heading as="h3" mb={4} size="sm">
          Select filters to apply
        </Heading>

        {isOrgUnifiedView && <UnifiedReportsTableFilters />}

        <Box mb={4} />

        <SpoofReportsTableFilterBar
          isOrgUnifiedView={isOrgUnifiedView}
          selectedOrgIds={selectedReportFilters
            .filter((filter) => filter.filterType === ReportFilterType.Organization)
            .map((filter) => filter.value)}
          showAllFilters
          showSort={false}
        />
      </Box>
    </Stack>
  )

  return (
    <Flex alignItems="center" justifyContent="space-between" ml={7} width="100%">
      {/* Left Side: Search Bar */}
      <Box flex="1" maxW="300px">
        <ReportsSearchBar
          autocompleteClickCallback={autocompleteClickCallback}
          isOrgUnifiedView={isOrgUnifiedView}
        />
      </Box>

      {/* Middle: Filters and Buttons */}
      <HStack spacing={2}>
        <RefreshButton refreshFunc={refreshTable} />

        {!isOrgUnifiedView && displayAlertsPageActions && <UploadAlertButtons />}

        {displayAlertsPageActions && actionInfoMapping && (
          <Dropdown
            customMenuButton={
              <DoppelIconButton
                as={MenuButton}
                h={'40px'}
                icon={<IoEllipsisVertical />}
                w={'40px'}
              />
            }
            items={Object.keys(actionInfoMapping).map((key) => ({
              label: actionInfoMapping[key].label,
              value: key,
              isInternal: actionInfoMapping[key].isInternal,
            }))}
            onSelect={(value) => actionInfoMapping[value]?.action()}
          />
        )}
      </HStack>

      <DoppelModal
        body={csvModalBody}
        isOpen={isOpenCSV}
        onClose={onCloseCSV}
        primaryAction={onSubmitCSV}
        primaryLabel="Request Export"
        size="2xl"
        title={`Request CSV Export for ${
          isOrgUnifiedView ? 'All Organizations' : orgData.name
        }${currentProduct ? ` | ${currentProduct}` : ''}`}
      ></DoppelModal>

      <BulkAssignAlertsModal
        isOpen={isOpenBulkAssign}
        onClose={onCloseBulkAssign}
        refreshFunc={refreshTable}
      />

      <BulkMoveAlertsModal
        isOpen={isOpenBulkMove}
        onClose={onCloseBulkMove}
        refreshFunc={refreshTable}
      />
    </Flex>
  )
}

export default AlertsToolbar
