import { Button } from '@chakra-ui/react'
import {
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_BUTTON_GREY,
  FONT_SIZE_MEDIUM,
  DOPPEL_INTERNAL_PURPLE_SHADE,
  DOPPEL_BUTTON_GREY_SHADE,
  BUTTON_COLOR_PRIMARY,
  BUTTON_COLOR_PRIMARY_SHADE,
  DOPPEL_TEXT_BLACK,
  DOPPEL_TEXT_WHITE,
} from '../../utils/style'

const getColorScheme = (isInternal: boolean, isPrimaryColor: boolean) => {
  if (isInternal) {
    return {
      bg: DOPPEL_INTERNAL_PURPLE,
      hover: DOPPEL_INTERNAL_PURPLE_SHADE,
      text: DOPPEL_TEXT_WHITE,
    }
  } else if (isPrimaryColor) {
    return {
      bg: BUTTON_COLOR_PRIMARY,
      hover: BUTTON_COLOR_PRIMARY_SHADE,
      text: DOPPEL_TEXT_BLACK,
    }
  } else {
    return {
      bg: DOPPEL_BUTTON_GREY,
      hover: DOPPEL_BUTTON_GREY_SHADE,
      text: DOPPEL_TEXT_WHITE,
    }
  }
}

const DoppelPrimaryButton = ({
  children,
  isInternal = false,
  isPrimaryColor = false,
  isDisabled = false,
  ...props
}) => {
  const {
    bg: bgColor,
    hover: hoverColor,
    text: textColor,
  } = getColorScheme(isInternal, isPrimaryColor)

  const buttonProps = {
    ...props,
    isDisabled: isDisabled, // Chakra UI's prop
    disabled: isDisabled, // HTML attribute
    'aria-disabled': isDisabled, // ARIA attribute for accessibility
  }

  return (
    <Button
      _hover={{ background: hoverColor }}
      as={Button}
      bgColor={bgColor}
      fontSize={FONT_SIZE_MEDIUM}
      height={9}
      minWidth="80px"
      textColor={textColor}
      {...buttonProps}
    >
      {children}
    </Button>
  )
}

export default DoppelPrimaryButton
