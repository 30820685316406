import { atom, PrimitiveAtom } from 'jotai'

// enable alert details page to switch between alert results,
// after navigating from table view to detailed view
export type AlertsTableResult = {
  ids: string[]
  internal_ids: string[]
  offset: number
  previousPage: string
  rowSelectionState?: Record<string, boolean>
}

// NOTE: if we run into this state unexpectedly persisting, look into
// binding this atom to a new store/provider under /alerts

// a null nav state represents no table context
// e.g. loading detailed view directly from the URL
export const alertsTableResultAtom = atom<AlertsTableResult | null>(
  null,
) as PrimitiveAtom<AlertsTableResult | null>
// need "as" because atom infers type wrong: https://github.com/pmndrs/jotai/issues/550#issuecomment-877777563
