export const DOPPEL_FONT_FAMILY_MONO = `'Inter', monospace`
export const DOPPEL_FONT_FAMILY_BODY = `'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`
export const DOPPEL_FONT_FAMILY_HEADING = `'Inter', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"`

export const FONT_SIZE_SMALL = '12px'
export const FONT_SIZE_MEDIUM = '14px'
export const FONT_SIZE_LARGE = '15px' // temp value; 16px in figma

export const DOPPEL_INTERNAL_PURPLE = '#806584'
export const DOPPEL_INTERNAL_PURPLE_SHADE = '#B78DB7'
export const DOPPEL_TERMINAL_BLUE = '#142349'
export const DOPPEL_TERMINAL_BLUE_SHADE = '#060D20'

export const DOPPEL_CYBER_BLUE = '#336AEA'
export const DOPPEL_CYBER_BLUE_SHADE = '#3453B3'

export const DOPPEL_CLOUD_BLUE = '#3FAEFF'
export const DOPPEL_CLOUD_BLUE_SHADE = '#2F9EEE'
export const DOPPEL_TEAL_BLUE = '#449CDB'

export const DOPPEL_WHITE = '#EEEFFB'
export const DOPPEL_LIGHT_GREY = '#B1B1B1'
export const DOPPEL_GREY = '#E7E7E7'
export const DOPPEL_LINE_GREY = '#4B4C54'
export const DOPPEL_BORDER_GREY = '#727272'
export const DOPPEL_BUTTON_GREY = '#4F5055'
export const DOPPEL_BUTTON_GREY_SHADE = '#5F6167'
export const DOPPEL_CLICKABLE_GREY = '#edf2f6'
export const DOPPEL_CLICKABLE_GREY_SHADE = '#DDE2E7'
export const DOPPEL_HOVER = '#1A202C'
export const DOPPEL_PLACEHOLDER_GREY = '#9E9E9E'

export const DOPPEL_SECURE = '#727E9B'
export const DOPPEL_SECURE_SHADE = '#5C6989'

export const DOPPEL_BREACH_RED = '#D6524A'
export const DOPPEL_BREACH_RED_SHADE = '#A64540'

export const DOPPEL_FIREWALL_ORANGE = '#D18A38'

export const DOPPEL_YELLOW = '#FFBE3F'
export const DOPPEL_DIM_YELLOW = '#A98733'

export const DOPPEL_ENCRYPT_GREEN = '#229954'
export const DOPPEL_ENCRYPT_GREEN_SHADE = '#44EA73'
export const DOPPEL_SECONDARY_GREEN = '#44B163'
export const DOPPEL_SECONDARY_GREEN_SHADE = '#44B1631A'

export const DELIST_COLOR = '#58FF87'
export const MENU_LIST_ZINDEX = 10
export const DOPPEL_SUCCESS = '#4d875d'
export const DOPPEL_ERROR = '#FF4E4E'
export const DOPPEL_LINK = '#93B3F2'

export const DOPPEL_BLANK_SHADE = '#FFFFFF1A' // 10% opacity filter
export const DOPPEL_BLANK_BUTTON_HOVER = '#FFFFFF29' // 16% opacity filter (Chakra button default)
export const DOPPEL_TAG_OUTLINE = '#FEFEFFCC' // 80% opacity
export const DOPPEL_TEXT_WHITE = '#EEEFFB'
export const DOPPEL_TEXT_GREY = '#B3B5C2'
export const DOPPEL_TEXT_GREY_DIM = '#7E7F8D'
export const DOPPEL_TEXT_BLUE = '#0F52FF'
export const DOPPEL_TEXT_RED = '#E4544A'
export const DOPPEL_TEXT_INTERNAL_PURPLE_DARK = '#801384'
export const DOPPEL_TEXT_DARK = '#181921'

export const DOPPEL_ACTIVE_SILVER = '#8292A2'
export const DOPPEL_INACTIVE_GREY = '#2E373F'

export const DOPPEL_DIVIDER = '#0000001A'

export const DROPDOWN_OUTLINE = '#00000018'

export const DOPPEL_TAG_BACKGROUND_BLUE = '#0F52FF1A'
export const DOPPEL_TAG_WHITE = '#FEFEFF'
export const DOPPEL_TAG_YELLOW = '#F4C742'
export const DOPPEL_TAG_YELLOW_SHADE = '#F4C7421A'

// Rebranding. We should probably replace the original ones after... or start using these (?)

export const DOPPEL_BLACK = '#000000'
export const DOPPEL_GREY_950 = '#0E1114'
export const DOPPEL_GREY_925 = '#1D2228'
export const DOPPEL_GREY_900 = '#242B32'
export const DOPPEL_GREY_800 = '#2B343B'
export const DOPPEL_GREY_700 = '#323C45'
export const DOPPEL_GREY_600 = '#3D4954'
export const DOPPEL_GREY_500 = '#485663'
export const DOPPEL_GREY_400 = '#6D7882'
export const DOPPEL_GREY_300 = '#7F8992'
export const DOPPEL_GREY_200 = '#A3AAB1'
export const DOPPEL_GREY_100 = '#C8CCD0'
export const DOPPEL_GREY_75 = '#DADDE0'
export const DOPPEL_GREY_50 = '#EDEEEF'
export const DOPPEL_GREY_25 = '#F6F7F7'
export const DOPPEL_WHITE_NEW = '#FFFFFF'

export const DOPPEL_BLUE_100 = '#3365E1'
export const DOPPEL_BLUE_200 = '#003EDA'
export const DOPPEL_BLUE_300 = '#0032AE'
export const DOPPEL_BLUE_400 = '#001F6D'
export const DOPPEL_BLUE_500 = '#001341'

export const DOPPEL_CYAN_100 = '#BBFFFE'
export const DOPPEL_CYAN_200 = '#77FFFD'
export const DOPPEL_CYAN_300 = '#5FCCCA'
export const DOPPEL_CYAN_400 = '#244D4C'
export const DOPPEL_CYAN_500 = '#183333'

export const DOPPEL_YELLOW_100 = '#FBFFB0'
export const DOPPEL_YELLOW_200 = '#F7FF61'
export const DOPPEL_YELLOW_300 = '#C6CC4E'
export const DOPPEL_YELLOW_400 = '#4A4D1D'
export const DOPPEL_YELLOW_500 = '#313313'

export const DOPPEL_GREEN_100 = '#D5FFA0'
export const DOPPEL_GREEN_200 = '#AAFF42'
export const DOPPEL_GREEN_300 = '#88CC35'
export const DOPPEL_GREEN_400 = '#334D14'
export const DOPPEL_GREEN_500 = '#22330D'

export const DOPPEL_PINK_100 = '#FFADFB'
export const DOPPEL_PINK_200 = '#FF76F8'
export const DOPPEL_PINK_300 = '#CC5EC6'
export const DOPPEL_PINK_400 = '#4D234A'
export const DOPPEL_PINK_500 = '#331832'

export const DOPPEL_PROFILE_BACKGROUND = '#0776C7'

// Old constants which have been redefined with rebrand colors

export const DOPPEL_TEXT_BLACK = DOPPEL_BLACK

export const DOPPEL_DARK = DOPPEL_GREY_925
export const DOPPEL_DARK_GREY = DOPPEL_GREY_925
export const DOPPEL_DARK_SECONDARY = DOPPEL_GREY_900
export const DOPPEL_DARK_CLICKABLE = DOPPEL_GREY_800
export const DOPPEL_DARK_CLICKABLE_HOVER = DOPPEL_GREY_700

export const DOPPEL_OFFICIAL_BLUE = DOPPEL_CYAN_200
export const DOPPEL_OFFICIAL_BLUE_SHADE = DOPPEL_CYAN_300

export const BUTTON_COLOR_PRIMARY = DOPPEL_OFFICIAL_BLUE
export const BUTTON_COLOR_PRIMARY_SHADE = DOPPEL_OFFICIAL_BLUE_SHADE
