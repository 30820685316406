import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Textarea,
  useToast,
} from '@chakra-ui/react'
import React, { useState, useRef } from 'react'
import {
  Order_By,
  useGetSpoofingReportsLazyQuery,
  useUpdateSpoofingReportsMutation,
} from '@/generated/graphql'
import { getReportsWhereClauseFromExternalIds, parseExternalId } from '@/hooks/queries'
import { ReportChangeEventSource } from '@/generated/enums'
import DoppelModal from '@/components/doppel_design/doppel_modal'
import { getReportStatuses } from '@/utils/reports/report_utils'
import { getStatusLabel } from '@/utils/constants'
import { useSelectedReportType } from '@/pages/reports'
import { Dropdown } from '@/components/doppel_design/dropdown'
import { DOPPEL_LINE_GREY } from '@/utils/style'
import { useIsEmployeeView } from '@/hooks/id_token_claims'

type BulkMoveAlertsModalProps = {
  isOpen: boolean
  onClose: () => void
  refreshFunc: () => void
}

export default function BulkMoveAlertsModal({
  isOpen,
  onClose,
  refreshFunc,
}: BulkMoveAlertsModalProps) {
  const [selectedQueueState, setSelectedQueueState] = useState(null)
  const [alertIds, setAlertIds] = useState('')
  const [isEmployeeView] = useIsEmployeeView()
  const [selectedReportType] = useSelectedReportType()
  const [comments, setComments] = useState('')
  const [isInternal, setIsInternal] = useState(false)
  const toast = useToast()
  const firstField = useRef()

  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation({
    onCompleted: () => {
      refreshFunc()
      reset()
      onClose()
      toast({
        title: `Alerts moved successfully to ${getStatusLabel(
          selectedQueueState,
          selectedReportType,
        )}`,
        status: 'success',
        isClosable: true,
      })
    },
    onError: (error) => {
      toast({
        title: 'Error moving alerts',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
    },
  })

  const [getAlertByIds] = useGetSpoofingReportsLazyQuery()

  const statuses = getReportStatuses(isEmployeeView)

  const updateAlertFromExternalIds = async () => {
    try {
      const alertExternalIds = alertIds.split('\n').filter((url) => url.trim() !== '')
      const firstExternalId = parseExternalId(alertExternalIds[0])
      if (
        !alertExternalIds.every((externalId) =>
          externalId.startsWith(firstExternalId?.orgAbbr),
        )
      ) {
        throw new Error('All alerts need to belong to the same organization')
      }

      const whereClause = getReportsWhereClauseFromExternalIds(alertExternalIds)

      if (
        !whereClause ||
        !whereClause?.external_id ||
        !whereClause?.organization?.abbr_name
      ) {
        throw new Error(
          "Invalid alert ID provided, please make sure it's in the correct ID format.",
        )
      }

      const { data } = await getAlertByIds({
        variables: {
          spoofingReportsWhere: whereClause,
          orderBy: [{ created_at: Order_By.Asc }],
        },
      })

      if (data && data.spoofing_reports.length > 0) {
        const alertIds = data.spoofing_reports.map((report) => report.id)
        await updateSpoofingReportsMutation({
          variables: {
            input: {
              report_ids: alertIds,
              update_source: ReportChangeEventSource.UI,
              report_status: selectedQueueState,
              notes: comments || null,
              is_internal: isInternal,
            },
          },
        })
      } else {
        toast({
          title: 'Error',
          description: 'No matching alerts found for the provided IDs',
          status: 'error',
          isClosable: true,
        })
        return
      }
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message,
        status: 'error',
        isClosable: true,
      })
      return
    }
  }

  const reset = () => {
    setSelectedQueueState(null)
    setAlertIds('')
    setComments('')
    setIsInternal(false)
  }

  const renderModalBody = () => {
    return (
      <Stack spacing="24px">
        <FormControl isRequired>
          <FormLabel>{'Alert IDs'} </FormLabel>

          <Textarea
            minHeight="200px"
            onChange={(e) => setAlertIds(e.target.value)}
            placeholder={'TST-1234\nTST-4567\nTST-8901'}
            ref={firstField}
            value={alertIds}
          />

          <FormHelperText>
            New line separated list of Alert IDs to assign (Max {100})
          </FormHelperText>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Queue State</FormLabel>

          <Dropdown
            groupedItems={[
              {
                items: statuses.map((status) => ({
                  value: status,
                  label: getStatusLabel(status, selectedReportType),
                })),
              },
            ]}
            menuLabel={
              getStatusLabel(selectedQueueState, selectedReportType) || 'Queue State'
            }
            onSelect={(value) => setSelectedQueueState(value)}
            selectedValue={selectedQueueState}
          />

          <FormHelperText>Queue State to move alerts to</FormHelperText>
        </FormControl>

        <FormControl>
          <FormLabel>Comments</FormLabel>

          <Textarea
            minHeight="100px"
            onChange={(e) => setComments(e.target.value)}
            placeholder={'Add comments here...'}
            value={comments}
          />

          <FormHelperText>Comments to add to the alerts</FormHelperText>
        </FormControl>

        <FormControl>
          <Checkbox
            borderColor={DOPPEL_LINE_GREY}
            isChecked={isInternal}
            onChange={() => setIsInternal((x) => !x)}
            pl={1.5}
          >
            Internal Comment
          </Checkbox>
        </FormControl>
      </Stack>
    )
  }

  return (
    <DoppelModal
      body={renderModalBody()}
      isAsync={true}
      isDisabled={!selectedQueueState || !alertIds}
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
      primaryAction={updateAlertFromExternalIds}
      primaryLabel="Move alerts"
      size="lg"
      title={'Bulk Move Alerts'}
    ></DoppelModal>
  )
}
