import { isSameDay } from 'date-fns'

// interface with methods needed for stats
export type DateRange = {
  start: Date
  end: Date
  displayLabel: string
}

/**
 * simple implementation of DateRange, representing a range between instants in time
 */
export class DateTimeRange implements DateRange {
  start: Date
  end: Date
  displayLabel: string

  constructor(start: Date, end: Date, displayLabel: string) {
    this.start = start
    this.end = end
    this.displayLabel = displayLabel
  }
}

/**
 * converts a local date (e.g. 2024-12-05 22:00 EST, which would be 2024-12-06 in UTC)
 * to the start or end of corresponding UTC day (2024-12-05 00:00:00/23:59:59 UTC)
 *
 * most of this system uses local timestamp (due to date picker), so this is called
 * when getting the exact timestamp for a query
 */
const transformDateToQueryTime = (date: Date, type: 'start' | 'end') => {
  const time = type === 'start' ? [0, 0, 0] : [23, 59, 59]
  return new Date(
    Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), ...time),
  )
}

/**
 * implementation of DateRange, used in date range dropdown
 * represents a date range with only start and end dates (no time information)
 */
export class DateOnlyRange implements DateRange {
  startDate: Date
  endDate: Date
  customLabel?: string // this is to store/track naming of predefined ranges

  constructor(startDate: Date, endDate: Date, label?: string) {
    this.startDate = startDate
    this.endDate = endDate
    this.customLabel = label
  }

  /**
   * displays selected dropdown range with space constraints
   */
  toLocalShortDisplay(): string {
    const startYear = this.startDate.getFullYear()
    const endYear = this.endDate.getFullYear()
    const currentYear = new Date().getFullYear()
    const startMonth = this.startDate.getMonth() + 1
    const endMonth = this.endDate.getMonth() + 1
    const startDay = this.startDate.getDate()
    const endDay = this.endDate.getDate()

    if (startYear !== endYear) {
      return `${startYear} - ${endYear}`
    } else if (startYear !== currentYear) {
      if (startMonth !== endMonth) {
        return `${startYear}/${startMonth}-${endMonth}`
      } else if (startDay !== endDay) {
        return `${startYear}/${startMonth}/${startDay}-${endDay}`
      } else {
        return `${startYear}/${startMonth}/${startDay}`
      }
    } else if (startMonth !== endMonth) {
      return `${startMonth}/${startDay} - ${endMonth}/${endDay}`
    } else if (startDay !== endDay) {
      return `${startMonth}/${startDay} - ${endMonth}/${endDay}`
    } else {
      return `${startMonth}/${startDay}`
    }
  }

  withLabel(label: string): DateOnlyRange {
    return new DateOnlyRange(this.startDate, this.endDate, label)
  }

  get start(): Date {
    return transformDateToQueryTime(this.startDate, 'start')
  }
  get end(): Date {
    return transformDateToQueryTime(this.endDate, 'end')
  }

  /**
   * long display, defaulting to label first
   */
  get displayLabel(): string {
    if (this.customLabel) return this.customLabel

    const formatDate = (date: Date) =>
      date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      })

    if (isSameDay(this.startDate, this.endDate)) {
      return formatDate(this.startDate)
    }

    return `${formatDate(this.startDate)} - ${formatDate(this.endDate)}`
  }
}
