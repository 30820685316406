import { Box, Tab, Text, Tooltip, Spinner } from '@chakra-ui/react'
import { useSelectedReportType } from '../../pages/reports'
import {
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
  FONT_SIZE_LARGE,
  FONT_SIZE_MEDIUM,
} from '../../utils/style'
import { ReportStatus, getStatusLabel } from '../../utils/constants'

const ReportStatusTab = ({ reportStatus, count, isLoading }) => {
  const [selectedReportType] = useSelectedReportType()
  const isInternal = reportStatus.toLowerCase().includes('internal')
  const tooltipLabel = getHeaderTooltip(reportStatus)

  const [bgColor, textColor] = getTabColor(isInternal)

  const content = (
    <Tab bgColor={bgColor} key={'report_tab' + reportStatus} textColor={textColor}>
      <Text fontSize={FONT_SIZE_LARGE} fontWeight={600}>
        {getStatusLabel(reportStatus, selectedReportType)}
      </Text>

      <Box marginLeft="2" paddingY="2">
        {isLoading ? (
          <Spinner size="xs" />
        ) : (
          <Text fontSize={FONT_SIZE_MEDIUM} fontWeight={500}>
            {count}
          </Text>
        )}
      </Box>
    </Tab>
  )
  if (!tooltipLabel) {
    return content
  }
  return (
    <Tooltip aria-label={tooltipLabel} label={tooltipLabel}>
      {content}
    </Tooltip>
  )
}

function getTabColor(isInternal) {
  let bgColor = 'transparent'
  let textColor = DOPPEL_WHITE

  if (isInternal) {
    bgColor = DOPPEL_INTERNAL_PURPLE
    textColor = DOPPEL_WHITE
  }

  return [bgColor, textColor]
}

function getHeaderTooltip(reportStatus) {
  if (
    reportStatus == ReportStatus.APPROVED_DERIVATIVE ||
    reportStatus == ReportStatus.ENUM_APPROVED_DERIVATIVE
  ) {
    return 'Incidents that have been flagged to monitor, but don’t need immediate actioning.'
  }
  return null
}

export default ReportStatusTab
