import { Spacer, VStack, Image, Text, HStack, Link, Heading } from '@chakra-ui/react'
import LoginButton from '../components/login_button'
import { DOPPEL_GREY_900, DOPPEL_OFFICIAL_BLUE } from '../utils/style'
import { useAuth0 } from '@auth0/auth0-react'
import { useRouter } from 'next/router'
import {
  DOPPEL_LOGO_URL,
  DOPPEL_LOGO_URL_REBRANDING,
  DOPPEL_VISION_URL_REBRANDING,
  PROGRESSIVE_WARP_PATTERN,
} from '@/utils/image'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const Login = () => {
  const { isAuthenticated, isLoading } = useAuth0()
  const isRebrandingEnabled = useFeatureFlagEnabled('doppel-rebranding-signup')

  const router = useRouter()

  if (isLoading || isRebrandingEnabled === undefined) {
    return <></>
  }

  if (isAuthenticated) {
    router.push('/')
  }

  return isRebrandingEnabled === false ? (
    <VStack bg={DOPPEL_OFFICIAL_BLUE} minH="100vh" paddingBottom={10}>
      <Spacer />

      <VStack spacing="50px">
        <Image alt="" h="200px" src={DOPPEL_LOGO_URL} />

        <VStack spacing="20px">
          <Heading color="#FFFFFF" fontSize="74px">
            Doppel Vision
          </Heading>

          <Text color="#FFFFFF" fontSize="24px">
            Social Engineering Defense.
          </Text>
        </VStack>

        <LoginButton />
      </VStack>

      <Spacer />

      <HStack>
        <Text color="#FFFFFF" fontSize="14px">
          © 2025 Doppel
        </Text>

        <Link
          color="#FFFFFF"
          fontSize="14px"
          href="https://app.termly.io/document/privacy-policy/194662e6-a835-4542-abe4-942999ce5147"
          isExternal
        >
          Privacy Policy
        </Link>

        <Link
          color="#FFFFFF"
          fontSize="14px"
          href="https://app.termly.io/document/terms-of-use-for-saas/be0df5c9-83a7-494f-b17f-6926447a30b3"
          isExternal
        >
          Terms of Service
        </Link>
      </HStack>
    </VStack>
  ) : (
    <VStack
      bgColor={DOPPEL_GREY_900}
      bgImage={PROGRESSIVE_WARP_PATTERN}
      bgPosition={'bottom'}
      bgSize={'cover'}
      minH="100vh"
      paddingBottom={10}
    >
      <Spacer />

      <VStack spacing="52px">
        <VStack spacing="24px">
          <HStack alignItems={'flex-start'} spacing="3px">
            <Image alt="" h="58px" src={DOPPEL_LOGO_URL_REBRANDING} />

            <Image alt="" h="45px" marginTop={0.5} src={DOPPEL_VISION_URL_REBRANDING} />
          </HStack>

          <Text color="#FFFFFF" fontSize="20px">
            Social Engineering Defense.
          </Text>
        </VStack>

        <LoginButton />
      </VStack>

      <Spacer />

      <HStack>
        <Text color="#FFFFFF" fontSize="14px">
          © 2025 Doppel
        </Text>

        <Text color="#FFFFFF" fontSize="14px">
          -
        </Text>

        <Link
          color="#FFFFFF"
          fontSize="14px"
          href="https://app.termly.io/document/privacy-policy/194662e6-a835-4542-abe4-942999ce5147"
          isExternal
        >
          Privacy Policy
        </Link>

        <Text color="#FFFFFF" fontSize="14px">
          -
        </Text>

        <Link
          color="#FFFFFF"
          fontSize="14px"
          href="https://app.termly.io/document/terms-of-use-for-saas/be0df5c9-83a7-494f-b17f-6926447a30b3"
          isExternal
        >
          Terms of Service
        </Link>
      </HStack>
    </VStack>
  )
}

export default Login
