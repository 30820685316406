/**
 * This is an AUTOGENERATED FILE generated by shared/platform_subtype_generation.py.
 * DO NOT modify this file manually. To update this file,
 * modify shared/platform_subtypes.json5 and run shared/platform_subtype_generation.py.
 */

import { PlatformSubtypeMetadata } from '@/utils/reports/platform_subtype_utils'
import { ProductType, Archetype, Platform, PlatformSubtype, PlatformSubtypeId } from './enums'


export const PRODUCT_TO_DEFAULT_SUBTYPE: Readonly<Partial<Record<ProductType, PlatformSubtype>>> = {
  [ProductType.DOMAIN]: PlatformSubtype.DOMAINS,
  [ProductType.SOCIAL_MEDIA]: PlatformSubtype.SOCIAL_MEDIA_OTHER,
  [ProductType.CRYPTO]: PlatformSubtype.CRYPTO_OTHER,
  [ProductType.MOBILE_APP]: PlatformSubtype.MOBILE_APPS_OTHER,
  [ProductType.PAID_AD]: PlatformSubtype.PAID_ADS_OTHER,
  [ProductType.ECOMMERCE]: PlatformSubtype.ECOMMERCE_OTHER,
  [ProductType.EMAIL]: PlatformSubtype.EMAIL,
  [ProductType.DARKWEB]: PlatformSubtype.DARKWEB,
  [ProductType.DARK_MARKET]: PlatformSubtype.DARKMARKET_OTHER,
  [ProductType.TELCO]: PlatformSubtype.TELCO,
  [ProductType.SUSPICIOUS_EMAILS]: PlatformSubtype.SUSPICIOUS_EMAILS,
}


export const PLATFORM_SUBTYPE_METADATA_LIST: Partial<Record<PlatformSubtypeId, PlatformSubtypeMetadata>> = {
  [PlatformSubtypeId.COINBASE]: {
    id: PlatformSubtypeId.COINBASE,
    name: PlatformSubtype.COINBASE,
    platform: Platform.COINBASE,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.BINANCE]: {
    id: PlatformSubtypeId.BINANCE,
    name: PlatformSubtype.BINANCE,
    platform: Platform.BINANCE,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.OPENSEA]: {
    id: PlatformSubtypeId.OPENSEA,
    name: PlatformSubtype.OPENSEA,
    platform: Platform.OPENSEA,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.MINTABLE]: {
    id: PlatformSubtypeId.MINTABLE,
    name: PlatformSubtype.MINTABLE,
    platform: Platform.MINTABLE,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.MAGICEDEN]: {
    id: PlatformSubtypeId.MAGICEDEN,
    name: PlatformSubtype.MAGICEDEN,
    platform: Platform.MAGICEDEN,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.OKX]: {
    id: PlatformSubtypeId.OKX,
    name: PlatformSubtype.OKX,
    platform: Platform.OKX,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.RARIBLE]: {
    id: PlatformSubtypeId.RARIBLE,
    name: PlatformSubtype.RARIBLE,
    platform: Platform.RARIBLE,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.CRYPTO_OTHER]: {
    id: PlatformSubtypeId.CRYPTO_OTHER,
    name: PlatformSubtype.CRYPTO_OTHER,
    platform: Platform.CRYPTO_OTHER,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.SHELLIX]: {
    id: PlatformSubtypeId.SHELLIX,
    name: PlatformSubtype.SHELLIX,
    platform: Platform.SHELLIX,
    archetype: Archetype.DARK_MARKET,
    product: ProductType.DARK_MARKET,
  },
  [PlatformSubtypeId.DARKMARKET_OTHER]: {
    id: PlatformSubtypeId.DARKMARKET_OTHER,
    name: PlatformSubtype.DARKMARKET_OTHER,
    platform: Platform.DARKMARKET_OTHER,
    archetype: Archetype.DARK_MARKET,
    product: ProductType.DARK_MARKET,
  },
  [PlatformSubtypeId.DARKWEB]: {
    id: PlatformSubtypeId.DARKWEB,
    name: PlatformSubtype.DARKWEB,
    platform: Platform.DARKWEB,
    archetype: Archetype.DARKWEB,
    product: ProductType.DARKWEB,
  },
  [PlatformSubtypeId.CRED_LEAKS]: {
    id: PlatformSubtypeId.CRED_LEAKS,
    name: PlatformSubtype.CRED_LEAKS,
    platform: Platform.CRED_LEAKS,
    archetype: Archetype.CRED_LEAKS,
    product: ProductType.DARKWEB,
  },
  [PlatformSubtypeId.CREDIT_CARD_LEAKS]: {
    id: PlatformSubtypeId.CREDIT_CARD_LEAKS,
    name: PlatformSubtype.CREDIT_CARD_LEAKS,
    platform: Platform.CREDIT_CARD_LEAKS,
    archetype: Archetype.CREDIT_CARD_LEAKS,
    product: ProductType.DARKWEB,
  },
  [PlatformSubtypeId.API_LEAKS]: {
    id: PlatformSubtypeId.API_LEAKS,
    name: PlatformSubtype.API_LEAKS,
    platform: Platform.API_LEAKS,
    archetype: Archetype.API_LEAKS,
    product: ProductType.DARKWEB,
  },
  [PlatformSubtypeId.DOMAINS]: {
    id: PlatformSubtypeId.DOMAINS,
    name: PlatformSubtype.DOMAINS,
    platform: Platform.DOMAINS,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
  [PlatformSubtypeId.GITBOOK]: {
    id: PlatformSubtypeId.GITBOOK,
    name: PlatformSubtype.GITBOOK,
    platform: Platform.GITBOOK,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
  [PlatformSubtypeId.WEBFLOW]: {
    id: PlatformSubtypeId.WEBFLOW,
    name: PlatformSubtype.WEBFLOW,
    platform: Platform.WEBFLOW,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
  [PlatformSubtypeId.SHOPEE]: {
    id: PlatformSubtypeId.SHOPEE,
    name: PlatformSubtype.SHOPEE,
    platform: Platform.SHOPEE,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.ROBLOX]: {
    id: PlatformSubtypeId.ROBLOX,
    name: PlatformSubtype.ROBLOX,
    platform: Platform.ROBLOX,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.AMAZON]: {
    id: PlatformSubtypeId.AMAZON,
    name: PlatformSubtype.AMAZON,
    platform: Platform.AMAZON,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.WALMART]: {
    id: PlatformSubtypeId.WALMART,
    name: PlatformSubtype.WALMART,
    platform: Platform.WALMART,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.CRAIGSLIST]: {
    id: PlatformSubtypeId.CRAIGSLIST,
    name: PlatformSubtype.CRAIGSLIST,
    platform: Platform.CRAIGSLIST,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.ETSY]: {
    id: PlatformSubtypeId.ETSY,
    name: PlatformSubtype.ETSY,
    platform: Platform.ETSY,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.EBAY]: {
    id: PlatformSubtypeId.EBAY,
    name: PlatformSubtype.EBAY,
    platform: Platform.EBAY,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.LAZADA]: {
    id: PlatformSubtypeId.LAZADA,
    name: PlatformSubtype.LAZADA,
    platform: Platform.LAZADA,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.Z2U]: {
    id: PlatformSubtypeId.Z2U,
    name: PlatformSubtype.Z2U,
    platform: Platform.Z2U,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.TOKOPEDIA]: {
    id: PlatformSubtypeId.TOKOPEDIA,
    name: PlatformSubtype.TOKOPEDIA,
    platform: Platform.TOKOPEDIA,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.FACEBOOK_MARKETPLACE]: {
    id: PlatformSubtypeId.FACEBOOK_MARKETPLACE,
    name: PlatformSubtype.FACEBOOK_MARKETPLACE,
    platform: Platform.FACEBOOK_MARKETPLACE,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.ECOMMERCE_OTHER]: {
    id: PlatformSubtypeId.ECOMMERCE_OTHER,
    name: PlatformSubtype.ECOMMERCE_OTHER,
    platform: Platform.ECOMMERCE_OTHER,
    archetype: Archetype.ECOMMERCE,
    product: ProductType.ECOMMERCE,
  },
  [PlatformSubtypeId.EMAIL]: {
    id: PlatformSubtypeId.EMAIL,
    name: PlatformSubtype.EMAIL,
    platform: Platform.EMAIL,
    archetype: Archetype.EMAIL,
    product: ProductType.EMAIL,
  },
  [PlatformSubtypeId.APPLE_APP_STORE]: {
    id: PlatformSubtypeId.APPLE_APP_STORE,
    name: PlatformSubtype.APPLE_APP_STORE,
    platform: Platform.APPLE_APP_STORE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKGK]: {
    id: PlatformSubtypeId.APKGK,
    name: PlatformSubtype.APKGK,
    platform: Platform.APKGK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.UPTODOWN]: {
    id: PlatformSubtypeId.UPTODOWN,
    name: PlatformSubtype.UPTODOWN,
    platform: Platform.UPTODOWN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.STEPRIMO]: {
    id: PlatformSubtypeId.STEPRIMO,
    name: PlatformSubtype.STEPRIMO,
    platform: Platform.STEPRIMO,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APPBRAIN]: {
    id: PlatformSubtypeId.APPBRAIN,
    name: PlatformSubtype.APPBRAIN,
    platform: Platform.APPBRAIN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.GOOGLE_PLAY_STORE]: {
    id: PlatformSubtypeId.GOOGLE_PLAY_STORE,
    name: PlatformSubtype.GOOGLE_PLAY_STORE,
    platform: Platform.GOOGLE_PLAY_STORE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APTOIDE]: {
    id: PlatformSubtypeId.APTOIDE,
    name: PlatformSubtype.APTOIDE,
    platform: Platform.APTOIDE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.NINEAPPS]: {
    id: PlatformSubtypeId.NINEAPPS,
    name: PlatformSubtype.NINEAPPS,
    platform: Platform.NINEAPPS,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKCOMBO]: {
    id: PlatformSubtypeId.APKCOMBO,
    name: PlatformSubtype.APKCOMBO,
    platform: Platform.APKCOMBO,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKPURE]: {
    id: PlatformSubtypeId.APKPURE,
    name: PlatformSubtype.APKPURE,
    platform: Platform.APKPURE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.CAFEBAZAAR]: {
    id: PlatformSubtypeId.CAFEBAZAAR,
    name: PlatformSubtype.CAFEBAZAAR,
    platform: Platform.CAFEBAZAAR,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.GAMELOOP]: {
    id: PlatformSubtypeId.GAMELOOP,
    name: PlatformSubtype.GAMELOOP,
    platform: Platform.GAMELOOP,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.HAPPYMOD]: {
    id: PlatformSubtypeId.HAPPYMOD,
    name: PlatformSubtype.HAPPYMOD,
    platform: Platform.HAPPYMOD,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKHOUSE]: {
    id: PlatformSubtypeId.APKHOUSE,
    name: PlatformSubtype.APKHOUSE,
    platform: Platform.APKHOUSE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.IODROID]: {
    id: PlatformSubtypeId.IODROID,
    name: PlatformSubtype.IODROID,
    platform: Platform.IODROID,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MALAVIDA]: {
    id: PlatformSubtypeId.MALAVIDA,
    name: PlatformSubtype.MALAVIDA,
    platform: Platform.MALAVIDA,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKMODY]: {
    id: PlatformSubtypeId.APKMODY,
    name: PlatformSubtype.APKMODY,
    platform: Platform.APKMODY,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APK4K]: {
    id: PlatformSubtypeId.APK4K,
    name: PlatformSubtype.APK4K,
    platform: Platform.APK4K,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKFREE]: {
    id: PlatformSubtypeId.APKFREE,
    name: PlatformSubtype.APKFREE,
    platform: Platform.APKFREE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKDOWNLOAD]: {
    id: PlatformSubtypeId.APKDOWNLOAD,
    name: PlatformSubtype.APKDOWNLOAD,
    platform: Platform.APKDOWNLOAD,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKMONK]: {
    id: PlatformSubtypeId.APKMONK,
    name: PlatformSubtype.APKMONK,
    platform: Platform.APKMONK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKSOS]: {
    id: PlatformSubtypeId.APKSOS,
    name: PlatformSubtype.APKSOS,
    platform: Platform.APKSOS,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKSUPPORT]: {
    id: PlatformSubtypeId.APKSUPPORT,
    name: PlatformSubtype.APKSUPPORT,
    platform: Platform.APKSUPPORT,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKTOY]: {
    id: PlatformSubtypeId.APKTOY,
    name: PlatformSubtype.APKTOY,
    platform: Platform.APKTOY,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.FILEHIPPO]: {
    id: PlatformSubtypeId.FILEHIPPO,
    name: PlatformSubtype.FILEHIPPO,
    platform: Platform.FILEHIPPO,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId._99IMAGES]: {
    id: PlatformSubtypeId._99IMAGES,
    name: PlatformSubtype._99IMAGES,
    platform: Platform._99IMAGES,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKDOG]: {
    id: PlatformSubtypeId.APKDOG,
    name: PlatformSubtype.APKDOG,
    platform: Platform.APKDOG,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKBOG]: {
    id: PlatformSubtypeId.APKBOG,
    name: PlatformSubtype.APKBOG,
    platform: Platform.APKBOG,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKLUST]: {
    id: PlatformSubtypeId.APKLUST,
    name: PlatformSubtype.APKLUST,
    platform: Platform.APKLUST,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APPSONWINDOWS]: {
    id: PlatformSubtypeId.APPSONWINDOWS,
    name: PlatformSubtype.APPSONWINDOWS,
    platform: Platform.APPSONWINDOWS,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.NEDGET]: {
    id: PlatformSubtypeId.NEDGET,
    name: PlatformSubtype.NEDGET,
    platform: Platform.NEDGET,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.SOFTONIC]: {
    id: PlatformSubtypeId.SOFTONIC,
    name: PlatformSubtype.SOFTONIC,
    platform: Platform.SOFTONIC,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.SOFTSTON]: {
    id: PlatformSubtypeId.SOFTSTON,
    name: PlatformSubtype.SOFTSTON,
    platform: Platform.SOFTSTON,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.GETJAR]: {
    id: PlatformSubtypeId.GETJAR,
    name: PlatformSubtype.GETJAR,
    platform: Platform.GETJAR,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKMIRROR]: {
    id: PlatformSubtypeId.APKMIRROR,
    name: PlatformSubtype.APKMIRROR,
    platform: Platform.APKMIRROR,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.OPERA]: {
    id: PlatformSubtypeId.OPERA,
    name: PlatformSubtype.OPERA,
    platform: Platform.OPERA,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.FILEHORSE]: {
    id: PlatformSubtypeId.FILEHORSE,
    name: PlatformSubtype.FILEHORSE,
    platform: Platform.FILEHORSE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.UOLDOWN]: {
    id: PlatformSubtypeId.UOLDOWN,
    name: PlatformSubtype.UOLDOWN,
    platform: Platform.UOLDOWN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKDOWNLOADHUNT]: {
    id: PlatformSubtypeId.APKDOWNLOADHUNT,
    name: PlatformSubtype.APKDOWNLOADHUNT,
    platform: Platform.APKDOWNLOADHUNT,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKLOO]: {
    id: PlatformSubtypeId.APKLOO,
    name: PlatformSubtype.APKLOO,
    platform: Platform.APKLOO,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKNITE]: {
    id: PlatformSubtypeId.APKNITE,
    name: PlatformSubtype.APKNITE,
    platform: Platform.APKNITE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APPVN]: {
    id: PlatformSubtypeId.APPVN,
    name: PlatformSubtype.APPVN,
    platform: Platform.APPVN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.SMARTLOADS]: {
    id: PlatformSubtypeId.SMARTLOADS,
    name: PlatformSubtype.SMARTLOADS,
    platform: Platform.SMARTLOADS,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.LUCKYMODAPK]: {
    id: PlatformSubtypeId.LUCKYMODAPK,
    name: PlatformSubtype.LUCKYMODAPK,
    platform: Platform.LUCKYMODAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKSHUB]: {
    id: PlatformSubtypeId.APKSHUB,
    name: PlatformSubtype.APKSHUB,
    platform: Platform.APKSHUB,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.CAPTAINDROID]: {
    id: PlatformSubtypeId.CAPTAINDROID,
    name: PlatformSubtype.CAPTAINDROID,
    platform: Platform.CAPTAINDROID,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.IPA4FUN]: {
    id: PlatformSubtypeId.IPA4FUN,
    name: PlatformSubtype.IPA4FUN,
    platform: Platform.IPA4FUN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.AAPKS]: {
    id: PlatformSubtypeId.AAPKS,
    name: PlatformSubtype.AAPKS,
    platform: Platform.AAPKS,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKMODULE]: {
    id: PlatformSubtypeId.APKMODULE,
    name: PlatformSubtype.APKMODULE,
    platform: Platform.APKMODULE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APK20]: {
    id: PlatformSubtypeId.APK20,
    name: PlatformSubtype.APK20,
    platform: Platform.APK20,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKFOLLOW]: {
    id: PlatformSubtypeId.APKFOLLOW,
    name: PlatformSubtype.APKFOLLOW,
    platform: Platform.APKFOLLOW,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKSOUL]: {
    id: PlatformSubtypeId.APKSOUL,
    name: PlatformSubtype.APKSOUL,
    platform: Platform.APKSOUL,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.ANDROIDFREEWARE]: {
    id: PlatformSubtypeId.ANDROIDFREEWARE,
    name: PlatformSubtype.ANDROIDFREEWARE,
    platform: Platform.ANDROIDFREEWARE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.PCMACSTORE]: {
    id: PlatformSubtypeId.PCMACSTORE,
    name: PlatformSubtype.PCMACSTORE,
    platform: Platform.PCMACSTORE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.ANDROIDBLUE]: {
    id: PlatformSubtypeId.ANDROIDBLUE,
    name: PlatformSubtype.ANDROIDBLUE,
    platform: Platform.ANDROIDBLUE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MODDROID]: {
    id: PlatformSubtypeId.MODDROID,
    name: PlatformSubtype.MODDROID,
    platform: Platform.MODDROID,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKDOM]: {
    id: PlatformSubtypeId.APKDOM,
    name: PlatformSubtype.APKDOM,
    platform: Platform.APKDOM,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.WEBCATALOG]: {
    id: PlatformSubtypeId.WEBCATALOG,
    name: PlatformSubtype.WEBCATALOG,
    platform: Platform.WEBCATALOG,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.ANDROIDAPPSAPK]: {
    id: PlatformSubtypeId.ANDROIDAPPSAPK,
    name: PlatformSubtype.ANDROIDAPPSAPK,
    platform: Platform.ANDROIDAPPSAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.SOFT112]: {
    id: PlatformSubtypeId.SOFT112,
    name: PlatformSubtype.SOFT112,
    platform: Platform.SOFT112,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.A2ZAPK]: {
    id: PlatformSubtypeId.A2ZAPK,
    name: PlatformSubtype.A2ZAPK,
    platform: Platform.A2ZAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.BIGNOX]: {
    id: PlatformSubtypeId.BIGNOX,
    name: PlatformSubtype.BIGNOX,
    platform: Platform.BIGNOX,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.NAPKFORPC]: {
    id: PlatformSubtypeId.NAPKFORPC,
    name: PlatformSubtype.NAPKFORPC,
    platform: Platform.NAPKFORPC,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKVISIT]: {
    id: PlatformSubtypeId.APKVISIT,
    name: PlatformSubtype.APKVISIT,
    platform: Platform.APKVISIT,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKBE]: {
    id: PlatformSubtypeId.APKBE,
    name: PlatformSubtype.APKBE,
    platform: Platform.APKBE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.PHONEKY]: {
    id: PlatformSubtypeId.PHONEKY,
    name: PlatformSubtype.PHONEKY,
    platform: Platform.PHONEKY,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APK3]: {
    id: PlatformSubtypeId.APK3,
    name: PlatformSubtype.APK3,
    platform: Platform.APK3,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APPONIC]: {
    id: PlatformSubtypeId.APPONIC,
    name: PlatformSubtype.APPONIC,
    platform: Platform.APPONIC,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.ANDRIODAPK]: {
    id: PlatformSubtypeId.ANDRIODAPK,
    name: PlatformSubtype.ANDRIODAPK,
    platform: Platform.ANDRIODAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MODPURE]: {
    id: PlatformSubtypeId.MODPURE,
    name: PlatformSubtype.MODPURE,
    platform: Platform.MODPURE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.JOJOY]: {
    id: PlatformSubtypeId.JOJOY,
    name: PlatformSubtype.JOJOY,
    platform: Platform.JOJOY,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKSTREAMZ]: {
    id: PlatformSubtypeId.APKSTREAMZ,
    name: PlatformSubtype.APKSTREAMZ,
    platform: Platform.APKSTREAMZ,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APK4FUN]: {
    id: PlatformSubtypeId.APK4FUN,
    name: PlatformSubtype.APK4FUN,
    platform: Platform.APK4FUN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKBIND]: {
    id: PlatformSubtypeId.APKBIND,
    name: PlatformSubtype.APKBIND,
    platform: Platform.APKBIND,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKVISION]: {
    id: PlatformSubtypeId.APKVISION,
    name: PlatformSubtype.APKVISION,
    platform: Platform.APKVISION,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.ANDROIDOUT]: {
    id: PlatformSubtypeId.ANDROIDOUT,
    name: PlatformSubtype.ANDROIDOUT,
    platform: Platform.ANDROIDOUT,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.DFAST]: {
    id: PlatformSubtypeId.DFAST,
    name: PlatformSubtype.DFAST,
    platform: Platform.DFAST,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MODCOMBO]: {
    id: PlatformSubtypeId.MODCOMBO,
    name: PlatformSubtype.MODCOMBO,
    platform: Platform.MODCOMBO,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MEMUPLAY]: {
    id: PlatformSubtypeId.MEMUPLAY,
    name: PlatformSubtype.MEMUPLAY,
    platform: Platform.MEMUPLAY,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.VEAPPS]: {
    id: PlatformSubtypeId.VEAPPS,
    name: PlatformSubtype.VEAPPS,
    platform: Platform.VEAPPS,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKCAFE]: {
    id: PlatformSubtypeId.APKCAFE,
    name: PlatformSubtype.APKCAFE,
    platform: Platform.APKCAFE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKSHKI]: {
    id: PlatformSubtypeId.APKSHKI,
    name: PlatformSubtype.APKSHKI,
    platform: Platform.APKSHKI,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.KINGMODAPK]: {
    id: PlatformSubtypeId.KINGMODAPK,
    name: PlatformSubtype.KINGMODAPK,
    platform: Platform.KINGMODAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKGOLD]: {
    id: PlatformSubtypeId.APKGOLD,
    name: PlatformSubtype.APKGOLD,
    platform: Platform.APKGOLD,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.ANDROIDGOZAR]: {
    id: PlatformSubtypeId.ANDROIDGOZAR,
    name: PlatformSubtype.ANDROIDGOZAR,
    platform: Platform.ANDROIDGOZAR,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.CRX4CHROME]: {
    id: PlatformSubtypeId.CRX4CHROME,
    name: PlatformSubtype.CRX4CHROME,
    platform: Platform.CRX4CHROME,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKAMP]: {
    id: PlatformSubtypeId.APKAMP,
    name: PlatformSubtype.APKAMP,
    platform: Platform.APKAMP,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKTUME]: {
    id: PlatformSubtypeId.APKTUME,
    name: PlatformSubtype.APKTUME,
    platform: Platform.APKTUME,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APPLATEST]: {
    id: PlatformSubtypeId.APPLATEST,
    name: PlatformSubtype.APPLATEST,
    platform: Platform.APPLATEST,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKEASY]: {
    id: PlatformSubtypeId.APKEASY,
    name: PlatformSubtype.APKEASY,
    platform: Platform.APKEASY,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKNITRO]: {
    id: PlatformSubtypeId.APKNITRO,
    name: PlatformSubtype.APKNITRO,
    platform: Platform.APKNITRO,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.BAIXEFACIL]: {
    id: PlatformSubtypeId.BAIXEFACIL,
    name: PlatformSubtype.BAIXEFACIL,
    platform: Platform.BAIXEFACIL,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.ANDROIDLISTA]: {
    id: PlatformSubtypeId.ANDROIDLISTA,
    name: PlatformSubtype.ANDROIDLISTA,
    platform: Platform.ANDROIDLISTA,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MATJARAPK]: {
    id: PlatformSubtypeId.MATJARAPK,
    name: PlatformSubtype.MATJARAPK,
    platform: Platform.MATJARAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.GOZLA]: {
    id: PlatformSubtypeId.GOZLA,
    name: PlatformSubtype.GOZLA,
    platform: Platform.GOZLA,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKWATCH]: {
    id: PlatformSubtypeId.APKWATCH,
    name: PlatformSubtype.APKWATCH,
    platform: Platform.APKWATCH,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKSSOFTWARE]: {
    id: PlatformSubtypeId.APKSSOFTWARE,
    name: PlatformSubtype.APKSSOFTWARE,
    platform: Platform.APKSSOFTWARE,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.KERNAPK]: {
    id: PlatformSubtypeId.KERNAPK,
    name: PlatformSubtype.KERNAPK,
    platform: Platform.KERNAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKTIP]: {
    id: PlatformSubtypeId.APKTIP,
    name: PlatformSubtype.APKTIP,
    platform: Platform.APKTIP,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.APKUNDUH]: {
    id: PlatformSubtypeId.APKUNDUH,
    name: PlatformSubtype.APKUNDUH,
    platform: Platform.APKUNDUH,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.TRAPK]: {
    id: PlatformSubtypeId.TRAPK,
    name: PlatformSubtype.TRAPK,
    platform: Platform.TRAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MODAPKDOWN]: {
    id: PlatformSubtypeId.MODAPKDOWN,
    name: PlatformSubtype.MODAPKDOWN,
    platform: Platform.MODAPKDOWN,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MODAPKCLOUD]: {
    id: PlatformSubtypeId.MODAPKCLOUD,
    name: PlatformSubtype.MODAPKCLOUD,
    platform: Platform.MODAPKCLOUD,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.LDPLAYER]: {
    id: PlatformSubtypeId.LDPLAYER,
    name: PlatformSubtype.LDPLAYER,
    platform: Platform.LDPLAYER,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.ANDROIDBLIP]: {
    id: PlatformSubtypeId.ANDROIDBLIP,
    name: PlatformSubtype.ANDROIDBLIP,
    platform: Platform.ANDROIDBLIP,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.FREEAPK]: {
    id: PlatformSubtypeId.FREEAPK,
    name: PlatformSubtype.FREEAPK,
    platform: Platform.FREEAPK,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.MOBILE_APPS_OTHER]: {
    id: PlatformSubtypeId.MOBILE_APPS_OTHER,
    name: PlatformSubtype.MOBILE_APPS_OTHER,
    platform: Platform.MOBILE_APPS_OTHER,
    archetype: Archetype.MOBILE_APP,
    product: ProductType.MOBILE_APP,
  },
  [PlatformSubtypeId.GOOGLE_ADS]: {
    id: PlatformSubtypeId.GOOGLE_ADS,
    name: PlatformSubtype.GOOGLE_ADS,
    platform: Platform.GOOGLE_ADS,
    archetype: Archetype.PAID_AD,
    product: ProductType.PAID_AD,
  },
  [PlatformSubtypeId.FACEBOOK_ADS]: {
    id: PlatformSubtypeId.FACEBOOK_ADS,
    name: PlatformSubtype.FACEBOOK_ADS,
    platform: Platform.FACEBOOK_ADS,
    archetype: Archetype.PAID_AD,
    product: ProductType.PAID_AD,
  },
  [PlatformSubtypeId.BING_ADS]: {
    id: PlatformSubtypeId.BING_ADS,
    name: PlatformSubtype.BING_ADS,
    platform: Platform.BING_ADS,
    archetype: Archetype.PAID_AD,
    product: ProductType.PAID_AD,
  },
  [PlatformSubtypeId.PAID_ADS_OTHER]: {
    id: PlatformSubtypeId.PAID_ADS_OTHER,
    name: PlatformSubtype.PAID_ADS_OTHER,
    platform: Platform.PAID_ADS_OTHER,
    archetype: Archetype.PAID_AD,
    product: ProductType.PAID_AD,
  },
  [PlatformSubtypeId.TWITTER]: {
    id: PlatformSubtypeId.TWITTER,
    name: PlatformSubtype.TWITTER,
    platform: Platform.TWITTER,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TWITTER_POST]: {
    id: PlatformSubtypeId.TWITTER_POST,
    name: PlatformSubtype.TWITTER_POST,
    platform: Platform.TWITTER,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TIKTOK]: {
    id: PlatformSubtypeId.TIKTOK,
    name: PlatformSubtype.TIKTOK,
    platform: Platform.TIKTOK,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TIKTOK_VIDEO]: {
    id: PlatformSubtypeId.TIKTOK_VIDEO,
    name: PlatformSubtype.TIKTOK_VIDEO,
    platform: Platform.TIKTOK,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TIKTOK_PHOTO]: {
    id: PlatformSubtypeId.TIKTOK_PHOTO,
    name: PlatformSubtype.TIKTOK_PHOTO,
    platform: Platform.TIKTOK,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK]: {
    id: PlatformSubtypeId.FACEBOOK,
    name: PlatformSubtype.FACEBOOK,
    platform: Platform.FACEBOOK,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK_POST]: {
    id: PlatformSubtypeId.FACEBOOK_POST,
    name: PlatformSubtype.FACEBOOK_POST,
    platform: Platform.FACEBOOK,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK_GROUPS]: {
    id: PlatformSubtypeId.FACEBOOK_GROUPS,
    name: PlatformSubtype.FACEBOOK_GROUPS,
    platform: Platform.FACEBOOK,
    archetype: Archetype.SOCIAL_MEDIA_GROUP,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK_PAGE]: {
    id: PlatformSubtypeId.FACEBOOK_PAGE,
    name: PlatformSubtype.FACEBOOK_PAGE,
    platform: Platform.FACEBOOK,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.INSTAGRAM]: {
    id: PlatformSubtypeId.INSTAGRAM,
    name: PlatformSubtype.INSTAGRAM,
    platform: Platform.INSTAGRAM,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.INSTAGRAM_POST]: {
    id: PlatformSubtypeId.INSTAGRAM_POST,
    name: PlatformSubtype.INSTAGRAM_POST,
    platform: Platform.INSTAGRAM,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.YOUTUBE]: {
    id: PlatformSubtypeId.YOUTUBE,
    name: PlatformSubtype.YOUTUBE,
    platform: Platform.YOUTUBE,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.YOUTUBE_VIDEO]: {
    id: PlatformSubtypeId.YOUTUBE_VIDEO,
    name: PlatformSubtype.YOUTUBE_VIDEO,
    platform: Platform.YOUTUBE,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TELEGRAM]: {
    id: PlatformSubtypeId.TELEGRAM,
    name: PlatformSubtype.TELEGRAM,
    platform: Platform.TELEGRAM,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TELEGRAM_POST]: {
    id: PlatformSubtypeId.TELEGRAM_POST,
    name: PlatformSubtype.TELEGRAM_POST,
    platform: Platform.TELEGRAM,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.LINKEDIN]: {
    id: PlatformSubtypeId.LINKEDIN,
    name: PlatformSubtype.LINKEDIN,
    platform: Platform.LINKEDIN,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.LINKEDIN_JOB]: {
    id: PlatformSubtypeId.LINKEDIN_JOB,
    name: PlatformSubtype.LINKEDIN_JOB,
    platform: Platform.LINKEDIN,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.LINKEDIN_POST]: {
    id: PlatformSubtypeId.LINKEDIN_POST,
    name: PlatformSubtype.LINKEDIN_POST,
    platform: Platform.LINKEDIN,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.REDDIT]: {
    id: PlatformSubtypeId.REDDIT,
    name: PlatformSubtype.REDDIT,
    platform: Platform.REDDIT,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.REDDIT_COMMENT]: {
    id: PlatformSubtypeId.REDDIT_COMMENT,
    name: PlatformSubtype.REDDIT_COMMENT,
    platform: Platform.REDDIT,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.REDDIT_SUBREDDIT]: {
    id: PlatformSubtypeId.REDDIT_SUBREDDIT,
    name: PlatformSubtype.REDDIT_SUBREDDIT,
    platform: Platform.REDDIT,
    archetype: Archetype.SOCIAL_MEDIA_GROUP,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.GITHUB]: {
    id: PlatformSubtypeId.GITHUB,
    name: PlatformSubtype.GITHUB,
    platform: Platform.GITHUB,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.PINTEREST]: {
    id: PlatformSubtypeId.PINTEREST,
    name: PlatformSubtype.PINTEREST,
    platform: Platform.PINTEREST,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.DISCORD]: {
    id: PlatformSubtypeId.DISCORD,
    name: PlatformSubtype.DISCORD,
    platform: Platform.DISCORD,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.WHATSAPP]: {
    id: PlatformSubtypeId.WHATSAPP,
    name: PlatformSubtype.WHATSAPP,
    platform: Platform.WHATSAPP,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TRUTH_SOCIAL]: {
    id: PlatformSubtypeId.TRUTH_SOCIAL,
    name: PlatformSubtype.TRUTH_SOCIAL,
    platform: Platform.TRUTH_SOCIAL,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.FACEBOOK_PROFILE]: {
    id: PlatformSubtypeId.FACEBOOK_PROFILE,
    name: PlatformSubtype.FACEBOOK_PROFILE,
    platform: Platform.FACEBOOK_PROFILE,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.SPOTIFY]: {
    id: PlatformSubtypeId.SPOTIFY,
    name: PlatformSubtype.SPOTIFY,
    platform: Platform.SPOTIFY,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TWITCH]: {
    id: PlatformSubtypeId.TWITCH,
    name: PlatformSubtype.TWITCH,
    platform: Platform.TWITCH,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.THREADS]: {
    id: PlatformSubtypeId.THREADS,
    name: PlatformSubtype.THREADS,
    platform: Platform.THREADS,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.SNAPCHAT]: {
    id: PlatformSubtypeId.SNAPCHAT,
    name: PlatformSubtype.SNAPCHAT,
    platform: Platform.SNAPCHAT,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.WEIBO]: {
    id: PlatformSubtypeId.WEIBO,
    name: PlatformSubtype.WEIBO,
    platform: Platform.WEIBO,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.WEIBO_POST]: {
    id: PlatformSubtypeId.WEIBO_POST,
    name: PlatformSubtype.WEIBO_POST,
    platform: Platform.WEIBO,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.MEDIUM]: {
    id: PlatformSubtypeId.MEDIUM,
    name: PlatformSubtype.MEDIUM,
    platform: Platform.MEDIUM,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.LINKTREE]: {
    id: PlatformSubtypeId.LINKTREE,
    name: PlatformSubtype.LINKTREE,
    platform: Platform.LINKTREE,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TUMBLR]: {
    id: PlatformSubtypeId.TUMBLR,
    name: PlatformSubtype.TUMBLR,
    platform: Platform.TUMBLR,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.SOCIAL_MEDIA_OTHER]: {
    id: PlatformSubtypeId.SOCIAL_MEDIA_OTHER,
    name: PlatformSubtype.SOCIAL_MEDIA_OTHER,
    platform: Platform.SOCIAL_MEDIA_OTHER,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.TELCO]: {
    id: PlatformSubtypeId.TELCO,
    name: PlatformSubtype.TELCO,
    platform: Platform.TELCO,
    archetype: Archetype.TELCO,
    product: ProductType.TELCO,
  },
  [PlatformSubtypeId.SUSPICIOUS_EMAILS]: {
    id: PlatformSubtypeId.SUSPICIOUS_EMAILS,
    name: PlatformSubtype.SUSPICIOUS_EMAILS,
    platform: Platform.SUSPICIOUS_EMAILS,
    archetype: Archetype.SUSPICIOUS_EMAILS,
    product: ProductType.SUSPICIOUS_EMAILS,
  },
  [PlatformSubtypeId.CLOUDFLARE_PAGES]: {
    id: PlatformSubtypeId.CLOUDFLARE_PAGES,
    name: PlatformSubtype.CLOUDFLARE_PAGES,
    platform: Platform.CLOUDFLARE_PAGES,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
  [PlatformSubtypeId.DEXSCREENER]: {
    id: PlatformSubtypeId.DEXSCREENER,
    name: PlatformSubtype.DEXSCREENER,
    platform: Platform.DEXSCREENER,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.UPBIT]: {
    id: PlatformSubtypeId.UPBIT,
    name: PlatformSubtype.UPBIT,
    platform: Platform.UPBIT,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.KRAKEN]: {
    id: PlatformSubtypeId.KRAKEN,
    name: PlatformSubtype.KRAKEN,
    platform: Platform.KRAKEN,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.KUCOIN]: {
    id: PlatformSubtypeId.KUCOIN,
    name: PlatformSubtype.KUCOIN,
    platform: Platform.KUCOIN,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.MEXC]: {
    id: PlatformSubtypeId.MEXC,
    name: PlatformSubtype.MEXC,
    platform: Platform.MEXC,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.TRADINGVIEW]: {
    id: PlatformSubtypeId.TRADINGVIEW,
    name: PlatformSubtype.TRADINGVIEW,
    platform: Platform.TRADINGVIEW,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.CRYPTORANK]: {
    id: PlatformSubtypeId.CRYPTORANK,
    name: PlatformSubtype.CRYPTORANK,
    platform: Platform.CRYPTORANK,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.BYBIT]: {
    id: PlatformSubtypeId.BYBIT,
    name: PlatformSubtype.BYBIT,
    platform: Platform.BYBIT,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.HTX]: {
    id: PlatformSubtypeId.HTX,
    name: PlatformSubtype.HTX,
    platform: Platform.HTX,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.LIVECOINWATCH]: {
    id: PlatformSubtypeId.LIVECOINWATCH,
    name: PlatformSubtype.LIVECOINWATCH,
    platform: Platform.LIVECOINWATCH,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.BITSCREENER]: {
    id: PlatformSubtypeId.BITSCREENER,
    name: PlatformSubtype.BITSCREENER,
    platform: Platform.BITSCREENER,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.BLUESKY]: {
    id: PlatformSubtypeId.BLUESKY,
    name: PlatformSubtype.BLUESKY,
    platform: Platform.BLUESKY,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.SUIVISION]: {
    id: PlatformSubtypeId.SUIVISION,
    name: PlatformSubtype.SUIVISION,
    platform: Platform.SUIVISION,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.SUISCAN]: {
    id: PlatformSubtypeId.SUISCAN,
    name: PlatformSubtype.SUISCAN,
    platform: Platform.SUISCAN,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.SUIEXPLORER]: {
    id: PlatformSubtypeId.SUIEXPLORER,
    name: PlatformSubtype.SUIEXPLORER,
    platform: Platform.SUIEXPLORER,
    archetype: Archetype.CRYPTO,
    product: ProductType.CRYPTO,
  },
  [PlatformSubtypeId.KAKAO]: {
    id: PlatformSubtypeId.KAKAO,
    name: PlatformSubtype.KAKAO,
    platform: Platform.KAKAO,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.QUORA]: {
    id: PlatformSubtypeId.QUORA,
    name: PlatformSubtype.QUORA,
    platform: Platform.QUORA,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.NOTION]: {
    id: PlatformSubtypeId.NOTION,
    name: PlatformSubtype.NOTION,
    platform: Platform.NOTION,
    archetype: Archetype.DOMAIN,
    product: ProductType.DOMAIN,
  },
  [PlatformSubtypeId.LINE_PUBLISHER]: {
    id: PlatformSubtypeId.LINE_PUBLISHER,
    name: PlatformSubtype.LINE_PUBLISHER,
    platform: Platform.LINE,
    archetype: Archetype.SOCIAL_MEDIA,
    product: ProductType.SOCIAL_MEDIA,
  },
  [PlatformSubtypeId.LINE_POST]: {
    id: PlatformSubtypeId.LINE_POST,
    name: PlatformSubtype.LINE_POST,
    platform: Platform.LINE,
    archetype: Archetype.SOCIAL_MEDIA_POST,
    product: ProductType.SOCIAL_MEDIA,
  },
}
