import { Flex, Icon, MenuButton } from '@chakra-ui/react'
import { FaFilter } from 'react-icons/fa'
import { useAtom, useAtomValue } from 'jotai'
import { visibleAlertsFilters } from '@/atoms/alerts_filters'
import { ReportFilterType, ReportType } from '@/utils/constants'
import { ReportStatus } from '@/generated/enums'
import {
  BOOLEAN_FILTERS,
  getFiltersForReportType,
} from '@/components/web2/spoof_reports_table_filter_bar'
import React from 'react'
import DropdownMenu from '@/components/shared/forms/dropdown_menu'
import {
  addFilter,
  removeFiltersByType,
  ReportFilter,
} from '@/hooks/report_table_filters'
import DoppelIconButton from '@/components/shared/doppel_icon_button'
import { isOrgUnifiedViewAtom } from '@/atoms/views'

type AlertsTableFilterDropdownProps = {
  reportType: ReportType
  isEmployeeView: boolean
  selectedReportStatus: ReportStatus
  selectedReportFilters: ReportFilter[]
  setSelectedReportFilters: (filters: ReportFilter[]) => void
}

const AlertsTableFilterDropdown: React.FC<AlertsTableFilterDropdownProps> = ({
  reportType,
  isEmployeeView,
  selectedReportStatus,
  selectedReportFilters,
  setSelectedReportFilters,
}) => {
  const [selectedVisibleAlertsFilters, setSelectedVisibleAlertsFilters] =
    useAtom(visibleAlertsFilters)
  const isOrgUnifiedView = useAtomValue(isOrgUnifiedViewAtom)

  const visibleFilters = getFiltersForReportType(
    reportType,
    isEmployeeView,
    selectedReportStatus,
    isOrgUnifiedView,
  )
  return (
    <Flex>
      <DropdownMenu
        buttonDisplayFunction={() => 'Filter'}
        clearSelectionOptionText={null}
        customButton={
          <DoppelIconButton
            as={MenuButton}
            h={9}
            icon={<Icon as={FaFilter} h={3} w={3} />}
            w={9}
          />
        }
        disableClearSelection={true}
        icon={<FaFilter />}
        isMultiSelect={true}
        itemDisplayFunction={(item) => item}
        items={visibleFilters}
        selectedItems={[...selectedVisibleAlertsFilters]}
        setSelectedItems={async (newSelection) => {
          const newSelectionSet = new Set(newSelection as ReportFilterType[])
          setSelectedVisibleAlertsFilters(newSelectionSet)

          // Clone existing filters
          let newFilters = [...selectedReportFilters]

          for (const filter of newSelectionSet) {
            if (BOOLEAN_FILTERS.has(filter)) {
              newFilters = addFilter(filter, 'true', newFilters) // Modify newFilters in place
            }
          }

          // Identify filters to remove
          const filtersToRemove = selectedReportFilters
            .filter((filter) => !newSelectionSet.has(filter.filterType))
            .map((filter) => filter.filterType)

          // Remove filters and update state in a single batch
          setSelectedReportFilters(removeFiltersByType(filtersToRemove, newFilters))
        }}
        showSearchBar={false}
        w="40px"
      />
    </Flex>
  )
}

export default AlertsTableFilterDropdown
