import { Flex, Spacer, Switch, Text, Tooltip } from '@chakra-ui/react'
import {
  IoIosNavigate,
  IoIosPeople,
  IoMdAlert,
  IoMdArrowRoundUp,
  IoMdExpand,
  IoMdEye,
  IoMdImages,
  IoMdLink,
  IoMdPin,
  IoMdPricetags,
  IoMdSearch,
  IoMdTimer,
} from 'react-icons/io'
import { VscPreview } from 'react-icons/vsc'
import { FiLink } from 'react-icons/fi'
import {
  useGetBrandNamesWithOrgQuery,
  useGetTagsQuery,
  useGetUsersQuery,
  useGetAllExternalUsersQuery,
  useGetRulesQuery,
  Order_By,
} from '../../generated/graphql'
import { getOrgData, useIsEmployeeView, useOrgID } from '../../hooks/id_token_claims'
import {
  addFilter,
  containsFilterByType,
  genReportFilter,
  removeFilterByType,
} from '../../hooks/report_table_filters'
import {
  useSelectedReportType,
  useSelectedReportStatus,
  useSelectedReportFilters,
} from '../../pages/reports'
import {
  DarkWebNetwork,
  DarkWebSource,
  ReportFilterType,
  ReportType,
  Severity,
  UploaderOption,
} from '../../utils/constants'
import {
  darkWebSortingMechanismToDisplay,
  getSpoofSortTypes,
  sortingMechanismToDisplay,
} from '../../utils/reports/report_sorting_mechanism'
import {
  REPORT_TYPES_WITH_PLATFORMS,
  PLATFORM_TYPE_DISPLAY_MAP,
} from '../../utils/reports/report_utils'
import SearchFilterToken from '../collection_reports_table/search_filter_token'
import SortDropdown from '../sort_dropdown'
import {
  ReportFilterDropdown,
  SHOW_ALL_FILTER_VALUE,
  useSelectedContext,
} from '../tables/filter_dropdown'
import { Classification } from '../../utils/constants'
import { IoCubeSharp, IoPerson } from 'react-icons/io5'
import {
  AlertAgeStatus,
  BooleanFilterOptions,
  ReportSourceCategory,
  ReportSourceCategoryLabels,
  ReportStatus,
} from '@/generated/enums'
import { formatSnakeStringWithCapitalization } from '../../utils/string_utils'
import { FEATURES_TO_RENDER } from '@/components/web2/detection_reasons/detection_reasons_table'
import {
  getPlatformSubtypeDisplayValue,
  PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE,
} from '@/utils/reports/platform_subtype_utils'
import React, { useEffect, useMemo, useState } from 'react'
import { useSelectedPage } from '@/hooks/spoof_report_table_filters'
import { getSortedAlertAssigneesWithUnassigned } from '@/utils/enforcement_utils'
import { DOPPEL_INTERNAL_PURPLE } from '@/utils/style'
import { PLATFORM_SUBTYPE_METADATA_LIST } from '@/generated/platform_subtype_mapping'
import { visibleAlertsFilters } from '../../atoms/alerts_filters'
import { useAtom, useSetAtom } from 'jotai'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import DateFilterWrapper from '../collection_reports_table/date_filter_wrapper'
import AlertsTableFilterDropdown from '@/components/collection_reports_table/alerts_table_filter_dropdown'
import { Box } from '@chakra-ui/react'

const filterValueToDisplayWithUnverified = (value: string) => {
  const modifiedValue =
    value === Classification.UNKNOWN.toString() ? 'unverified' : value
  return formatSnakeStringWithCapitalization(modifiedValue)
}

const useDeleteVisibleAlertsFilter = () => {
  const setSelectedVisibleAlertsFilters = useSetAtom(visibleAlertsFilters)

  const deleteVisibleAlertsFilter = (filterType) => {
    setSelectedVisibleAlertsFilters(
      (prevFilters) =>
        new Set(Array.from(prevFilters).filter((filter) => filter !== filterType)),
    )
  }

  return deleteVisibleAlertsFilter
}

const getBrandDisplayValue = (value, brandsData, isOrgUnifiedView) => {
  if (value === ReportFilterType.Brand) {
    return ReportFilterType.Brand
  } else if (value === SHOW_ALL_FILTER_VALUE) {
    return SHOW_ALL_FILTER_VALUE
  } else if (!brandsData) {
    return ''
  }
  const brand = brandsData.spoofing_entities.find((entity) => entity.id === value)
  return isOrgUnifiedView
    ? `${brand.entity_name} (${brand.organization.name})`
    : brand.entity_name
}

const getTagDisplayValue = (value, isOrgUnifiedView) => {
  if (value === ReportFilterType.Tag) {
    return isOrgUnifiedView ? 'Global Tag' : 'Tag'
  }
  return value
}

const getSourceCategoryDisplayValue = (category: ReportSourceCategory) => {
  const label =
    ReportSourceCategoryLabels[category] ??
    formatSnakeStringWithCapitalization(category)
  return label
}

const showPlatformSubtypeFilter = (product: ReportType) => {
  if (product === ReportType.DARK_WEB) return false // using subtabs instead
  return REPORT_TYPES_WITH_PLATFORMS.includes(product)
}

const ReportFilterBarDropdown = ({
  style,
  isDateFilter = false,
  widthOverridePx,
  ...props
}: any) => {
  const [, setSelectedPage] = useSelectedPage()
  const Component = isDateFilter ? DateFilterWrapper : ReportFilterDropdown
  const isVertical = style === FilterBarStyle.VERTICAL

  return (
    <Component
      marginRight={2}
      maxDisplayLength={isVertical ? 20 : 10}
      minW={isVertical ? undefined : '50px'}
      onSelect={() => setSelectedPage(1)}
      w="auto"
      {...(isVertical && { marginBottom: 2 })}
      {...props}
    />
  )
}

export const ReportFilterBarSwitch = ({
  label,
  tooltipText,
  filterType,
  isInternal,
  ...props
}: any) => {
  const [, setSelectedPage] = useSelectedPage()
  const [selectedFilters, setSelectedFilters] = useSelectedContext(null)
  const setSelectedVisibleAlertsFilters = useSetAtom(visibleAlertsFilters)
  const [isChecked, setIsChecked] = useState(
    containsFilterByType(filterType, selectedFilters),
  )
  useEffect(() => {
    if (isChecked) {
      setSelectedFilters(addFilter(filterType, 'true', selectedFilters))
      setSelectedVisibleAlertsFilters((prevFilters) =>
        new Set(prevFilters).add(filterType),
      )
    } else {
      setSelectedFilters(removeFilterByType(filterType, selectedFilters))
      setSelectedVisibleAlertsFilters(
        (prevFilters) =>
          new Set(Array.from(prevFilters).filter((filter) => filter !== filterType)),
      )
    }
  }, [isChecked])

  return (
    <Tooltip fontSize={12} fontWeight={500} label={tooltipText} maxW={'250px'}>
      <Flex
        alignItems={'center'}
        bg={isInternal ? DOPPEL_INTERNAL_PURPLE : undefined}
        flexDir={'row'}
        w="fit-content"
        {...props}
      >
        <Switch
          isChecked={isChecked}
          onChange={(e) => {
            setIsChecked(e.target.checked)
            setSelectedPage(1)
          }}
          pl={2}
        />

        <Text fontSize={12} fontWeight={500} marginLeft={2} noOfLines={1}>
          {label}
        </Text>
      </Flex>
    </Tooltip>
  )
}

const generateFilterComponentsMap = ({
  style,
  brandOptions,
  spoofStatusFilterOptions,
  severityFilterOptions,
  sourceFilterOptions,
  uploaderFilterOptions,
  tagFilterOptions,
  tldFilterOptions,
  platformSubtypeFilterOptions,
  darkWebNetworkFilterOptions,
  darkWebSourceFilterOptions,
  detectionReasonFilterOptions,
  detectionRuleFilterOptions,
  detectionRuleDescriptions,
  isAgedFilterOptions,
  brandsData,
  platformSubtypesIdToDisplay,
  assigneeFilterOptions,
  assigneeIdToName,
  isOrgUnifiedView,
  deleteVisibleAlertsFilter,
  filterImprovementsEnabled,
  showAllFilters,
  booleanFilterOptions,
  orgName,
}) => {
  const alwaysEnableClearSelection = filterImprovementsEnabled && !showAllFilters

  return {
    [ReportFilterType.DateRange]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        filterType={ReportFilterType.DateRange}
        isDateFilter={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.DateRange)}
        style={style}
      />
    ),
    [ReportFilterType.LastActivityDate]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        filterType={ReportFilterType.LastActivityDate}
        isDateFilter={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.LastActivityDate)}
        style={style}
      />
    ),

    [ReportFilterType.Brand]: (
      <ReportFilterBarDropdown
        alphabetizeFilterOptions={true}
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        disabled={!brandOptions?.length}
        dropdownIcon={<IoMdImages size="18" />}
        filterOptions={brandOptions}
        filterType={ReportFilterType.Brand}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.Brand)}
        showSearchBar={true}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) =>
          getBrandDisplayValue(value, brandsData, isOrgUnifiedView)
        }
      />
    ),

    [ReportFilterType.Classification]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdExpand size="18" />}
        filterOptions={spoofStatusFilterOptions}
        filterType={ReportFilterType.Classification}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.Classification)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={filterValueToDisplayWithUnverified}
      />
    ),

    [ReportFilterType.Severity]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdAlert size="18" />}
        filterOptions={severityFilterOptions}
        filterType={ReportFilterType.Severity}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.Severity)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={formatSnakeStringWithCapitalization}
      />
    ),

    [ReportFilterType.Source]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdPin size="18" />}
        filterOptions={sourceFilterOptions}
        filterType={ReportFilterType.Source}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.Source)}
        showSearchBar={true}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={getSourceCategoryDisplayValue}
      />
    ),

    [ReportFilterType.Uploader]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        disabled={!uploaderFilterOptions?.length}
        dropdownIcon={<IoMdArrowRoundUp size="18" />}
        filterOptions={uploaderFilterOptions}
        filterType={ReportFilterType.Uploader}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.Uploader)}
        showSearchBar={true}
        style={style}
        useNewButton={true}
      />
    ),

    [ReportFilterType.Tag]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdPricetags size="18" />}
        filterOptions={tagFilterOptions}
        filterType={ReportFilterType.Tag}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.Tag)}
        showSearchBar={true}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) => getTagDisplayValue(value, isOrgUnifiedView)}
      />
    ),

    [ReportFilterType.TLD]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdLink size="18" />}
        filterOptions={tldFilterOptions}
        filterType={ReportFilterType.TLD}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.TLD)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={formatSnakeStringWithCapitalization}
      />
    ),

    [ReportFilterType.PlatformSubtype]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoCubeSharp size="18" />}
        filterOptions={platformSubtypeFilterOptions}
        filterType={ReportFilterType.PlatformSubtype}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.PlatformSubtype)}
        showSearchBar={true}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) => platformSubtypesIdToDisplay[value] ?? value}
      />
    ),

    [ReportFilterType.DarkWebNetwork]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoIosPeople size="18" />}
        filterOptions={darkWebNetworkFilterOptions}
        filterType={ReportFilterType.DarkWebNetwork}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.DarkWebNetwork)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={formatSnakeStringWithCapitalization}
      />
    ),

    [ReportFilterType.DarkWebSource]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoIosNavigate size="18" />}
        filterOptions={darkWebSourceFilterOptions}
        filterType={ReportFilterType.DarkWebSource}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.DarkWebSource)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={formatSnakeStringWithCapitalization}
      />
    ),

    [ReportFilterType.DetectionReason]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdEye size="18" />}
        filterOptions={detectionReasonFilterOptions}
        filterType={ReportFilterType.DetectionReason}
        isInternal={true}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.DetectionReason)}
        showSearchBar={true}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={formatSnakeStringWithCapitalization}
      />
    ),

    [ReportFilterType.DetectionRule]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdEye size="18" />}
        filterOptions={detectionRuleFilterOptions}
        filterType={
          isOrgUnifiedView
            ? `Global${ReportFilterType.DetectionRule}`
            : ReportFilterType.DetectionRule
        }
        isInternal={true}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.DetectionRule)}
        showSearchBar={true}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) => detectionRuleDescriptions[value] ?? value}
      />
    ),

    [ReportFilterType.IsAged]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdTimer size="18" />}
        filterOptions={isAgedFilterOptions}
        filterType={ReportFilterType.IsAged}
        isInternal={true}
        isMultiSelect={false}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.IsAged)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={formatSnakeStringWithCapitalization}
      />
    ),
    [ReportFilterType.Assignee]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoPerson size="18" />}
        filterOptions={assigneeFilterOptions}
        filterType={ReportFilterType.Assignee}
        isInternal={true}
        isMultiSelect={true}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.Assignee)}
        showSearchBar={true}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) => assigneeIdToName[value] ?? value}
      />
    ),
    [ReportFilterType.LinkedAlerts]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<FiLink size="18" />}
        filterOptions={booleanFilterOptions(ReportFilterType.LinkedAlerts)}
        filterType={ReportFilterType.LinkedAlerts}
        isMultiSelect={false}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.LinkedAlerts)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) =>
          value === BooleanFilterOptions.TRUE ? 'With Linked Alerts' : 'All Alerts'
        }
      />
    ),
    [ReportFilterType.NoEnforcementRequest]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        filterOptions={booleanFilterOptions(ReportFilterType.NoEnforcementRequest)}
        filterType={ReportFilterType.NoEnforcementRequest}
        isInternal={true}
        isMultiSelect={false}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.NoEnforcementRequest)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) =>
          value === BooleanFilterOptions.TRUE ? 'No Enforcement Requests' : 'All Alerts'
        }
      />
    ),
    [ReportFilterType.ShowOnlyParsed]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        filterOptions={booleanFilterOptions(ReportFilterType.ShowOnlyParsed)}
        filterType={ReportFilterType.ShowOnlyParsed}
        isMultiSelect={false}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.ShowOnlyParsed)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) =>
          value === BooleanFilterOptions.TRUE ? 'Show Only Parsed' : 'All Alerts'
        }
      />
    ),
    [ReportFilterType.hasPreview]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<VscPreview size="18" />}
        filterOptions={booleanFilterOptions(ReportFilterType.hasPreview)}
        filterType={ReportFilterType.hasPreview}
        isMultiSelect={false}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.hasPreview)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) =>
          value === BooleanFilterOptions.TRUE ? 'Has Preview' : 'All Alerts'
        }
      />
    ),
    [ReportFilterType.Attribution]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoMdArrowRoundUp size="18" />}
        filterOptions={[
          { filterType: ReportFilterType.Attribution, value: UploaderOption.DOPPEL },
          {
            filterType: ReportFilterType.Attribution,
            value: UploaderOption.ORGANIZATION,
          },
        ]}
        filterType={ReportFilterType.Attribution}
        isMultiSelect={false}
        maxDisplayLength={25}
        onClear={() => deleteVisibleAlertsFilter(ReportFilterType.Attribution)}
        style={style}
        useNewButton={true}
        valueToDisplayFunction={(value) => {
          if (value === UploaderOption.DOPPEL) {
            return 'Doppel'
          } else if (value === UploaderOption.ORGANIZATION) {
            return orgName
          } else if (!value) {
            return ''
          }
          return value
        }}
      />
    ),
    [ReportFilterType.OrganizationEmailLeaks]: (
      <ReportFilterBarDropdown
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        dropdownIcon={<IoPerson size="15" />}
        filterOptions={booleanFilterOptions(ReportFilterType.OrganizationEmailLeaks)}
        filterType={ReportFilterType.OrganizationEmailLeaks}
        onClear={() =>
          deleteVisibleAlertsFilter(ReportFilterType.OrganizationEmailLeaks)
        }
        style={style}
        useNewButton
        valueToDisplayFunction={(value) =>
          value === BooleanFilterOptions.TRUE ? 'Organization Email Leaks' : 'All Leaks'
        }
      />
    ),
  }
}

export const BOOLEAN_FILTERS = new Set([
  ReportFilterType.NoEnforcementRequest,
  ReportFilterType.LinkedAlerts,
  ReportFilterType.ShowOnlyParsed,
  ReportFilterType.hasPreview,
  ReportFilterType.OrganizationEmailLeaks,
])

export const DEFAULT_FILTERS = [
  ReportFilterType.DateRange,
  ReportFilterType.Brand,
  ReportFilterType.PlatformSubtype,
  ReportFilterType.Source,
  ReportFilterType.Tag,
  ReportFilterType.LinkedAlerts,
  ReportFilterType.Severity,
]

export const getFiltersForReportType = (
  reportType: ReportType,
  isEmployeeView: boolean,
  selectedReportStatus: ReportStatus,
  isOrgUnifiedView = false,
) => {
  if (reportType === ReportType.SUSPICIOUS_EMAILS) {
    return [
      ReportFilterType.DateRange,
      ReportFilterType.LastActivityDate,
      ReportFilterType.Tag,
    ]
  }
  const visibleFilters: ReportFilterType[] = [
    ReportFilterType.DateRange,
    ReportFilterType.LastActivityDate,
    ReportFilterType.Brand,
    ReportFilterType.Classification,
    ReportFilterType.Severity,
    ReportFilterType.Source,
    ReportFilterType.Uploader,
    ReportFilterType.Tag,
    ReportFilterType.hasPreview,
    ReportFilterType.Attribution,
  ] // all universal

  if (showPlatformSubtypeFilter(reportType)) {
    visibleFilters.push(ReportFilterType.PlatformSubtype)
  }

  if (reportType === ReportType.DOMAINS) {
    visibleFilters.push(ReportFilterType.TLD)
  }

  if (reportType === ReportType.DARK_WEB) {
    visibleFilters.push(ReportFilterType.DarkWebNetwork)
    visibleFilters.push(ReportFilterType.DarkWebSource)
    visibleFilters.push(ReportFilterType.ShowOnlyParsed)
    if (!isOrgUnifiedView) {
      visibleFilters.push(ReportFilterType.OrganizationEmailLeaks)
    }
  }

  if (isEmployeeView) {
    visibleFilters.push(ReportFilterType.DetectionReason)
    visibleFilters.push(ReportFilterType.DetectionRule)
    visibleFilters.push(ReportFilterType.Assignee)
    visibleFilters.push(ReportFilterType.NoEnforcementRequest)
  }

  if (isEmployeeView && selectedReportStatus === ReportStatus.REPORTED) {
    visibleFilters.push(ReportFilterType.IsAged)
  }

  visibleFilters.push(ReportFilterType.LinkedAlerts)

  return visibleFilters.sort()
}

export enum FilterBarStyle {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

type SpoofReportsTableFilterBarProps = {
  isOrgUnifiedView: boolean
  selectedOrgIds?: string[]
  style?: FilterBarStyle
  showSort?: boolean
  tags?: Array<{ name: string; is_internal: boolean }>
  showAllFilters?: boolean
}

// TODO: refactor higher products to utilize visible filters instead of hacky if statement style
const SpoofReportsTableFilterBar = ({
  isOrgUnifiedView,
  selectedOrgIds,
  style = FilterBarStyle.HORIZONTAL,
  showSort = true,
  tags,
  showAllFilters = false,
}: SpoofReportsTableFilterBarProps) => {
  const orgID = useOrgID()
  const [orgData] = getOrgData()
  const orgName = orgData?.name || 'Organization'

  const [isEmployeeView] = useIsEmployeeView()
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const [selectedVisibleAlertsFilters, setSelectedVisibleAlertsFilters] =
    useAtom(visibleAlertsFilters)
  const [selectedReportStatus] = useSelectedReportStatus()
  const filterImprovementsEnabled = useFeatureFlagEnabled('filter-improvements')
  const platformSubtypesData = Object.values(PLATFORM_SUBTYPE_METADATA_LIST).filter(
    (subtype) => subtype.product === PLATFORM_TYPE_DISPLAY_MAP[selectedReportType],
  )
  const { data: assigneeData } = useGetUsersQuery({
    variables: {
      usersWhere: { is_internal: { _eq: true } },
    },
  })

  const { data: externalUsersData } = useGetAllExternalUsersQuery({
    variables: {
      organization_ids: isOrgUnifiedView ? [...selectedOrgIds] : [orgID],
    },
  })

  const { data: ruleData } = useGetRulesQuery({
    variables: {
      rulesWhere: {
        is_enabled: { _eq: true },
        _or: [
          {
            brand_id: { _is_null: true },
          },
          ...(isOrgUnifiedView
            ? []
            : [
                {
                  spoofing_entity: {
                    organization: {
                      id: { _eq: orgID },
                    },
                  },
                },
              ]),
        ],
      },
      orderBy: [{ created_at: Order_By.Desc }],
    },
  })

  const detectionRuleDescriptions: { [key: string]: string } = {}
  ruleData?.rules.forEach((rule) => {
    detectionRuleDescriptions[rule.id] = rule.description
  })

  const uploadersInfo = useMemo(() => {
    const combinedUsers = (assigneeData?.users || []).concat(
      externalUsersData?.users || [],
    )
    return Array.from(new Set(combinedUsers)).sort((a, b) =>
      a.email.localeCompare(b.email),
    )
  }, [assigneeData, externalUsersData])

  const alertAssignees = assigneeData?.users || []

  const sortedAssignees = getSortedAlertAssigneesWithUnassigned(alertAssignees)

  const assigneeIdToName: { [key: string]: string } = Object.fromEntries(
    sortedAssignees.map((assignee) => [assignee.id, assignee.name]),
  )

  const platformSubtypes =
    (selectedReportType
      ? platformSubtypesData
      : Object.values(PLATFORM_SUBTYPE_METADATA_LIST)) || []

  const platformSubtypesIdToDisplay: { [key: string]: string } = Object.fromEntries(
    platformSubtypes.map((subtype) => [
      subtype.id,
      getPlatformSubtypeDisplayValue(subtype.name, selectedReportType),
    ]),
  )
  const visibleFilters = getFiltersForReportType(
    selectedReportType,
    isEmployeeView,
    selectedReportStatus,
  )

  const { data: brandsData } = useGetBrandNamesWithOrgQuery({
    variables: {
      where: {
        organization_id: { _in: isOrgUnifiedView ? selectedOrgIds : [orgID] },
      },
    },
  })

  const { data: tagsData } = useGetTagsQuery({
    variables: { orgIDs: isOrgUnifiedView ? [] : [orgID], includeGlobal: true },
  })

  const genReportFilters = (reportFilterType: ReportFilterType, values: string[]) => {
    return values.map((value) => genReportFilter(reportFilterType, value))
  }

  const tldValues = ['.com', '.xyz', '.us', '.io']

  const platformSubtypeFilterOptions = genReportFilters(
    ReportFilterType.PlatformSubtype,
    // entries sorted alphabetically, but with "other" at the end
    Object.entries(platformSubtypesIdToDisplay)
      .sort(([_a, a], [_b, b]) => {
        const aIsOther = a === PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE
        const bIsOther = b === PLATFORM_SUBTYPE_OTHER_DISPLAY_VALUE
        if (aIsOther !== bIsOther) {
          return aIsOther ? 1 : -1
        }
        return a.localeCompare(b)
      })
      .map(([id, _]) => id),
  )

  const assigneeFilterOptions = genReportFilters(
    ReportFilterType.Assignee,
    Object.entries(assigneeIdToName).map(([id, _]) => id),
  )

  const darkWebNetworkFilterOptions = genReportFilters(
    ReportFilterType.DarkWebNetwork,
    Object.values(DarkWebNetwork),
  )

  const darkWebSourceFilterOptions = genReportFilters(
    ReportFilterType.DarkWebSource,
    Object.values(DarkWebSource),
  )

  const spoofStatusFilterOptions = genReportFilters(
    ReportFilterType.Classification,
    Object.values(Classification).filter((type) =>
      [
        Classification.ACTIVE.toString(),
        Classification.PARKED.toString(),
        Classification.DOWN.toString(),
      ].includes(type),
    ),
  )

  const severityFilterOptions = genReportFilters(
    ReportFilterType.Severity,
    Object.values(Severity),
  )

  const sourceFilterOptions = genReportFilters(
    ReportFilterType.Source,
    Object.values(ReportSourceCategory),
  )

  const uploaderFilterOptions =
    externalUsersData && externalUsersData.users.length > 0
      ? isEmployeeView
        ? uploadersInfo &&
          uploadersInfo.map((uploader) =>
            genReportFilter(ReportFilterType.Uploader, uploader?.email),
          )
        : [
            genReportFilter(ReportFilterType.Uploader, 'Doppel Analyst'),
            ...externalUsersData.users
              .filter(
                (uploader) =>
                  uploader &&
                  !uploader.is_internal &&
                  uploader.email !== orgData[0]?.['organization_email'],
              )
              .map((uploader) =>
                genReportFilter(ReportFilterType.Uploader, uploader.email),
              ),
          ]
      : []

  const tldFilterOptions = genReportFilters(ReportFilterType.TLD, tldValues)

  const brandOptions =
    brandsData?.spoofing_entities &&
    brandsData?.spoofing_entities.map((entity) => {
      return genReportFilter(ReportFilterType.Brand, entity.id)
    })

  const tagFilterOptions =
    tags && tags.length > 0
      ? tags
          .filter((tag) => isEmployeeView || !tag.is_internal)
          .map((tag) => genReportFilter(ReportFilterType.Tag, tag.name))
      : tagsData?.tags &&
        tagsData?.tags
          .filter((tag) => isEmployeeView || !tag.is_internal)
          .map((tag) => genReportFilter(ReportFilterType.Tag, tag.name))

  const detectionReasonFilterOptions = genReportFilters(
    ReportFilterType.DetectionReason,
    FEATURES_TO_RENDER,
  )

  const detectionRuleFilterOptions = genReportFilters(
    ReportFilterType.DetectionRule,
    ruleData?.rules.map((rule) => rule.id) || [],
  )

  const isAgedFilterOptions = genReportFilters(
    ReportFilterType.IsAged,
    Object.values(AlertAgeStatus),
  )

  const booleanFilterOptions = (filterType: ReportFilterType) =>
    genReportFilters(filterType, Object.values(BooleanFilterOptions))

  const deleteVisibleAlertsFilter = useDeleteVisibleAlertsFilter()
  const filterComponentsMap = generateFilterComponentsMap({
    style,
    brandOptions,
    severityFilterOptions,
    spoofStatusFilterOptions,
    sourceFilterOptions,
    uploaderFilterOptions,
    tagFilterOptions,
    tldFilterOptions,
    platformSubtypeFilterOptions,
    darkWebNetworkFilterOptions,
    darkWebSourceFilterOptions,
    detectionReasonFilterOptions,
    detectionRuleFilterOptions,
    detectionRuleDescriptions,
    isAgedFilterOptions,
    brandsData,
    platformSubtypesIdToDisplay,
    assigneeFilterOptions,
    assigneeIdToName,
    isOrgUnifiedView,
    deleteVisibleAlertsFilter,
    filterImprovementsEnabled,
    showAllFilters,
    booleanFilterOptions,
    orgName,
  })
  const selectedFilters = [...selectedVisibleAlertsFilters].filter((filterType) =>
    visibleFilters.includes(filterType),
  )
  return (
    <Flex
      align="left"
      flexDirection={style == FilterBarStyle.VERTICAL ? 'column' : 'row'}
    >
      <Flex flexWrap="wrap" rowGap={2}>
        {filterImprovementsEnabled && !showAllFilters
          ? selectedFilters.map((filterType) => (
              <React.Fragment key={filterType}>
                {filterComponentsMap[filterType] || null}
              </React.Fragment>
            ))
          : visibleFilters.map((filterType) => (
              <React.Fragment key={filterType}>
                {filterComponentsMap[filterType] || null}
              </React.Fragment>
            ))}

        {containsFilterByType(ReportFilterType.Search, selectedReportFilters) && (
          <Box mr={2}>
            <SearchFilterToken
              dropdownIcon={<IoMdSearch size="16" />}
              filterType={ReportFilterType.Search}
              selectedReportFilters={selectedReportFilters}
              setSelectedReportFilters={setSelectedReportFilters}
            />
          </Box>
        )}

        <AlertsTableFilterDropdown
          isEmployeeView={isEmployeeView}
          reportType={selectedReportType}
          selectedReportFilters={selectedReportFilters}
          selectedReportStatus={selectedReportStatus}
          setSelectedReportFilters={setSelectedReportFilters}
        />
      </Flex>

      <Spacer />

      {showSort && !filterImprovementsEnabled && (
        <SortDropdown
          sortToDisplay={
            selectedReportType == ReportType.DARK_WEB
              ? darkWebSortingMechanismToDisplay
              : sortingMechanismToDisplay
          }
          supportedSortTypes={getSpoofSortTypes(
            selectedReportType,
            selectedReportStatus,
            isEmployeeView,
          )}
        />
      )}
    </Flex>
  )
}

export default SpoofReportsTableFilterBar
