import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Spinner,
  Text,
} from '@chakra-ui/react'
import {
  BUTTON_COLOR_PRIMARY,
  DOPPEL_DARK_SECONDARY,
  DOPPEL_TEXT_WHITE,
  DOPPEL_BLANK_SHADE,
  BUTTON_COLOR_PRIMARY_SHADE,
  DOPPEL_BREACH_RED_SHADE,
  DOPPEL_BREACH_RED,
  DOPPEL_TEXT_GREY,
  FONT_SIZE_SMALL,
  DOPPEL_BLANK_BUTTON_HOVER,
  DOPPEL_TEXT_BLACK,
} from '@/utils/style'
import { useState, Children, cloneElement, useEffect, ReactNode } from 'react'

/**
 * Props for the DoppelModal component.
 */
type DoppelModalProps = {
  /**
   * Whether the modal is currently open.
   */
  isOpen: boolean
  /**
   * Function to call when the modal should close.
   */
  onClose: () => void
  /**
   * Title displayed at the top of the modal.
   */
  title: ReactNode
  /**
   * Subtitle displayed below the title.
   */
  subtitle?: ReactNode
  /**
   * Label for the primary action button.
   */
  primaryLabel?: string
  /**
   * Content to display in the modal body.
   */
  body: JSX.Element
  /**
   * Function to call when the primary action button is clicked.
   */
  primaryAction?: (() => void) | (() => Promise<void>)
  /**
   * Whether the primary action is destructive/dangerous.
   */
  isDanger?: boolean
  /**
   * Whether the primary action is asynchronous and should show loading state.
   */
  isAsync?: boolean
  /**
   * Size of the modal - 'xs' | 'sm' | 'md' | 'lg' | 'xl'.
   */
  size?: string
  /**
   * Whether the primary action button is disabled.
   */
  isDisabled?: boolean
  /**
   * Maximum height to lock the modal to if there's too much content
   */
  maxHeight?: string
  /**
   * Label for the secondary action button.
   */
  secondaryLabel?: string
  /**
   * Function to call when the secondary action button is clicked.
   */
  secondaryAction?: (() => void) | (() => Promise<void>)
  /**
   * Whether the secondary action is destructive/dangerous.
   */
  isSecondaryDanger?: boolean
}

const DoppelModal = ({
  isOpen,
  onClose,
  title,
  subtitle,
  primaryLabel,
  body,
  primaryAction,
  isDanger = false,
  isAsync = false,
  size,
  isDisabled = false,
  maxHeight = 'auto',
  secondaryLabel,
  secondaryAction,
  isSecondaryDanger = false,
}: DoppelModalProps) => {
  const [isLoading, setIsLoading] = useState(false)
  const buttonColor = isDanger ? DOPPEL_BREACH_RED : BUTTON_COLOR_PRIMARY
  const buttonColorHover = isDanger
    ? DOPPEL_BREACH_RED_SHADE
    : BUTTON_COLOR_PRIMARY_SHADE

  // Secondary button styling
  const secondaryButtonColor = isSecondaryDanger
    ? DOPPEL_BREACH_RED
    : DOPPEL_BLANK_SHADE
  const secondaryButtonColorHover = isSecondaryDanger
    ? DOPPEL_BREACH_RED_SHADE
    : DOPPEL_BLANK_BUTTON_HOVER
  const secondaryTextColor = isSecondaryDanger ? DOPPEL_TEXT_BLACK : DOPPEL_TEXT_WHITE
  const onClickAction = isAsync
    ? async () => {
        setIsLoading(true)
        await primaryAction()
        setIsLoading(false)
      }
    : primaryAction

  // Reset loading state when modal is closed
  useEffect(() => {
    if (!isOpen) {
      setIsLoading(false)
    }
  })

  const modalAutoSizeProps = {
    // for if no size is specified
    minW: '400px',
    w: 'fit-content',
    maxW: '75vw',
    h: 'fit-content',
  }

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose} size={size}>
      <ModalOverlay />

      <ModalContent
        bg={DOPPEL_DARK_SECONDARY}
        maxH={maxHeight}
        {...(size ? {} : modalAutoSizeProps)}
        display="flex"
        flexDirection="column"
      >
        <ModalHeader
          fontSize={18}
          fontWeight={700}
          pb={2}
          textColor={DOPPEL_TEXT_WHITE}
        >
          {title}

          {subtitle && (
            <Text
              fontSize={FONT_SIZE_SMALL}
              fontWeight={400}
              textColor={DOPPEL_TEXT_GREY}
            >
              {subtitle}
            </Text>
          )}
        </ModalHeader>

        <ModalBody flex="1" overflowY="auto" textColor={DOPPEL_TEXT_WHITE}>
          {Children.map(body, (child) => cloneElement(child, { disabled: isLoading }))}
        </ModalBody>

        {primaryAction && (
          <ModalFooter
            borderTop={`1px solid ${DOPPEL_BLANK_SHADE}`}
            display={'flex'}
            w="full"
          >
            <Box
              _hover={{ bgColor: DOPPEL_BLANK_BUTTON_HOVER }}
              as={Button}
              bgColor={DOPPEL_BLANK_SHADE}
              disabled={isLoading}
              display={'flex'}
              flex={secondaryAction ? 0.33 : 0.5}
              mr={3}
              onClick={onClose}
              textColor={DOPPEL_TEXT_WHITE}
            >
              Cancel
            </Box>

            {secondaryAction && secondaryLabel && (
              <Box
                _hover={{ bgColor: secondaryButtonColorHover }}
                as={Button}
                bgColor={secondaryButtonColor}
                disabled={isLoading}
                display={'flex'}
                flex={0.33}
                mr={3}
                onClick={secondaryAction}
                textColor={secondaryTextColor}
              >
                {secondaryLabel}
              </Box>
            )}

            <Box
              _disabled={{ _hover: { bgColor: buttonColorHover } }}
              _hover={{ bgColor: buttonColorHover }}
              as={Button}
              bgColor={buttonColor}
              display={'flex'}
              flex={secondaryAction ? 0.33 : 0.5}
              isDisabled={isDisabled}
              isLoading={isLoading}
              onClick={onClickAction}
              textColor={DOPPEL_TEXT_BLACK}
            >
              {isLoading ? <Spinner /> : primaryLabel}
            </Box>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  )
}

export default DoppelModal
