import { IdToken, useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/nextjs'
import { createContext, FC, useContext, useEffect, useState } from 'react'
import { usePostHog } from 'posthog-js/react'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { organizationAtom, organizationsAndRolesAtom } from '@/atoms/organizations'
import { userIdAtom } from '@/atoms/user'

const IDTokenClaimsContext = createContext(null)

export function useOrgID() {
  const orgData = useAtomValue(organizationAtom)

  return orgData?.id
}

export const getOrgData = () => useAtom(organizationAtom)

export const useAvailableOrganizationIds = () => useAtom(organizationsAndRolesAtom)

export const useUserID = () => useAtom(userIdAtom)

export function useIsEmployeeView() {
  const { employeeView } = useContext(IDTokenClaimsContext)
  const [isEmployeeView, setIsEmployeeView] = employeeView
  return [isEmployeeView, setIsEmployeeView]
}

export function useShouldApplyCustomerPermissions() {
  const { applyCustomerPermissions } = useContext(IDTokenClaimsContext)
  const [shouldApplyCustomerPermissions, setShouldApplyCustomerPermissions] =
    applyCustomerPermissions
  return [shouldApplyCustomerPermissions, setShouldApplyCustomerPermissions]
}

export function useIsEmployee() {
  const { employee } = useContext(IDTokenClaimsContext)
  const [isEmployee] = employee
  return isEmployee
}

export function useEmail() {
  const { emailAddress } = useContext(IDTokenClaimsContext)
  const [email, setEmail] = emailAddress
  return [email, setEmail]
}

export const IDTokenClaimsProvider: FC<{ children }> = ({ children }) => {
  const setUserID = useSetAtom(userIdAtom)
  const [isEmployeeView, setIsEmployeeView] = useState(false)
  const [isEmployee, setIsEmployee] = useState(false)
  const [email, setEmail] = useState(null)
  const [shouldApplyCustomerPermissions, setShouldApplyCustomerPermissions] =
    useState(false)

  const { getIdTokenClaims, isAuthenticated } = useAuth0()
  const posthog = usePostHog()
  useEffect(() => {
    getIdTokenClaims().then((idTokenClaims: IdToken) => {
      if (!idTokenClaims?.hasOwnProperty('https://hasura.io/jwt/claims')) {
        return
      }

      const computedUserID =
        idTokenClaims['https://hasura.io/jwt/claims']['x-hasura-user-id']
      setUserID(computedUserID)
      const computedEmail = idTokenClaims['email']
      setEmail(computedEmail)

      const computedIsEmployee =
        idTokenClaims['https://hasura.io/jwt/claims']['x-hasura-is-employee'] == '1'
      setIsEmployeeView(computedIsEmployee)
      setIsEmployee(computedIsEmployee)

      Sentry.setContext('app_context', {
        userID: computedUserID,
        isEmployee: computedIsEmployee,
        email: computedEmail,
      })

      posthog.identify(computedUserID, {
        email: computedEmail,
        isEmployee: computedIsEmployee,
      })
    })
  }, [isAuthenticated, getIdTokenClaims])

  return (
    <IDTokenClaimsContext.Provider
      value={{
        employeeView: [isEmployeeView, setIsEmployeeView],
        employee: [isEmployee],
        emailAddress: [email, setEmail],
        applyCustomerPermissions: [
          shouldApplyCustomerPermissions,
          setShouldApplyCustomerPermissions,
        ],
      }}
    >
      {children}
    </IDTokenClaimsContext.Provider>
  )
}
