import { useRouter } from 'next/router'
import {
  createContext,
  FC,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react'
import { getOrgData, useIsEmployeeView, useUserID } from '../hooks/id_token_claims'
import {
  OrganizationType,
  ReportFilterType,
  ReportStatus,
  ReportType,
  ALL_MODULES_LABEL,
  ALL_MODULES_PATH,
} from '../utils/constants'
import {
  getReportTypeFromPath,
  isTableViewPath,
  REPORT_TYPE_TO_PATH,
  getReportStatuses,
  getProductsByReportType,
  PRODUCT_TYPE_TO_REPORT_TYPE_MAP,
} from '@/utils/reports/report_utils'
import PageLayout, { TabInfo } from './layout/page_layout'
import {
  useGetFilterablePlatformsByProductQuery,
  useGetUserByIdQuery,
  useAlertsQueueStateCountByProductQuery,
} from '../generated/graphql'
import { defaultSortingMechanism } from '../utils/reports/report_sorting_mechanism'
import * as Sentry from '@sentry/nextjs'
import { getFilterValue, removeFilterByType } from '@/hooks/report_table_filters'
import { parseQueryParams } from '@/utils/reports/query_params'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { ProductType } from '@/generated/enums'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import { userProductsAtom } from '@/atoms/permissions'
import { visibleAlertsFilters } from '@/atoms/alerts_filters'
import AlertsToolbar from '@/components/web2/alerts_toolbar'
import {
  DEFAULT_FILTERS,
  getFiltersForReportType,
} from '@/components/web2/spoof_reports_table_filter_bar'
import { isOrgUnifiedViewAtom } from '@/atoms/views'

const ReportContext = createContext(null)

export function useDefaultReportStatus() {
  const [userId] = useUserID()
  const { data: userInfo } = useGetUserByIdQuery({
    variables: { id: userId },
  })
  const [isEmployeeView] = useIsEmployeeView()

  const defaultStatus = isEmployeeView
    ? ReportStatus.ENUM_NEEDS_REVIEW
    : ReportStatus.ENUM_NEEDS_CONFIRMATION
  const [defaultReportStatus, setDefaultReportStatus] = useState(
    userInfo?.users?.[0]?.app_settings?.default_report_status ?? defaultStatus,
  )

  useEffect(() => {
    setDefaultReportStatus(
      userInfo?.users?.[0]?.app_settings?.default_report_status ?? defaultStatus,
    )
  }, [userInfo, defaultStatus])

  return defaultReportStatus
}

export function useSelectedReportFilters() {
  const context = useContext(ReportContext)
  if (context === null) {
    throw new Error('useSelectedReportFilters must be used within a ReportProvider')
  }
  return context.selectedReportFilterList
}

// if filters can't be saved or loaded, warn user but do not interrupt access
const saveFiltersToSessionStorage = (filters) => {
  if (typeof window !== 'undefined') {
    try {
      const filtersWithoutElasticsearch = filters.filter(
        (filter) => filter.filterType !== ReportFilterType.ElasticSearchIds,
      )
      sessionStorage.setItem(
        'doppel_alert_filters',
        JSON.stringify(filtersWithoutElasticsearch),
      ) // Use filtered version
    } catch (e) {
      alert('Warning: Filters could not be saved.')
    }
  }
}

const loadFiltersFromSessionStorage = () => {
  if (typeof window !== 'undefined') {
    try {
      const savedFilters = sessionStorage.getItem('doppel_alert_filters')
      return savedFilters ? JSON.parse(savedFilters) : null
    } catch (e) {
      alert('Warning: Filters could not be loaded.')
      return null
    }
  }
  return null
}

export function useSelectedReportType() {
  const [isEmployeeView] = useIsEmployeeView()
  const defaultReportStatus = useDefaultReportStatus()

  const { type } = useContext(ReportContext)
  const [selectedReportType, setSelectedReportType] = type
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const setSelectedVisibleAlertsFilters = useSetAtom(visibleAlertsFilters)
  const [, setSelectedSortingMechanism] = useSelectedSortingMechanism()
  const [, setSelectedReportStatus] = useSelectedReportStatus()

  const handleReportTypeChange = useCallback(
    (reportType) => {
      const newReportType = reportType === ALL_MODULES_LABEL ? null : reportType
      // Every time the report type changes, update the report type and remove non-applicable filters
      if (newReportType !== selectedReportType) {
        const newReportStatus = defaultReportStatus
        setSelectedReportType(newReportType)
        setSelectedReportStatus(newReportStatus)
        setSelectedSortingMechanism(
          defaultSortingMechanism(newReportStatus, newReportType, isEmployeeView),
        )

        // maintain all filters when switching between any views
        const visibleFilters = getFiltersForReportType(
          newReportType,
          isEmployeeView,
          newReportStatus,
        )
        const currentFilters = selectedReportFilters.filter((filter) =>
          visibleFilters.includes(filter.filterType),
        )
        setSelectedReportFilters(currentFilters)
        const currentFiltersTypes = new Set(
          currentFilters.map((filter) => filter.filterType),
        ) as Set<ReportFilterType>
        setSelectedVisibleAlertsFilters(
          new Set([...currentFiltersTypes, ...DEFAULT_FILTERS]),
        )
      }
    },
    [selectedReportType, selectedReportFilters, defaultReportStatus, isEmployeeView],
  )

  // We should eventually move away from having this look like a simple useState hook; since it's misleading
  return [selectedReportType, handleReportTypeChange]
}

export function useReportStatuses() {
  const { statuses } = useContext(ReportContext)
  const [reportStatuses, setReportStatuses] = statuses
  return [reportStatuses, setReportStatuses]
}

export function useSelectedReportStatus() {
  const { selectedStatus } = useContext(ReportContext)
  const [selectedReportStatus, setSelectedReportStatus] = selectedStatus
  return [selectedReportStatus, setSelectedReportStatus]
}

export function useSelectedSortingMechanism() {
  const { selectedSorting } = useContext(ReportContext)
  const [selectedSortingMechanism, setSelectedSortingMechanism] = selectedSorting
  return [selectedSortingMechanism, setSelectedSortingMechanism]
}

export function useShouldDisableArrowNavigation() {
  const { navigationDisabled } = useContext(ReportContext)
  const [shouldDisableArrowNavigation, setShouldDisableArrowNavigation] =
    navigationDisabled
  return [shouldDisableArrowNavigation, setShouldDisableArrowNavigation]
}

export function useSearchKey() {
  const { searchKey } = useContext(ReportContext)
  const [searchKeyValue, setSearchKeyValue] = searchKey
  return [searchKeyValue.toLowerCase(), setSearchKeyValue]
}

export function useSelectedAutocompleteReportId() {
  const { selectedAutocompleteReportId } = useContext(ReportContext)
  const [selectedAutocompleteReportIdValue, setSelectedAutocompleteReportId] =
    selectedAutocompleteReportId
  return [selectedAutocompleteReportIdValue, setSelectedAutocompleteReportId]
}

export function useClearReportSearch(): () => void {
  const { clearReportSearch } = useContext(ReportContext)
  return clearReportSearch
}

export function usePlatformsData() {
  const { platformsDataState } = useContext(ReportContext)
  const [platformsData, platformsDataLoading] = platformsDataState
  return [platformsData, platformsDataLoading]
}

export function useTabs() {
  const { tabs } = useContext(ReportContext)
  const [tabsValue, setTabsValue] = tabs
  return [tabsValue, setTabsValue]
}

export const HIGHEST_NUMBER_TO_SHOW_IN_TABS = 99

export const MAX_VISIBLE_TABS = 4

const Reports = ({ component }) => {
  const router = useRouter()
  const [selectedReportType, handleReportTypeChange] = useSelectedReportType()
  const userProducts = useAtomValue(userProductsAtom)
  const isRbacEnabled = useFeatureFlagEnabled('rbac-enabled')

  const { type } = useContext(ReportContext)
  const [, setSelectedReportType] = type // access pure setter

  const [tabsValue, setTabsValue] = useTabs()
  const [orgData] = getOrgData()
  const filterImprovementsEnabled = useFeatureFlagEnabled('filter-improvements')
  const [, setIsOrgUnifiedView] = useAtom(isOrgUnifiedViewAtom)

  setIsOrgUnifiedView(false)
  // Tabs to be shown directly
  const visibleTabs = tabsValue.slice(0, MAX_VISIBLE_TABS)
  // Tabs to be moved into dropdown
  const dropdownTabs = tabsValue.slice(MAX_VISIBLE_TABS)

  const { data: alertsNeedingConfirmationCounts } =
    useAlertsQueueStateCountByProductQuery({
      variables: {
        organizationId: orgData?.id,
        queueState: ReportStatus.ENUM_NEEDS_CONFIRMATION,
        limitPerProduct: HIGHEST_NUMBER_TO_SHOW_IN_TABS + 1,
      },
      skip: !orgData?.id,
    })

  const filteredAlertsNeedingConfirmationCounts = useMemo(() => {
    if (
      !alertsNeedingConfirmationCounts?.alerts_queue_state_count_by_product ||
      !orgData
    ) {
      return []
    }
    const enabledTabs = getTabsForOrg(orgData, userProducts, isRbacEnabled)
    const enabledReportTypes = new Set(enabledTabs.map((tab) => tab.name))

    return alertsNeedingConfirmationCounts.alerts_queue_state_count_by_product.filter(
      (count) => {
        const reportType = PRODUCT_TYPE_TO_REPORT_TYPE_MAP[count.product]
        return enabledReportTypes.has(reportType)
      },
    )
  }, [alertsNeedingConfirmationCounts, orgData, userProducts, isRbacEnabled])

  const reportCounts = filteredAlertsNeedingConfirmationCounts
    ? Object.fromEntries([
        [
          ALL_MODULES_LABEL,
          filteredAlertsNeedingConfirmationCounts.reduce(
            (sum, { alerts_count }) => sum + alerts_count,
            0,
          ),
        ],
        ...filteredAlertsNeedingConfirmationCounts.map(({ alerts_count, product }) => [
          PRODUCT_TYPE_TO_REPORT_TYPE_MAP[product],
          alerts_count,
        ]),
      ])
    : null

  useEffect(() => {
    if (!reportCounts) return

    const thereAreTabsWithOutdatedCount = tabsValue.some(
      (tab) => tab.count !== reportCounts[tab.name],
    )
    if (thereAreTabsWithOutdatedCount) {
      setTabsValue((prev) => {
        return prev.map((tab) => {
          const tabCount = reportCounts[tab.name]
          return {
            ...tab,
            count: tabCount,
          }
        })
      })
    }
  }, [reportCounts])

  useEffect(() => {
    // Set default report type for the root path only (app.doppel.com/)
    if (tabsValue.length > 0 && !selectedReportType && router.pathname === '/') {
      setSelectedReportType(
        tabsValue[0].name === ALL_MODULES_LABEL ? null : tabsValue[0].name,
      ) // initial load, so use pure setter
    }
  }, [tabsValue, router.pathname])

  return (
    <PageLayout
      component={component}
      dropdownTabs={filterImprovementsEnabled ? dropdownTabs : []}
      onTabChange={(tabInfo) => {
        handleReportTypeChange(tabInfo.name)
        // Always update the route to match the selected tab
        router.push(tabInfo.route)
      }}
      tabs={filterImprovementsEnabled ? visibleTabs : tabsValue}
      toolbar={
        filterImprovementsEnabled && (
          <AlertsToolbar displayAlertsPageActions={true} isOrgUnifiedView={false} />
        )
      }
    />
  )
}

export default Reports

export const ReportsProvider: FC<{ children }> = ({ children }) => {
  const router = useRouter()
  const [orgData] = getOrgData()
  const [isEmployeeView] = useIsEmployeeView()
  const userProducts = useAtomValue(userProductsAtom)
  const isRbacEnabled = useFeatureFlagEnabled('rbac')
  const isAllModulesTabEnabled = useFeatureFlagEnabled('all-modules-tab')

  const [tabsValue, setTabsValue] = useState<TabInfo[]>([])
  const [selectedReportType, setSelectedReportType] = useState<ReportType>(
    // query holds the dynamic route for /admin/alerts/[alert_type]
    getReportTypeFromPath(router) as ReportType,
  )
  const [selectedReportFilters, setSelectedReportFilters] = useState(
    loadFiltersFromSessionStorage() || [],
  )
  const setSelectedVisibleAlertsFilters = useSetAtom(visibleAlertsFilters)
  useEffect(() => {
    const visibleFilters = selectedReportFilters.map((filter) => filter.filterType)
    setSelectedVisibleAlertsFilters(new Set([...visibleFilters, ...DEFAULT_FILTERS]))
  }, [])
  const [reportStatuses, setReportStatuses] = useState([])
  const [selectedReportStatus, setSelectedReportStatus] = useState(
    ReportStatus.ENUM_NEEDS_REVIEW,
  )
  const [shouldDisableArrowNavigation, setShouldDisableArrowNavigation] =
    useState(false)

  const [searchKey, setSearchKey] = useState('')
  const [selectedAutocompleteReportId, setSelectedAutocompleteReportId] = useState('')
  const [selectedSortingMechanism, setSelectedSortingMechanism] = useState(
    defaultSortingMechanism(
      selectedReportStatus as ReportStatus,
      selectedReportType,
      isEmployeeView,
    ),
  )
  const defaultReportStatus = useDefaultReportStatus()

  useEffect(() => {
    saveFiltersToSessionStorage(selectedReportFilters)
  }, [selectedReportFilters])

  const products = getProductsByReportType(selectedReportType).filter(
    (product) =>
      ![
        ProductType.URL,
        ProductType.CODE_REPOS,
        ProductType.PII,
        ProductType.DOMAIN_PURCHASING,
      ].includes(product),
  )

  const { data: platformsResult, loading: platformsDataLoading } =
    useGetFilterablePlatformsByProductQuery({
      variables: {
        orgId: orgData?.id,
        products: products,
        reportStatuses: getReportStatuses(isEmployeeView),
      },
      skip: !orgData?.id,
    })

  const platformsData =
    platformsResult?.spoofing_reports?.map((platform) => platform.type) || []

  const setStateFromQueryParams = () => {
    // misses some cases which are handled elsewhere, such as default report type (first module) and search key

    const { reportFilters, reportStatus, sortingMechanism } = parseQueryParams(
      new URLSearchParams(window.location.search),
    )
    const reportType = getReportTypeFromPath(router) as ReportType
    setSelectedReportType(reportType)

    // filters in the URL, use those otherwise load them from sessionStorage
    if (reportFilters && reportFilters.length > 0) {
      setSelectedReportFilters(reportFilters)
      setSelectedVisibleAlertsFilters(
        new Set([
          ...reportFilters.map((filter) => filter.filterType),
          ...DEFAULT_FILTERS,
        ]),
      )
    } else {
      // load filters from sessionStorage
      const savedFilters = loadFiltersFromSessionStorage()
      if (savedFilters && savedFilters.length > 0) {
        setSelectedReportFilters(savedFilters)
        setSelectedVisibleAlertsFilters(
          new Set([
            ...savedFilters.map((filter) => filter.filterType),
            ...DEFAULT_FILTERS,
          ]),
        )
      }
    }

    setSelectedReportStatus(reportStatus ?? defaultReportStatus)
    if (sortingMechanism || reportType) {
      setSelectedSortingMechanism(
        sortingMechanism ??
          defaultSortingMechanism(
            (reportStatus ?? defaultReportStatus) as ReportStatus,
            reportType,
            isEmployeeView,
          ),
      )
    }
  }

  useEffect(() => {
    // only read query params if we're on the table view
    if (isTableViewPath(router)) {
      setStateFromQueryParams()
    }
  }, [router.asPath])

  const clearReportSearch = () => {
    setSearchKey('')
    setSelectedAutocompleteReportId('')
    setSelectedReportFilters(
      removeFilterByType(ReportFilterType.Search, selectedReportFilters),
    )
  }

  const isFirstOrgDataLoad = useRef(true)

  useEffect(() => {
    if (orgData) {
      setTabsValue([
        ...(isAllModulesTabEnabled
          ? [
              {
                name: ALL_MODULES_LABEL,
                route: `/${ALL_MODULES_PATH}`,
                isVisible: true,
              },
            ]
          : []),
        ...getTabsForOrg(
          orgData,
          userProducts ?? Object.values(ProductType),
          isRbacEnabled,
        ),
      ])
      if (isFirstOrgDataLoad.current) {
        const paramSearchKey = getFilterValue(
          ReportFilterType.Search,
          selectedReportFilters,
        )
        if (paramSearchKey) setSearchKey(paramSearchKey)
        isFirstOrgDataLoad.current = false
      } else {
        clearReportSearch()
      }
    }
  }, [orgData, userProducts, isRbacEnabled])

  useEffect(() => {
    Sentry.setContext('report_context', {
      selectedReportStatus: selectedReportStatus,
      selectedReportType: selectedReportType,
    })
  }, [selectedReportStatus, selectedReportType, isEmployeeView])

  return (
    <ReportContext.Provider
      value={{
        type: [selectedReportType, setSelectedReportType],
        statuses: [reportStatuses, setReportStatuses],
        selectedStatus: [selectedReportStatus, setSelectedReportStatus],
        navigationDisabled: [
          shouldDisableArrowNavigation,
          setShouldDisableArrowNavigation,
        ],
        searchKey: [searchKey, setSearchKey],
        selectedAutocompleteReportId: [
          selectedAutocompleteReportId,
          setSelectedAutocompleteReportId,
        ],
        clearReportSearch: clearReportSearch,
        platformsDataState: [platformsData, platformsDataLoading],
        tabs: [tabsValue, setTabsValue],
        selectedReportFilterList: [selectedReportFilters, setSelectedReportFilters],
        selectedSorting: [selectedSortingMechanism, setSelectedSortingMechanism],
      }}
    >
      {children}
    </ReportContext.Provider>
  )
}

export const getTabsForOrg = (
  orgData,
  userProducts?: ProductType[],
  isRbacEnabled?: boolean,
): Array<TabInfo> => {
  const isMarketplace = orgData.organization_type == OrganizationType.MARKETPLACE
  const shouldShowWeb2Tabs = orgData.is_web2_enabled && !isMarketplace

  const filteredAlertTypes = Object.entries(PRODUCT_TYPE_TO_REPORT_TYPE_MAP)
    .filter(([productType]) => userProducts?.includes(productType as ProductType))
    .map(([, reportType]) => reportType)

  // set order for tabs too
  const reportTypeToIsVisible: Partial<Record<ReportType, boolean>> = {
    [ReportType.DOMAINS]: shouldShowWeb2Tabs,
    [ReportType.SOCIAL_MEDIA]: orgData.is_social_media_detection_enabled,
    [ReportType.MOBILE_APPS]: orgData.is_app_detection_enabled,
    [ReportType.DARK_WEB]: orgData.is_dark_web_detection_enabled,
    [ReportType.DARK_MARKET]: orgData.is_dark_market_detection_enabled,
    [ReportType.ECOMMERCE]: orgData.is_ecommerce_detection_enabled,
    [ReportType.EMAIL]: orgData.is_email_detection_enabled,
    [ReportType.PAID_ADS]: orgData.is_paid_ads_detection_enabled,
    [ReportType.CRYPTO]: orgData.is_crypto_detection_enabled,
    [ReportType.METAVERSE]: [
      '1dff6f78-5a80-4cb7-a263-7a39f3cfbd18',
      '717b1cc4-62a7-4d88-8b73-1a5d4617bb05', // Sony Music
    ].includes(orgData.id),
    [ReportType.CODE_REPOS]: orgData.id === '46aec505-64a2-4304-bf93-dfae13f3e4ec',
    [ReportType.TELCO]: orgData.is_telco_detection_enabled,
  }

  return Object.entries(reportTypeToIsVisible)
    .filter(
      ([reportType, isVisible]) =>
        isVisible &&
        (isRbacEnabled ? filteredAlertTypes.includes(reportType as ReportType) : true),
    )
    .map(([reportType, isVisible]) => ({
      name: reportType,
      route: `/${REPORT_TYPE_TO_PATH[reportType]}`,
      isVisible,
    }))
}
