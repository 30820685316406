import { Button } from '@chakra-ui/react'
import {
  DOPPEL_DARK_CLICKABLE,
  DOPPEL_DARK_CLICKABLE_HOVER,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_WHITE,
  FONT_SIZE_MEDIUM,
} from '../../utils/style'

const DoppelDefaultButton = ({ children, isInternal = false, ...props }) => {
  return (
    <Button
      _hover={{ background: DOPPEL_DARK_CLICKABLE_HOVER }}
      as={Button}
      bgColor={isInternal ? DOPPEL_INTERNAL_PURPLE : DOPPEL_DARK_CLICKABLE}
      fontSize={FONT_SIZE_MEDIUM}
      minWidth="80px"
      textColor={DOPPEL_WHITE}
      {...props}
    >
      {children}
    </Button>
  )
}

export default DoppelDefaultButton
