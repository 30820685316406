import { useAuth0 } from '@auth0/auth0-react'
import { Button } from '@chakra-ui/react'
import {
  DOPPEL_TERMINAL_BLUE,
  DOPPEL_WHITE,
  DOPPEL_CYAN_200,
  DOPPEL_CYAN_300,
  DOPPEL_GREY_900,
} from '../utils/style'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const LoginButton: React.FC = () => {
  const { loginWithRedirect } = useAuth0()
  const isRebrandingEnabled = useFeatureFlagEnabled('doppel-rebranding-signup')

  return !isRebrandingEnabled ? (
    <Button
      bgColor={DOPPEL_WHITE}
      color={DOPPEL_TERMINAL_BLUE}
      mr={4}
      onClick={() => {
        loginWithRedirect({
          appState: {
            auth0RedirectPath: window.location.href.slice(
              window.location.origin.length,
            ),
          },
        })
      }}
      size={'lg'}
      variant={'solid'}
    >
      Log In
    </Button>
  ) : (
    <Button
      _hover={{
        bgColor: DOPPEL_CYAN_300,
      }}
      bgColor={DOPPEL_CYAN_200}
      borderRadius="25px"
      color={DOPPEL_GREY_900}
      fontWeight={400}
      mr={4}
      onClick={() => {
        loginWithRedirect({
          appState: {
            auth0RedirectPath: window.location.href.slice(
              window.location.origin.length,
            ),
          },
        })
      }}
      size="lg"
      variant="solid"
    >
      Log in or sign up
    </Button>
  )
}

export default LoginButton
