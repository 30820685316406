import { HIGHEST_NUMBER_TO_SHOW_IN_TABS } from '@/pages/reports'
import { BUTTON_COLOR_PRIMARY, DOPPEL_TEXT_BLACK, FONT_SIZE_SMALL } from '@/utils/style'
import { Badge } from '@chakra-ui/react'

const formatCount = (count: number): string => {
  if (count >= HIGHEST_NUMBER_TO_SHOW_IN_TABS) {
    return `${HIGHEST_NUMBER_TO_SHOW_IN_TABS}+`
  }
  return count.toString()
}

export default function CountBadge({ count }: { count: number }) {
  return (
    <Badge
      alignItems="center"
      bg={BUTTON_COLOR_PRIMARY}
      borderRadius="12px"
      color={DOPPEL_TEXT_BLACK}
      display="flex"
      fontSize={FONT_SIZE_SMALL}
      fontWeight={600}
      justifyContent="center"
      lineHeight="1"
      marginLeft="6px"
      padding="5px 8px"
    >
      {formatCount(count)}
    </Badge>
  )
}
