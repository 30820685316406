import {
  Box,
  Checkbox,
  Heading,
  Link,
  Stack,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react'
import DoppelModal from '@/components/doppel_design/doppel_modal'
import { getOrgData } from '@/hooks/id_token_claims'
import DoppelDefaultButton from '../shared/doppel_default_button'
import { FaFileExport } from 'react-icons/fa'
import {
  useReportStatuses,
  useSelectedReportFilters,
  useSelectedReportType,
} from '@/pages/reports'
import { visibleAlertsFilters } from '@/atoms/alerts_filters'
import SpoofReportsTableFilterBar from '../web2/spoof_reports_table_filter_bar'
import { useState, useEffect } from 'react'
import { getStatusLabel, ReportFilterType } from '@/utils/constants'
import {
  useRequestAlertCsvExportMutation,
  useAlertCsvDownloadUrlSubscription,
  Spoofing_Reports_Bool_Exp,
} from '@/generated/graphql'
import { PLATFORM_TYPE_DISPLAY_MAP } from '@/utils/reports/report_utils'
import { Deferred } from '@/utils/deferred'
import UnifiedReportsTableFilters from '../admin/unified_reports_table_filters'
import { useSetAtom } from 'jotai'

type RequestCSVExportButtonProps = {
  makeWhereClause: (selectedQueues: string[]) => Spoofing_Reports_Bool_Exp
  isOrgUnifiedView?: boolean
}

export const useRequestCSVExport = (
  makeWhereClause: (selectedQueues: string[]) => Spoofing_Reports_Bool_Exp,
  selectedReportType,
  availableQueues,
  selectedQueues,
  selectedReportFilters,
  orgData,
  isOrgUnifiedView?: boolean,
) => {
  const toast = useToast()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const setSelectedVisibleAlertsFilters = useSetAtom(visibleAlertsFilters)
  const currentProduct = PLATFORM_TYPE_DISPLAY_MAP[selectedReportType]
  const [requestAlertCSVExport] = useRequestAlertCsvExportMutation()
  const [requestId, setRequestId] = useState<string | null>(null)
  const [toastDeferred, setToastDeferred] = useState<Deferred<string> | null>(null)

  // Subscribe to download URL updates
  const { data } = useAlertCsvDownloadUrlSubscription({
    variables: { id: requestId },
    skip: !requestId,
  })

  useEffect(() => {
    if (!data) return
    const downloadUrl = data.request_alert_csv_export?.output?.download_url
    const errors = data.request_alert_csv_export?.errors
    if (downloadUrl) {
      toastDeferred?.resolve(downloadUrl)
      // trigger download
      const link = document.createElement('a')
      link.href = downloadUrl
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    } else if (errors) {
      toastDeferred?.reject(errors.error)
    } else {
      // data is not ready
      return
    }
    // reset request ID as soon as data is fetched
    setRequestId(null)
  }, [data])

  useEffect(() => {
    if (!toastDeferred) return

    toast.promise(toastDeferred.promise, {
      loading: {
        title: 'CSV Export Request Submitted',
        description: (
          <>
            <Text>Your request is being processed.</Text>

            <Text>(Large exports may take a few minutes to complete.)</Text>
          </>
        ),
      },
      success: (downloadUrl) => {
        return {
          title: 'CSV Export Ready',
          description: (
            <>
              <Text>Your CSV export is being downloaded.</Text>

              <Text>
                (
                <Link href={downloadUrl} rel="noopener noreferrer" target="_blank">
                  Click here
                </Link>{' '}
                if it does not start automatically)
              </Text>
            </>
          ),
          duration: 9000,
          isClosable: true,
        }
      },
      error: (error) => {
        return {
          title: 'Error in CSV Export',
          description: error.message,
          duration: 9000,
          isClosable: true,
        }
      },
    })

    // handle cleanup
    toastDeferred.promise.finally(() => {
      setToastDeferred(null)
    })
  }, [toastDeferred])

  const onSubmit = async () => {
    // filter queues as safety check
    const queues = selectedQueues.filter((queue) => availableQueues.includes(queue))
    const whereClause = makeWhereClause(queues)
    setSelectedVisibleAlertsFilters(
      new Set(selectedReportFilters.map((filter) => filter.filterType)),
    )
    const actionResponse = await requestAlertCSVExport({
      variables: {
        organization_id: isOrgUnifiedView ? undefined : orgData.id,
        product: currentProduct,
        where_clause: whereClause,
      },
    })
    const actionId = actionResponse?.data?.request_alert_csv_export
    if (actionId) {
      setRequestId(actionId)
      setToastDeferred(new Deferred()) // handled by subscription
    }
    onClose()
  }

  return {
    isOpen,
    onOpen,
    onClose,
    onSubmit,
    currentProduct,
  }
}

const RequestCSVExportButton = ({
  makeWhereClause,
  isOrgUnifiedView,
}: RequestCSVExportButtonProps) => {
  const [availableQueues] = useReportStatuses()
  const [selectedQueues, setSelectedQueues] = useState(availableQueues)
  useEffect(() => setSelectedQueues(availableQueues), [availableQueues])
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportFilters] = useSelectedReportFilters()
  const [orgData] = getOrgData()

  const { isOpen, onOpen, onClose, onSubmit, currentProduct } = useRequestCSVExport(
    makeWhereClause,
    selectedReportType,
    availableQueues,
    selectedQueues,
    selectedReportFilters,
    orgData,
    isOrgUnifiedView,
  )

  const modalBody = (
    <Stack spacing={8} width="100%">
      <Box minW="200px">
        <Heading as="h3" mb={4} size="sm">
          Select queues to include
        </Heading>

        <VStack align="start">
          {availableQueues.map((status) => (
            <Checkbox
              isChecked={selectedQueues.includes(status)}
              key={status}
              onChange={(e) => {
                // Toggle the selected status
                if (e.target.checked) {
                  setSelectedQueues([...selectedQueues, status])
                } else {
                  setSelectedQueues(selectedQueues.filter((s) => s !== status))
                }
              }}
            >
              {getStatusLabel(status, currentProduct)}
            </Checkbox>
          ))}
        </VStack>
      </Box>

      <Box>
        <Heading as="h3" mb={4} size="sm">
          Select filters to apply
        </Heading>

        {isOrgUnifiedView && <UnifiedReportsTableFilters />}

        <Box mb={4} />

        <SpoofReportsTableFilterBar
          isOrgUnifiedView={isOrgUnifiedView}
          selectedOrgIds={selectedReportFilters
            .filter((filter) => filter.filterType === ReportFilterType.Organization)
            .map((filter) => filter.value)}
          showAllFilters
          showSort={false}
        />
      </Box>
    </Stack>
  )

  return (
    <>
      <DoppelDefaultButton
        leftIcon={<FaFileExport size="14px" />}
        marginRight={4}
        onClick={onOpen}
      >
        Request CSV Export
      </DoppelDefaultButton>

      <DoppelModal
        body={modalBody}
        isOpen={isOpen}
        onClose={onClose}
        primaryAction={onSubmit}
        primaryLabel="Request Export"
        size="2xl"
        title={`Request CSV Export for ${
          isOrgUnifiedView ? 'All Organizations' : orgData.name
        }${currentProduct ? ` | ${currentProduct}` : ''}`}
      ></DoppelModal>
    </>
  )
}

export default RequestCSVExportButton
