import { FC, useEffect } from 'react'
import {
  useGetLastOrgContextLazyQuery,
  useGetOrganizationIdsForUserWithRolesLazyQuery,
  useGetUserPermissionsAndProductsLazyQuery,
} from '@/generated/graphql'
import { useUserID } from '@/hooks/id_token_claims'
import { Permission, ProductType } from '@/generated/enums'
import {
  userPermissionsDataAtom,
  userRoleAtom,
  userPermissionsAtom,
  userProductsAtom,
} from '@/atoms/permissions'
import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import {
  organizationAtom,
  organizationIdAtom,
  organizationsAndRolesAtom,
} from '@/atoms/organizations'
import { captureException } from '@sentry/nextjs'
import { OrganizationIdWithRole } from '@/types/organizations'

const parseRolePermissions = (permissions): Set<Permission> =>
  new Set<Permission>(
    permissions.map((permission) => {
      return `${permission.permission.resource}.${
        permission.permission.feature ? `${permission.permission.feature}.` : ''
      }${permission.permission.action}` as Permission
    }),
  )

export const InitializeAtoms: FC = () => {
  // User ID for all queries
  const [userID] = useUserID()

  // Permission atoms
  const [userData, setUserData] = useAtom(userPermissionsDataAtom)
  const setUserRole = useSetAtom(userRoleAtom)
  const setUserPermissions = useSetAtom(userPermissionsAtom)
  const setUserProducts = useSetAtom(userProductsAtom)

  // Organization atoms
  const orgId = useAtomValue(organizationIdAtom)
  const setOrgData = useSetAtom(organizationAtom)
  const setOrgId = useSetAtom(organizationIdAtom)
  const setOrganizationsAndRoles = useSetAtom(organizationsAndRolesAtom)

  // User permissions query
  const [
    getUserPerms,
    { data: userPermsData, loading: userPermsLoading, error: userPermsError },
  ] = useGetUserPermissionsAndProductsLazyQuery({
    variables: {
      user_id: userID,
    },
  })

  // Organizations query
  const [getOrgs, { data: orgs, loading: orgsLoading, error: orgsError }] =
    useGetOrganizationIdsForUserWithRolesLazyQuery({
      variables: {
        user_id: userID,
      },
    })

  // Current organization query
  const [
    getLastOrgContext,
    { data: lastOrgData, loading: lastOrgLoading, error: lastOrgError },
  ] = useGetLastOrgContextLazyQuery({
    variables: {
      user_id: userID,
    },
  })

  // Initialize user permissions
  useEffect(() => {
    if (userID) {
      getOrgs()
      getUserPerms()
    }
  }, [userID])

  useEffect(() => {
    if (userPermsData) {
      if (userPermsData?.users?.length > 0) {
        const user = userPermsData?.users?.[0]
        setUserData(user)
      }
    }
  }, [userPermsData])

  useEffect(() => {
    if (userData) {
      setUserRole(userData?.role)
      setUserPermissions(parseRolePermissions(userData?.role?.role_permissions))
      setUserProducts(
        userData?.products?.map((product) => product.product as ProductType),
      )
    }
  }, [userData])

  useEffect(() => {
    if (orgs?.users_by_pk?.organizations) {
      const orgIdsAndRoles = orgs.users_by_pk.organizations.map(
        (org) =>
          ({
            organization_id: org?.organization?.id,
            name: org?.organization?.name,
            role_id: org?.role_id,
          } as OrganizationIdWithRole),
      )
      setOrganizationsAndRoles(orgIdsAndRoles)
    }
  }, [orgs])

  // Initialize current organization
  useEffect(() => {
    if (userID && !lastOrgData && !lastOrgLoading) {
      getLastOrgContext()
    }
  }, [userID, lastOrgData, lastOrgLoading, orgId])

  useEffect(() => {
    if (lastOrgData) {
      if (lastOrgData?.users_by_pk?.last_org) {
        if (lastOrgData.users_by_pk.last_org.id !== orgId) {
          setOrgId(lastOrgData.users_by_pk.last_org.id)
        }
        setOrgData(lastOrgData.users_by_pk.last_org)
      } else {
        captureException('Error fetching and setting organization data', {
          extra: { lastOrgData },
        })
      }
    }
  }, [lastOrgData])

  return null
}
