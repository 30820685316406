import { Permission, ProductType, ProductTypePath, Role } from '@/generated/enums'
import { PATH_TO_PRODUCT_TYPE } from './reports/report_utils'
import { useAtomValue } from 'jotai'
import { userPermissionsAtom, userRoleAtom } from '@/atoms/permissions'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { ALL_MODULES_PATH } from '@/utils/constants'
import { TEST_ORG_ID } from '@/utils/constants'

const ORG_COLUMN_TO_PRODUCT_TYPE: Record<string, ProductType> = {
  is_web2_enabled: ProductType.DOMAIN,
  is_social_media_detection_enabled: ProductType.SOCIAL_MEDIA,
  is_app_detection_enabled: ProductType.MOBILE_APP,
  is_crypto_detection_enabled: ProductType.CRYPTO,
  is_paid_ads_detection_enabled: ProductType.PAID_AD,
  is_email_detection_enabled: ProductType.EMAIL,
  is_ecommerce_detection_enabled: ProductType.ECOMMERCE,
  is_dark_web_detection_enabled: ProductType.DARKWEB,
  is_dark_market_detection_enabled: ProductType.DARK_MARKET,
  is_telco_detection_enabled: ProductType.TELCO,
  is_suspicious_emails_enabled: ProductType.SUSPICIOUS_EMAILS,
  is_pii_detection_enabled: ProductType.PII,
  is_domain_purchasing_enabled: ProductType.DOMAIN_PURCHASING,
}

const ASSIGNABLE_ROLES = [Role.CUSTOMER_ADMIN, Role.CUSTOMER_USER, Role.CUSTOMER_VIEWER]

export const getOrgEnabledProducts = (
  org: Record<string, any>,
  isInternal = false,
): ProductType[] => {
  if (isInternal) {
    return Array.from(new Set(Object.values(ORG_COLUMN_TO_PRODUCT_TYPE)))
  }
  return Object.entries(ORG_COLUMN_TO_PRODUCT_TYPE)
    .filter(([column, _]) => column in org && org[column])
    .map(([_, productType]) => productType)
}

// Should replace current isEmployee at some point
export const isEmployee = (userRole: Role): boolean => userRole === Role.EMPLOYEE

export const isCustomerSuperAdmin = (userRole: Role): boolean =>
  userRole === Role.CUSTOMER_SUPER_ADMIN

export const assignableRoles = (userRole: Role, organizationId: string): Role[] => {
  if (isEmployee(userRole)) {
    return organizationId === TEST_ORG_ID
      ? [Role.EMPLOYEE, Role.CUSTOMER_SUPER_ADMIN, ...ASSIGNABLE_ROLES]
      : [Role.CUSTOMER_SUPER_ADMIN, ...ASSIGNABLE_ROLES]
  }
  return ASSIGNABLE_ROLES
}

export const isUserAuthorizedForProductType = (
  productType: ProductType,
  userProducts: ProductType[],
  isRbacEnabled: boolean,
): boolean => {
  if (isRbacEnabled && userProducts != null) {
    return userProducts.includes(productType)
  }
  return true
}

export const isUserAuthorizedForPage = (
  pathname: string,
  userProducts: ProductType[],
  isRbacEnabled: boolean,
): boolean => {
  const formattedPathname = pathname.replace('/', '') as ProductTypePath
  if (
    isRbacEnabled &&
    userProducts !== null &&
    Object.values(ProductTypePath).includes(formattedPathname)
  ) {
    return (
      userProducts.includes(PATH_TO_PRODUCT_TYPE[formattedPathname]) ||
      formattedPathname === (ALL_MODULES_PATH as ProductTypePath)
    )
  }
  return true
}

export const useIsUserAuthorizedForAction = (permission: Permission) => {
  const isRbacRoleRestrictEnabled = useFeatureFlagEnabled('rbac-role-restrict')
  const userPermissions = useAtomValue(userPermissionsAtom)

  if (
    !isRbacRoleRestrictEnabled &&
    isRbacRoleRestrictEnabled !== undefined // posthog taking a while to load here
  )
    return true

  return userPermissions?.has(permission)
}

export const useUserAuthorizedTagActions = () => {
  const isRbacRoleRestrictEnabled = useFeatureFlagEnabled('rbac-role-restrict')
  const userPermissions = useAtomValue(userPermissionsAtom)

  if (!isRbacRoleRestrictEnabled)
    return { canCreateTag: true, canUpdateTag: true, canDeleteTag: true }

  return {
    canCreateTag: userPermissions?.has(Permission.TAG_CREATE),
    canUpdateTag: userPermissions?.has(Permission.TAG_UPDATE),
    canDeleteTag: userPermissions?.has(Permission.TAG_DELETE),
  }
}

export const useUserAuthorizedUserActions = () => {
  const userPermissions = useAtomValue(userPermissionsAtom)

  return {
    canCreateUser: userPermissions?.has(Permission.USER_CREATE),
    canUpdateUser: userPermissions?.has(Permission.USER_UPDATE),
    canDeleteUser: userPermissions?.has(Permission.USER_DELETE),
  }
}

export const useUserRole = (): Role => {
  const userRole = useAtomValue(userRoleAtom)
  return userRole?.name as Role
}
