import { useEffect, useMemo, useState } from 'react'
import {
  useGetSpoofingReportsLazyQuery,
  useSearchSpoofingReportsCountQuery,
  useSearchSpoofingReportsQuery,
  Spoofing_Reports_Bool_Exp,
  Order_By,
  useGetCustomerAssetsWhereQuery,
} from '@/generated/graphql'
import { spoofingReportsWhereClause } from '@/hooks/spoof_report_table_filters'
import { spoofingReportsOrderByClause } from '@/utils/reports/report_sorting_mechanism'
import { cleanUrl } from '@/utils/domain_utils'
import { getTabsForOrg } from '@/pages/reports'
import { DarkWebPlatforms, ReportType } from '@/utils/constants'
import {
  generateReportUrl,
  generateTableViewUrl,
  getDoppelUrlFromId,
  getExternalReportId,
} from '@/utils/reports/report_utils'
import { availableStatuses } from '@/components/web2/spoof_report_tab'
import usePreserveScrollPosition from './preserve_scroll_position'
import { ProductType, SafelistStatus } from '@/generated/enums'

/**
 * useAlertsTable is a hook that provides data for the alerts table.
 *
 * It fetches the spoofing reports based on the filters and sorting options,
 * cleans the data, and formats it for the table.
 *
 * It also provides functions to handle the table's pagination and sorting.
 */
export const useAlertsTable = (
  isOrgUnifiedView,
  selectedReportFilters,
  router,
  orgId,
  orgData,
  userId,
  selectedPage,
  setSelectedPage,
  selectedSpoofReport,
  setSelectedSpoofReport,
  setReportStatuses,
  selectedReportStatus,
  selectedSortingMechanism,
  isEmployeeView,
  searchKey,
  selectedAutocompleteReportId,
  selectedReportExposureType,
  setAlertsTableResult,
  selectedReportType,
  selectedPageSize,
  userProducts,
  isRbacEnabled,
) => {
  // Should refactor to leverage selectedAutocompleteReportId and refactor other use cases of state
  // report ID to open on top of current logic
  const [overrideOpenReportId, setOverrideOpenReportId] = useState(null)
  const [domainCustomerAssets, setDomainCustomerAssets] = useState([])
  const [toRefreshCache, setToRefreshCache] = useState(false)

  const isDarkWeb = selectedReportType === ReportType.DARK_WEB

  const selectedReportTypes = useMemo(() => {
    if (!selectedReportType) {
      if (userProducts) {
        const tabsForOrg = getTabsForOrg(orgData, userProducts, isRbacEnabled)
        return tabsForOrg.map((tab) => tab.name)
      } else {
        return []
      }
    }
    return selectedReportType
  }, [selectedReportType, userProducts])

  // fetches an org's domain customer assets that do not have a subdpath (designated by a /)
  // will be used to filter by employee leaks (not customer leaks) for Dark Web
  const { data: domainCustomerAssetsData } = useGetCustomerAssetsWhereQuery({
    variables: {
      customerAssetsWhere: {
        _and: [
          {
            platform_subtype: {
              product: { _eq: ProductType.DOMAIN },
            },
            status: { _eq: SafelistStatus.ACTIVE },
            value: { _nlike: '%/%' },
          },
        ],
      },
      orderBy: [{ value: Order_By.Asc }],
      org_id: orgId,
    },
    skip: !orgId || !isDarkWeb, // only run query when there is an org id present and report type is dark web
  })

  useEffect(() => {
    if (domainCustomerAssetsData?.customer_assets) {
      setDomainCustomerAssets(domainCustomerAssetsData.customer_assets)
    }
  }, [domainCustomerAssetsData])

  const spoofReportsWhere = spoofingReportsWhereClause(
    selectedReportFilters,
    isOrgUnifiedView ? null : orgId,
    [selectedReportStatus],
    selectedReportTypes,
    selectedAutocompleteReportId,
    selectedReportExposureType,
    undefined,
    domainCustomerAssets,
  )

  const queryOffset = (selectedPage - 1) * selectedPageSize

  const {
    data: searchSpoofingReportsData,
    loading: searchSpoofingReportsLoading,
    fetchMore: searchSpoofingReportsFetchMore,
    networkStatus: searchSpoofingReportsNetworkStatus,
    refetch: searchSpoofingReportsRefetch,
  } = useSearchSpoofingReportsQuery({
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: {
      offset: queryOffset,
      limit: selectedPageSize,
      refresh: toRefreshCache,
      spoofingReportsWhere: spoofReportsWhere,
      searchKey: cleanUrl(searchKey),
      skipNormalSearch: !selectedAutocompleteReportId && Boolean(searchKey),
      skipFullTextSearch: Boolean(selectedAutocompleteReportId) || !searchKey,
      orderBy: spoofingReportsOrderByClause(searchKey, selectedSortingMechanism),
      includeCreditCardData:
        selectedReportExposureType == DarkWebPlatforms.CREDIT_CARD_LEAKS ? true : false,
    },
    notifyOnNetworkStatusChange: true,
  })

  const { data: spoofingReportCount, refetch: spoofingReportCountRefetch } =
    useSearchSpoofingReportsCountQuery({
      variables: {
        spoofingReportsWhere: spoofReportsWhere,
        searchKey: cleanUrl(searchKey),
        skipNormalSearch: !selectedAutocompleteReportId && Boolean(searchKey),
        skipFullTextSearch: Boolean(selectedAutocompleteReportId) || !searchKey,
      },
      notifyOnNetworkStatusChange: true,
    })

  const spoofingReportWhere: Spoofing_Reports_Bool_Exp = {
    id: { _eq: overrideOpenReportId },
  }
  const [getReportById, { data: reportByIdData }] = useGetSpoofingReportsLazyQuery({
    variables: {
      spoofingReportsWhere: spoofingReportWhere,
      orderBy: [{ created_at: Order_By.Asc }],
    },
  })

  const spoofingReports =
    searchSpoofingReportsData?.search_spoofing_reports ||
    searchSpoofingReportsData?.spoofing_reports ||
    []

  const tableViewUrl = useMemo(() => {
    const baseUrl = generateReportUrl(selectedReportType, isOrgUnifiedView, null)
    return generateTableViewUrl(
      baseUrl,
      selectedReportFilters,
      selectedReportStatus,
      selectedSortingMechanism,
    )
  }, [
    selectedReportType,
    isOrgUnifiedView,
    selectedReportFilters,
    selectedReportStatus,
    selectedSortingMechanism,
  ])

  // preserve scroll position, using the exact URL as a key
  usePreserveScrollPosition(tableViewUrl)

  const updateAlertsTableResult = (previousPage) => {
    // preserve query result in jotai for detail view
    if (spoofingReports?.length) {
      setAlertsTableResult((prev) => ({
        ...prev,
        ids: spoofingReports.map((report) => {
          return (
            getExternalReportId(report.organization.abbr_name, report.external_id) ||
            report.id
          )
        }),
        internal_ids: spoofingReports.map((report) => report.id),
        offset: queryOffset,
        previousPage: previousPage,
      }))
    }
  }
  useEffect(() => {
    const tableViewUrlWithPage = `${tableViewUrl}&page=${selectedPage}`
    updateAlertsTableResult(tableViewUrlWithPage)
  }) // no dependencies; do every render

  const spoofingReportCounts =
    spoofingReportCount?.search_spoofing_reports_aggregate ||
    spoofingReportCount?.spoofing_reports_aggregate ||
    []

  useEffect(() => {
    if (orgId) {
      searchSpoofingReportsFetchMore({
        variables: { offset: (selectedPage - 1) * selectedPageSize },
      })
    }
  }, [orgId, selectedPage])

  useEffect(() => {
    setReportStatuses(availableStatuses(isEmployeeView, selectedReportType))
  }, [isEmployeeView, isDarkWeb]) // isDarkWeb instead of selectedReportType to reduce renders

  useEffect(() => {
    if (!selectedSpoofReport) setOverrideOpenReportId(null)
  }, [selectedSpoofReport])

  useEffect(() => {
    getReportById()
  }, [overrideOpenReportId])

  useEffect(() => {
    setSelectedSpoofReport(reportByIdData?.spoofing_reports[0] || null)
  }, [reportByIdData])

  const refreshTable = () => {
    setSelectedPage(1)
    setSelectedSpoofReport(null)
    setOverrideOpenReportId(null)
    searchSpoofingReportsRefetch()
    spoofingReportCountRefetch()
  }

  const createSpoofingReportsWhereClause = (isOrgUnifiedView = false) => {
    return (selectedStatuses) =>
      spoofingReportsWhereClause(
        selectedReportFilters,
        isOrgUnifiedView ? null : orgId,
        selectedStatuses,
        selectedReportTypes,
        selectedAutocompleteReportId,
        undefined,
        undefined,
        domainCustomerAssets,
      )
  }

  const autocompleteClickCallback = (alertId) => {
    router.push(getDoppelUrlFromId(alertId))
  }

  useEffect(() => {
    const baseUrl = generateReportUrl(selectedReportType, isOrgUnifiedView, null)
    const tableViewUrl = generateTableViewUrl(
      baseUrl,
      selectedReportFilters,
      selectedReportStatus,
      selectedSortingMechanism,
    )
    window.history.replaceState(window.history.state, '', tableViewUrl)
  }, [
    selectedSpoofReport,
    selectedReportType,
    selectedReportFilters,
    selectedReportStatus,
    selectedSortingMechanism,
    selectedPage,
  ])

  useEffect(() => {
    if (toRefreshCache) {
      setToRefreshCache(false)
    }
  }, [toRefreshCache])

  // Not a huge fan of this, we should probably just use permalink to avoid caching issues and hacks like this
  // if selectedSearchBarReportId is set, user has clicked on autocomplete suggestion
  const freshSelectedReport = overrideOpenReportId
    ? selectedSpoofReport
    : selectedSpoofReport
    ? spoofingReports?.find((report) => report.id === selectedSpoofReport?.id)
    : null

  useEffect(() => {
    setSelectedSpoofReport(freshSelectedReport)
  }, [freshSelectedReport])

  return {
    refreshTable,
    spoofReportsWhere,
    spoofingReportCounts,
    autocompleteClickCallback,
    createSpoofingReportsWhereClause,
    searchSpoofingReportsData,
    searchSpoofingReportsLoading,
    searchSpoofingReportsNetworkStatus,
    setToRefreshCache,
  }
}
