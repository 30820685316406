import { atom, PrimitiveAtom } from 'jotai'
import {
  Organization_InternalFragment,
  OrganizationFragment,
} from '@/generated/graphql'
import { OrganizationIdWithRole } from '@/types/organizations'

// triggers a refetch of organizations query
export const organizationIdAtom = atom<string | null>(null) as PrimitiveAtom<
  string | null
>
export const organizationAtom = atom<
  OrganizationFragment | Organization_InternalFragment | null
>(null) as PrimitiveAtom<OrganizationFragment | Organization_InternalFragment | null>
export const organizationsAndRolesAtom = atom<OrganizationIdWithRole[]>(
  [],
) as PrimitiveAtom<OrganizationIdWithRole[]>
