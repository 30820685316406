import {
  Box,
  Flex,
  HStack,
  Spacer,
  TabList,
  Tabs,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import { useRouter } from 'next/router'
import { useEffect, useMemo, useRef, useState } from 'react'
import OrgPicker from '../../components/internal/org_picker'
import {
  DOPPEL_DARK,
  DOPPEL_DARK_GREY,
  DOPPEL_WHITE,
  DOPPEL_DARK_CLICKABLE_HOVER,
} from '../../utils/style'
import InternalDropdown from '@/components/internal/internal_dropdown'
import FeatureFlagDisplay from '@/components/internal/feature_flag_display'
import { MainTab, TabWarningInfo } from '@/components/reports/main_tab'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import { useAtomValue } from 'jotai'
import { userProductsAtom } from '@/atoms/permissions'
import { isUserAuthorizedForPage } from '@/utils/permissions'
import NotFoundPage from '@/components/not_found_page'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Dropdown } from '@/components/doppel_design/dropdown'
import DropdownButton from '@/components/doppel_design/dropdown_button'
import CountBadge from '@/components/doppel_design/count_badge'

export type TabInfo = {
  name: string
  route: string
  isVisible: boolean
  subTabs?: TabInfo[]
  count?: number
  warningInfo?: TabWarningInfo
}

export type SubTabInfo = {
  name: string
  route: string
  isVisible: boolean
}

type PageLayoutProps = {
  component: JSX.Element
  tabs?: TabInfo[]
  dropdownTabs?: TabInfo[]
  onTabChange?: (tab: TabInfo, index: number) => void
  onSubTabChange?: (subTab: SubTabInfo, index: number) => void
  toolbar?: JSX.Element
}

const getTabIndexForRoute = (tabs, route) => {
  const directMatch = tabs.findIndex((tab) => tab.route === route)
  if (directMatch > -1) return directMatch // avoid cases where Employee Threatbox matches to Email because it's a prefix
  const initTab = tabs.findIndex((tab) => route.startsWith(tab.route))
  return initTab > 0 ? initTab : 0
}

const PageLayout = (props: PageLayoutProps) => {
  const {
    component,
    tabs = [],
    dropdownTabs = [],
    toolbar = <></>,
    onTabChange = () => {},
    onSubTabChange = () => {},
  } = props

  const router = useRouter()
  const allTabs = useMemo(() => [...tabs, ...dropdownTabs], [tabs, dropdownTabs])
  const [tabIndex, setTabIndex] = useState(() =>
    getTabIndexForRoute(allTabs, router.asPath),
  )
  const subTabs = useMemo(() => allTabs[tabIndex]?.subTabs || [], [allTabs, tabIndex])
  const [subTabIndex, setSubTabIndex] = useState(() =>
    subTabs ? getTabIndexForRoute(subTabs, router.asPath) : 0,
  )

  const userProducts = useAtomValue(userProductsAtom)
  const isRbacEnabled = useFeatureFlagEnabled('rbac')
  const filterImprovementsEnabled = useFeatureFlagEnabled('filter-improvements')
  const totalHiddenCount = dropdownTabs.reduce((sum, tab) => sum + (tab.count || 0), 0)

  const tabManuallyChanged = useRef(false)
  const subtabManuallyChanged = useRef(false)

  useEffect(() => {
    const handleRouteChange = () => {
      tabManuallyChanged.current = false // Reset after route change
      subtabManuallyChanged.current = false
    }

    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    if (tabManuallyChanged.current) return
    const newIndex = getTabIndexForRoute(allTabs, router.asPath)
    setTabIndex(newIndex)
    tabManuallyChanged.current = false
  }, [allTabs, router.asPath]) // Only runs when the route changes

  useEffect(() => {
    if (subtabManuallyChanged.current) return
    if (subTabs) {
      setSubTabIndex(getTabIndexForRoute(subTabs, router.asPath))
    }
    subtabManuallyChanged.current = false
  }, [subTabs, router.asPath]) // Only runs when route changes

  const bgColor = useColorModeValue(DOPPEL_WHITE, DOPPEL_DARK)

  const dropdownLabel = useMemo(() => {
    const selectedDropdownTab = dropdownTabs.find(
      (tab) => tab.name === allTabs[tabIndex]?.name,
    )
    // If there's only one dropdown option, use its name
    // Otherwise, if a dropdown tab is selected, show its name, else show "Other"
    if (dropdownTabs.length === 1) {
      return dropdownTabs[0].name
    } else {
      return selectedDropdownTab ? selectedDropdownTab.name : 'Other'
    }
  }, [tabIndex, dropdownTabs, allTabs])

  if (!isUserAuthorizedForPage(router.pathname, userProducts, isRbacEnabled)) {
    return <NotFoundPage />
  }

  // If there's only one dropdown option, render it as a regular tab
  const visibleTabs = dropdownTabs.length === 1 ? [...tabs, dropdownTabs[0]] : tabs

  // Only use dropdown if there's more than one option
  const dropdownTabsToShow = dropdownTabs.length === 1 ? [] : dropdownTabs

  return (
    <VStack bgColor={bgColor} width="100%">
      <VStack marginBottom={2} width="100%">
        <Flex alignItems={filterImprovementsEnabled ? 'center' : ''} width="100%">
          {/* Left side: Tabs */}
          <Tabs
            index={tabIndex}
            isLazy={true}
            marginLeft={1}
            onChange={(index) => {
              tabManuallyChanged.current = true
              setTabIndex(index)
              onTabChange(visibleTabs[index], index)
            }}
            variant="solid-rounded"
            w={filterImprovementsEnabled ? 'auto' : '100%'}
          >
            <TabList borderRadius="4px">
              {visibleTabs.map((tab) => (
                <MainTab
                  count={tab.count}
                  key={tab.name}
                  name={tab.name}
                  warningInfo={tab.warningInfo}
                />
              ))}
            </TabList>
          </Tabs>

          {/* Dropdown with a custom menu button for "More" */}
          {filterImprovementsEnabled && dropdownTabsToShow.length > 0 && (
            <Box>
              <Dropdown
                customMenuButton={
                  <DropdownButton
                    _hover={{ bgColor: DOPPEL_DARK_CLICKABLE_HOVER }}
                    bgColor={DOPPEL_DARK_GREY}
                    height="40px"
                    label={dropdownLabel}
                    rightIcon={
                      <Flex alignItems="center">
                        {dropdownTabsToShow.some((tab) => tab.name === dropdownLabel)
                          ? allTabs[tabIndex]?.count > 0 && (
                              <CountBadge count={allTabs[tabIndex]?.count} />
                            )
                          : totalHiddenCount > 0 && (
                              <CountBadge count={totalHiddenCount} />
                            )}

                        <ChevronDownIcon ml={1} />
                      </Flex>
                    }
                    useMenuButton
                  />
                }
                items={dropdownTabsToShow.map((tab) => ({
                  label: (
                    <Flex alignItems="center">
                      {tab.name}

                      {tab.count > 0 && <CountBadge count={tab.count} />}
                    </Flex>
                  ),
                  value: tab.name,
                }))}
                onSelect={(value) => {
                  const selectedIndex = allTabs.findIndex((tab) => tab.name === value)
                  if (selectedIndex !== -1) {
                    tabManuallyChanged.current = true
                    setTabIndex(selectedIndex)
                    onTabChange(allTabs[selectedIndex], selectedIndex)
                  }
                }}
              />
            </Box>
          )}

          {/* Right side components */}
          <Spacer />

          <HStack>
            {toolbar}

            <OrgPicker />

            <InternalDropdown />

            <FeatureFlagDisplay />
          </HStack>
        </Flex>

        {subTabs?.length && (
          <Flex justifyContent="center" width="100%">
            <Tabs
              index={subTabIndex}
              isLazy={true}
              marginLeft={1}
              onChange={(index) => {
                setSubTabIndex(index)
                onSubTabChange(subTabs[index], index)
              }}
              variant="solid-rounded"
              w="100%"
            >
              <TabList>
                {subTabs.map((tab) => (
                  <MainTab key={tab.name} name={tab.name} />
                ))}
              </TabList>
            </Tabs>
          </Flex>
        )}
      </VStack>

      {component}
    </VStack>
  )
}

export default PageLayout
