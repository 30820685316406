import { RepeatIcon } from '@chakra-ui/icons'
import DoppelDefaultButton from '../shared/doppel_default_button'
import DoppelIconButton from '../shared/doppel_icon_button'
import { useFeatureFlagEnabled } from 'posthog-js/react'

const RefreshButton = ({ refreshFunc }) => {
  const filterImprovementsEnabled = useFeatureFlagEnabled('filter-improvements')
  return filterImprovementsEnabled ? (
    <DoppelIconButton
      h={'40px'}
      icon={<RepeatIcon />}
      ml={1}
      onClick={() => {
        refreshFunc()
      }}
      w={'40px'}
    />
  ) : (
    <DoppelDefaultButton
      leftIcon={<RepeatIcon />}
      marginLeft={5}
      onClick={() => {
        refreshFunc()
      }}
    >
      Refresh
    </DoppelDefaultButton>
  )
}

export default RefreshButton
