import { Box, Text, BoxProps } from '@chakra-ui/react'
import { useAtom, useAtomValue } from 'jotai'
import {
  organizationAtom,
  organizationsAndRolesAtom,
  organizationIdAtom,
} from '@/atoms/organizations'
import { Dropdown, DropdownGroup } from '@/components/doppel_design/dropdown'
import AvatarButton from '@/components/doppel_design/avatar_button'
import { useAuth0 } from '@auth0/auth0-react'
import { DOPPEL_TEXT_GREY, DOPPEL_TEXT_RED } from '@/utils/style'
import { isFederated, shouldForceRedirectLogout } from '@/components/logout_button'
import React from 'react'
import { useRouter } from 'next/router'
import { useUpdateLastOrgContextMutation } from '@/generated/graphql'
import { useUserID } from '@/hooks/id_token_claims'
import { useToast } from '@chakra-ui/react'

enum USER_ACTIONS {
  ORGANIZATION_SETTINGS = 'organization_settings',
  LOGOUT = 'logout',
}

type UserActionsButtonProps = BoxProps & {
  /** Additional props to pass to the underlying button element */
  [key: string]: any
}

const UserActionsButton: React.FC<UserActionsButtonProps> = ({ ...props }) => {
  const toast = useToast()
  const organization = useAtomValue(organizationAtom)
  const organizationsAndRoles = useAtomValue(organizationsAndRolesAtom)
  const { logout } = useAuth0()
  const router = useRouter()
  const [updateLastOrgContext] = useUpdateLastOrgContextMutation()
  const [userId] = useUserID()
  const [orgId, setOrgId] = useAtom(organizationIdAtom)

  // TODO (DOP-7256): implement switch organization and settings
  const handleSelect = (value: string) => {
    // Handle the selection of menu items
    switch (value) {
      case USER_ACTIONS.ORGANIZATION_SETTINGS: {
        router.push('/users')
        break
      }
      case USER_ACTIONS.LOGOUT: {
        // Handle logout
        localStorage.clear()
        sessionStorage.clear()
        let returnUrl = window.location.origin
        if (shouldForceRedirectLogout(organization)) {
          returnUrl += '/custom-logout'
        }
        logout({
          federated: isFederated(organization),
          returnTo: returnUrl,
        })
        break
      }
      default: {
        // Check if value is an organization_id
        const matchingOrg = organizationsAndRoles.find(
          (org) => org.organization_id === value,
        )

        if (matchingOrg) {
          // Update the last used organization in the database
          updateLastOrgContext({
            variables: {
              last_org_context: matchingOrg.organization_id,
              id: userId,
            },
          })

          // Set organization ID in atom
          setOrgId(matchingOrg.organization_id)

          toast({
            title: `Switching to ${matchingOrg.name}.`,
            status: 'info',
            duration: 2000,
            isClosable: false,
          })
        }
        break
      }
    }
  }

  // Create dropdown items
  const actionItems: DropdownGroup[] = [
    {
      items: [
        {
          label: <Text>Organization settings</Text>,
          value: USER_ACTIONS.ORGANIZATION_SETTINGS,
        },
        {
          label: <Text color={DOPPEL_TEXT_RED}>Log out</Text>,
          value: USER_ACTIONS.LOGOUT,
        },
      ],
    },
  ]

  const organizationSwitchItems: DropdownGroup[] = [
    {
      title: 'Switch organizations',
      titleProps: {
        ml: 3,
        color: DOPPEL_TEXT_GREY,
        fontSize: 14,
        fontWeight: 500,
      },
      items: organizationsAndRoles.map((organization) => ({
        label: <Text>{organization.name}</Text>,
        value: organization.organization_id,
      })),
    },
  ]

  const customMenuButton = (
    <AvatarButton initials={organization?.abbr_name} isMenuButton />
  )

  return (
    <Box display="flex" justifyContent="center" width="100%" {...props}>
      <Dropdown
        customMenuButton={customMenuButton}
        groupedItems={
          organizationsAndRoles.length > 1
            ? [...organizationSwitchItems, ...actionItems]
            : actionItems
        }
        onSelect={handleSelect}
        selectedValue={orgId}
      />
    </Box>
  )
}

export default UserActionsButton
