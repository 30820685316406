import { Tab, Box, Tooltip } from '@chakra-ui/react'
import { DOPPEL_DARK_CLICKABLE, FONT_SIZE_MEDIUM } from '@/utils/style'
import CountBadge from '@/components/doppel_design/count_badge'

export type TabWarningInfo = {
  icon?: React.ReactNode
  tooltipText?: string
}

type MainTabProps = {
  name: string
  count?: number
  height?: number
  warningInfo?: TabWarningInfo
}

export const MainTab = ({ name, count, height = 40, warningInfo }: MainTabProps) => {
  return (
    <Tab
      _hover={{ bg: DOPPEL_DARK_CLICKABLE }}
      _selected={{ bg: DOPPEL_DARK_CLICKABLE }}
      borderRadius={8}
      cursor="pointer"
      fontSize={FONT_SIZE_MEDIUM}
      height={`${height}px`}
      marginRight="16px"
      overflow="visible"
      padding="7px 8px"
      position="relative"
      whiteSpace="nowrap"
    >
      {name}

      {count > 0 && <CountBadge count={count} />}

      {warningInfo?.icon && (
        <Tooltip label={warningInfo.tooltipText}>
          <Box padding="0 4px">{warningInfo.icon}</Box>
        </Tooltip>
      )}
    </Tab>
  )
}
