import { GCS_PREFIX } from './web2'

export const DOPPEL_VISION_ASSETS_BUCKETS = 'doppel_vision_assets'

export const extractGCSDetails = (
  url: string,
): { bucketName: string; objectName: string } | null => {
  const match = url.match(/https:\/\/storage\.googleapis\.com\/([^\\/]+)\/(.+)/)

  if (!match || match.length < 3) {
    return null
  }

  const bucketName = match[1]
  const objectName = match[2]

  return { bucketName, objectName }
}

export const getBucketNameAndObjectName = (
  url: string,
): { bucketName: string; objectName: string } => {
  const urlWithoutPrefix = url.substring(GCS_PREFIX.length)
  const firstSlashIndex: number = urlWithoutPrefix.indexOf('/')
  const signedUrlIndex: number = urlWithoutPrefix.indexOf('?X-Goog-Algorithm')
  const bucketName = urlWithoutPrefix.substring(0, firstSlashIndex)
  let objectName
  // Adding this check to handle when the url is already signed
  if (signedUrlIndex !== -1) {
    objectName = urlWithoutPrefix.substring(firstSlashIndex + 1, signedUrlIndex)
  } else {
    objectName = urlWithoutPrefix.substring(firstSlashIndex + 1)
  }
  objectName = decodeURIComponent(objectName) // workaround for old flagged_url object names

  return { bucketName, objectName }
}

export const constructGcsUrl = (bucketName: string, objectName: string): string =>
  `${GCS_PREFIX}${bucketName}/${objectName}`

/**
 * Check if a given signed URL is still valid (not expired).
 *
 * @param signedUrl The signed URL to check.
 * @returns True if the URL is valid, false otherwise.
 */
export const isSignedUrlValid = (signedUrl: string) => {
  if (!signedUrl) return false
  const url = new URL(signedUrl)
  const expiryParam = url.searchParams.get('X-Goog-Expires')
  const timestampParam = url.searchParams.get('X-Goog-Date')

  if (!expiryParam || !timestampParam) return false

  const expiryTime = parseInt(expiryParam, 10)
  const timestamp = Date.parse(
    timestampParam.replace(
      /(\d{4})(\d{2})(\d{2})T(\d{2})(\d{2})(\d{2})Z/,
      '$1-$2-$3T$4:$5:$6Z',
    ),
  )
  const now = Date.now()

  return now < timestamp + expiryTime * 1000
}
