import {
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  Text,
  Textarea,
  useToast,
  Checkbox,
} from '@chakra-ui/react'
import React, { useState, useRef, useEffect } from 'react'
import {
  Order_By,
  useGetSpoofingReportsLazyQuery,
  useUpdateSpoofingReportsMutation,
  useGetUsersQuery,
  useGetAssignedAlertCountsQuery,
} from '../../generated/graphql'
import DropdownMenu from '../shared/forms/dropdown_menu'
import { IoMdPerson } from 'react-icons/io'
import { getReportsWhereClauseFromExternalIds, parseExternalId } from '@/hooks/queries'
import { ReportChangeEventSource } from '@/generated/enums'
import DoppelModal from '@/components/doppel_design/doppel_modal'
import { useUserID } from '@/hooks/id_token_claims'
import {
  getSortedAlertAssigneesWithUnassigned,
  handleAlertAssignmentError,
} from '@/utils/enforcement_utils'
import { ALERT_ASSIGNMENT_LIMIT } from '@/utils/constants'
import { DOPPEL_BREACH_RED } from '@/utils/style'

export default function BulkAssignAlertsModal({
  isOpen,
  onClose,
  refreshFunc,
  selectedRows = null,
}) {
  const [notes, setNotes] = useState('')
  const [selectedAssignee, setSelectedAssignee] = useState(null)
  const [alertIds, setAlertIds] = useState('')
  const [selfAssign, setSelfAssign] = useState(false)
  const [userId] = useUserID()
  const toast = useToast()
  const firstField = useRef()

  const UNASSIGNED_EMAIL = 'Unassigned'

  const [updateSpoofingReportsMutation] = useUpdateSpoofingReportsMutation({
    onCompleted: () => {
      refreshFunc()
      onClose()
      toast({
        title:
          selectedAssignee?.email !== UNASSIGNED_EMAIL
            ? 'Successfully assigned alerts to ' + selectedAssignee?.email
            : 'Successfully unassigned Alerts',
        status: 'success',
        isClosable: true,
      })
    },
    onError: (error) => {
      handleAlertAssignmentError(error, toast)
    },
  })

  const { data: assigneeData } = useGetUsersQuery({
    variables: {
      usersWhere: { is_internal: { _eq: true } },
    },
  })
  const alertAssignees = assigneeData?.users || []
  const assigneeIds = alertAssignees.map((assignee) => assignee.id)

  const { data: alertCountData } = useGetAssignedAlertCountsQuery({
    variables: {
      assigneeIds,
    },
  })

  const alertCountsByAssignee: Record<string, number> = {}

  const assignedAlerts = alertCountData?.assignee_counts?.nodes || []
  assignedAlerts.forEach((alert) => {
    if (alert?.internal_alert_assignee?.id) {
      alertCountsByAssignee[alert.internal_alert_assignee.id] =
        (alertCountsByAssignee[alert.internal_alert_assignee.id] || 0) + 1
    }
  })

  const sortedAssignees = getSortedAlertAssigneesWithUnassigned(alertAssignees).map(
    (assignee) => ({
      ...assignee,
      alertCount: alertCountsByAssignee[assignee.id] || 0,
    }),
  )

  const alertCount = selectedRows
    ? selectedRows.length
    : alertIds.split('\n').filter((id) => id.trim()).length
  const selectedAssigneeCount = selectedAssignee?.id
    ? alertCountsByAssignee[selectedAssignee.id] || 0
    : 0
  const totalAlertCount = selectedAssigneeCount + alertCount
  const willExceedLimit =
    selectedAssignee &&
    selectedAssignee.email !== UNASSIGNED_EMAIL &&
    totalAlertCount > ALERT_ASSIGNMENT_LIMIT

  const [getAlertByIds] = useGetSpoofingReportsLazyQuery()

  // Effect to handle self-assign checkbox changes
  useEffect(() => {
    if (selfAssign && assigneeData?.users) {
      // Case 1: Checkbox checked -> select my email
      const currentUser = assigneeData.users.find((user) => user.id === userId)
      if (currentUser) {
        setSelectedAssignee(currentUser)
      }
    } else if (!selfAssign && (!selectedAssignee || selectedAssignee?.id === userId)) {
      // Case 4: Checkbox unchecked -> clear my email if it was selected
      setSelectedAssignee(null)
    }
  }, [selfAssign, assigneeData, userId])

  // Effect to handle assignee selection changes
  useEffect(() => {
    // Case 2 & 3: Update checkbox based on whether selected email is mine
    setSelfAssign(
      selectedAssignee &&
        selectedAssignee.id === userId &&
        selectedAssignee.email !== UNASSIGNED_EMAIL,
    )
  }, [selectedAssignee, userId])

  const updateAlertFromExternalIds = async () => {
    try {
      const alertExternalIds = alertIds.split('\n').filter((url) => url.trim() !== '')
      const firstExternalId = parseExternalId(alertExternalIds[0])
      if (
        !alertExternalIds.every((externalId) =>
          externalId.startsWith(firstExternalId.orgAbbr),
        )
      ) {
        throw new Error('All alerts need to belong to the same organization')
      }

      const whereClause = getReportsWhereClauseFromExternalIds(alertExternalIds)

      if (
        !whereClause ||
        !whereClause?.external_id ||
        !whereClause?.organization?.abbr_name
      ) {
        throw new Error(
          "Invalid alert ID provided, please make sure it's in the format of TET-1234.",
        )
      }

      const { data } = await getAlertByIds({
        variables: {
          spoofingReportsWhere: whereClause,
          orderBy: [{ created_at: Order_By.Asc }],
        },
      })

      if (data && data.spoofing_reports.length > 0 && selectedAssignee) {
        const alertIds = data.spoofing_reports.map((report) => report.id)
        await updateSpoofingReportsMutation({
          variables: {
            input: {
              report_ids: alertIds,
              update_source: ReportChangeEventSource.UI,
              internal_assignee_id: selectedAssignee.id,
              notes: notes || null,
              is_internal: true,
            },
          },
        })
      } else {
        toast({
          title: 'Error',
          description: 'No matching alerts found for the provided external IDs',
          status: 'error',
          isClosable: true,
        })
        return
      }
    } catch (error) {
      handleAlertAssignmentError(error, toast)
      return
    }
  }

  const updateAlert = async () => {
    if (!selectedRows) {
      updateAlertFromExternalIds()
    } else {
      const selectedAlertIds = selectedRows.map((row) => row.original.id)
      await updateSpoofingReportsMutation({
        variables: {
          input: {
            report_ids: selectedAlertIds,
            update_source: ReportChangeEventSource.UI,
            internal_assignee_id: selectedAssignee?.id,
            notes: notes || null,
            is_internal: true,
          },
        },
      })
    }
  }

  const reset = () => {
    setNotes('')
    setSelectedAssignee(null)
    setAlertIds('')
  }

  const renderModalBody = () => {
    return (
      <Stack spacing="24px">
        {!selectedRows && (
          <FormControl isRequired>
            <FormLabel>{'Alert IDs (all from 1 organization)'} </FormLabel>

            <Textarea
              minHeight="200px"
              onChange={(e) => setAlertIds(e.target.value)}
              placeholder={'TET-1234\nTET-4567\nTET-8901'}
              ref={firstField}
              value={alertIds}
            />

            <FormHelperText>
              New line separated list of Alert IDs to assign (Max
              {ALERT_ASSIGNMENT_LIMIT})
            </FormHelperText>
          </FormControl>
        )}

        <FormControl>
          <FormLabel>Assignee</FormLabel>

          <DropdownMenu
            buttonDisplayFunction={(selectedItems) => selectedItems[0] || 'Assignee'}
            icon={<IoMdPerson size="18" />}
            isMultiSelect={false}
            itemDisplayFunction={(email) => {
              const assignee = sortedAssignees.find((a) => a.email === email)
              return email === UNASSIGNED_EMAIL
                ? email
                : `${email} (${assignee?.alertCount || 0})`
            }}
            items={
              sortedAssignees ? sortedAssignees.map((assignee) => assignee.email) : []
            }
            placeholder={'Search Assignees...'}
            selectedItems={[selectedAssignee?.email]}
            setSelectedItems={([assigneeEmail]) => {
              const selectedAssignee = sortedAssignees?.find(
                (b) => b.email === assigneeEmail,
              )
              setSelectedAssignee(selectedAssignee)
            }}
            showSearchBar={true}
          />

          <FormHelperText>Assignee to assign alerts to</FormHelperText>

          {willExceedLimit && (
            <Text color={DOPPEL_BREACH_RED} fontWeight="bold" mt={1}>
              Warning: You cannot assign more than {ALERT_ASSIGNMENT_LIMIT} alert(s) to
              a single analyst
            </Text>
          )}
        </FormControl>

        <FormControl>
          <Checkbox
            isChecked={selfAssign}
            onChange={(e) => setSelfAssign(e.target.checked)}
          >
            Self-assign alert(s)
          </Checkbox>
        </FormControl>

        <FormControl>
          <FormLabel>Notes</FormLabel>

          <Textarea
            minHeight="100px"
            onChange={(e) => setNotes(e.target.value)}
            placeholder={'Add notes here...'}
            value={notes}
          />

          <FormHelperText>
            Notes for assignee. Notes will be left as a comment on the alert
          </FormHelperText>
        </FormControl>
      </Stack>
    )
  }

  return (
    <DoppelModal
      body={renderModalBody()}
      isAsync={true}
      isDisabled={willExceedLimit}
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
      primaryAction={updateAlert}
      primaryLabel={
        selectedAssignee?.email === UNASSIGNED_EMAIL ? 'Unassign' : 'Assign'
      }
      size="lg"
      title={
        selectedRows
          ? `Bulk Assign Alerts - ${selectedRows?.length} alert(s) selected`
          : 'Bulk Assign Alerts'
      }
    ></DoppelModal>
  )
}
