import {
  ReportFilter,
  removeFilterByType,
  upsertFilter,
  genReportFilter,
} from '@/hooks/report_table_filters'
import { useSelectedReportFilters } from '@/pages/reports'
import { ReportFilterType } from '@/utils/constants'
import DateFilterDropdown, {
  DateFilterLabels,
} from '@/components/doppel_design/date_filter_dropdown/date_filter_dropdown'
import { DateOnlyRange } from '../stats/date_range'
import moment from 'moment'
import { IoMdCalendar } from 'react-icons/io'
import { useIsEmployeeView } from '@/hooks/id_token_claims'
import { DeletableButton } from '../doppel_design/deletable_button'
import { Box } from '@chakra-ui/react'

const DateFilterWrapper = ({
  onSelect,
  filterType,
  alwaysEnableClearSelection = false,
  onClear = () => {},
  w,
  ...props
}) => {
  // while we haven't fully migrated, this is a wrapper to call the new date filter
  // with the appropriate filter setting handlers
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const [isEmployeeView] = useIsEmployeeView()

  const staticRanges = [
    DateFilterLabels.TODAY,
    DateFilterLabels.SINCE_YESTERDAY,
    DateFilterLabels.THIS_WEEK,
    DateFilterLabels.MONTH_TO_DATE,
    DateFilterLabels.YEAR_TO_DATE,
    DateFilterLabels.LAST_7_DAYS,
    DateFilterLabels.LAST_30_DAYS,
    DateFilterLabels.LAST_90_DAYS,
    DateFilterLabels.LAST_6_MONTHS,
  ]

  if (isEmployeeView && filterType === ReportFilterType.LastActivityDate) {
    staticRanges.push(
      DateFilterLabels.OLDER_THAN_24_HOURS,
      DateFilterLabels.OLDER_THAN_48_HOURS,
      DateFilterLabels.OLDER_THAN_72_HOURS,
    )
  }

  const dateFilter = selectedReportFilters.find(
    (filter) => filter.filterType === filterType,
  )
  const isFilterSelected = !!dateFilter
  const filterToRange = (filter: ReportFilter, key?: string): DateOnlyRange => {
    if (!filter) {
      return new DateOnlyRange(new Date(), new Date(), key)
    }
    const [startDate, endDate] = filter.value
      .split('#')
      .map((dateString) => moment(dateString).toDate())
    return new DateOnlyRange(startDate, endDate, key)
  }
  const currentDateRange = filterToRange(dateFilter)

  const makeDateFilterValue = (selectedRange: DateOnlyRange): string => {
    const { startDate, endDate } = selectedRange
    const start = moment(startDate).format('YYYY-MM-DD')
    const end = moment(endDate).format('YYYY-MM-DD')
    return `${start}#${end}`
  }

  const customButton = (
    <Box mr={2}>
      <DeletableButton
        icon={<IoMdCalendar size="18" />}
        label={!isFilterSelected ? filterType : currentDateRange.toLocalShortDisplay()}
        onDelete={() => {
          onClear && onClear()
          setSelectedReportFilters(
            removeFilterByType(filterType, selectedReportFilters),
          )
        }}
        textOverflow="ellipsis"
      />
    </Box>
  )

  return (
    <DateFilterDropdown
      allowClearSelection
      currentDateRange={currentDateRange}
      customMenuButton={customButton}
      onSelect={(range) => {
        if (!range) {
          // clear selection
          setSelectedReportFilters(
            removeFilterByType(filterType, selectedReportFilters),
          )
          if (onSelect) onSelect([])
        } else {
          const dateFilter = makeDateFilterValue(range)
          setSelectedReportFilters(
            upsertFilter(
              genReportFilter(filterType as ReportFilterType, dateFilter),
              selectedReportFilters,
            ),
          )
          if (onSelect) onSelect([dateFilter])
        }
      }}
      staticRangesToShow={staticRanges}
    />
  )
}

export default DateFilterWrapper
