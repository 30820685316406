import {
  Box,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Tag,
  Text,
} from '@chakra-ui/react'
import debounce from 'lodash.debounce'
import { useEffect, useMemo, useRef, useState } from 'react'
import { IoSearch } from 'react-icons/io5'
import {
  Order_By,
  useGetSpoofingReportsLazyQuery,
  useSpoofingReportsAutocompleteLazyQuery,
} from '../../generated/graphql'
import { useOrgID } from '../../hooks/id_token_claims'
import { genReportFilter, upsertFilter } from '../../hooks/report_table_filters'
import {
  useSearchKey,
  useSelectedAutocompleteReportId,
  useSelectedReportFilters,
  useSelectedReportType,
} from '../../pages/reports'
import {
  KeyCode,
  ReportFilterType,
  ReportType,
  getStatusLabel,
} from '../../utils/constants'
import {
  METAVERSE_TYPES,
  DARK_WEB_TYPES,
  PLATFORM_TYPE_DISPLAY_MAP,
} from '../../utils/reports/report_utils'
import { getSpoofMatchUrl } from '../web2/spoof_match'
import { cleanUrl } from '../../utils/domain_utils'
import { DOPPEL_DARK_SECONDARY, DOPPEL_SECURE } from '../../utils/style'
import DoppelLink from '../report_detail/doppel_link'
import { maybeGetExternalIdWhereClauseFromSearch } from '@/hooks/queries'
import { ReportStatus } from '@/generated/enums'
import { useElasticsearch } from '../../hooks/use_elasticsearch_search'
import { useIsEmployeeView } from '../../hooks/id_token_claims'
import { usePostHog } from 'posthog-js/react'

const ES_DOCUMENT_LIMIT = 10000
const SEARCH_BAR_WIDTH = 300
const ReportsSearchBar = ({ isOrgUnifiedView, autocompleteClickCallback }) => {
  const dropdownRef = useRef(null)
  const orgId = useOrgID()
  const [isEmployeeView] = useIsEmployeeView()
  const [showAutocomplete, setShowAutocomplete] = useState(false)
  const postHog = usePostHog()
  const elasticSearchEnabled = postHog?.isFeatureEnabled('elastic-search-enabled')

  const { search, loading: elasticSearchLoading } = useElasticsearch({
    organizationId: orgId,
  })

  const [localSearchKey, setLocalSearchKey] = useState('')
  const [searchKey, setSearchKey] = useSearchKey()
  const [, setSelectedAutocompleteReportId] = useSelectedAutocompleteReportId()
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()
  const [searchSpoofingReports, { data: spoofingReportsData }] =
    useSpoofingReportsAutocompleteLazyQuery({
      notifyOnNetworkStatusChange: true,
    })
  const whereClauseFromExternalId = maybeGetExternalIdWhereClauseFromSearch(
    localSearchKey.trim(),
    isOrgUnifiedView ? null : orgId,
  )
  const noResultsClause = { id: { _eq: '00000000-0000-0000-0000-000000000000' } }
  const [getExternalIdReport, { data: externalIdData, loading: externalIdLoading }] =
    useGetSpoofingReportsLazyQuery({
      variables: {
        spoofingReportsWhere: whereClauseFromExternalId ?? noResultsClause,
        orderBy: [{ created_at: Order_By.Asc }],
      },
    })

  const [getSpoofingReports, { data: elasticSearchReportsData }] =
    useGetSpoofingReportsLazyQuery()

  const externalIdReport = externalIdData?.spoofing_reports[0] || null
  // only stores alert ids
  const [cachedElasticsearchResults, setCachedElasticsearchResults] = useState({
    query: '',
    alertIds: [],
  })

  // we stored detailed info about the hits here.
  const [esHits, setEsHits] = useState(null)

  const handleSearch = useMemo(
    () =>
      debounce(async (searchKey: string) => {
        if (!searchKey) {
          setShowAutocomplete(false)
          return null
        }
        setShowAutocomplete(true)

        // Only use elastic search for autocomplete
        if (elasticSearchEnabled) {
          try {
            const elasticResults = await search(searchKey, ES_DOCUMENT_LIMIT)

            // If still loading after getting results, a newer query is in progress
            if (elasticSearchLoading) return

            const alertIds = elasticResults?.data?.alert_ids || []
            // Store ES hits data
            setEsHits(elasticResults?.data?.hits || null)
            // Cache the results for future use
            setCachedElasticsearchResults({
              query: searchKey,
              alertIds,
            })

            if (alertIds.length > 0) {
              const topFiveAlertIds = alertIds.slice(0, 5)
              await getSpoofingReports({
                variables: {
                  spoofingReportsWhere: {
                    id: { _in: topFiveAlertIds },
                    organization_id: { _eq: orgId },
                  },
                  orderBy: [{ created_at: Order_By.Desc }],
                },
              })

              if (alertIds.length >= 5) {
                return alertIds.length
              }
            }
          } catch (error) {
            // Silently handle Elasticsearch errors in autocomplete
          }
        }

        // Then try external ID and normal search
        try {
          await getExternalIdReport()
          const whereClauses: any = [
            {
              organization_id: { _eq: orgId },
              report_status: { _neq: ReportStatus.INTERNAL_ARCHIVED },
            },
          ]
          if (selectedReportType == ReportType.METAVERSE) {
            whereClauses.push({ type: { _in: METAVERSE_TYPES } })
          } else if (selectedReportType == ReportType.DARK_WEB) {
            whereClauses.push({ type: { _in: DARK_WEB_TYPES } })
          } else {
            whereClauses.push({
              platform: {
                product: { _eq: PLATFORM_TYPE_DISPLAY_MAP[selectedReportType] },
              },
            })
          }
          await searchSpoofingReports({
            variables: {
              searchKey: cleanUrl(searchKey),
              limit: 5,
              spoofingReportsWhere: {
                _and: whereClauses,
              },
            },
          })
        } catch (error) {
          // Silently handle normal search errors in autocomplete
        }
        return 0
      }, 400),
    [
      orgId,
      selectedReportType,
      elasticSearchEnabled,
      search,
      getSpoofingReports,
      getExternalIdReport,
      searchSpoofingReports,
      elasticSearchLoading,
    ],
  )

  // Clean up debounce on unmount
  useEffect(() => {
    return () => {
      handleSearch.cancel()
    }
  }, [handleSearch])

  const triggerSearch = async () => {
    if (externalIdLoading) {
      return
    }
    if (externalIdReport) {
      autocompleteClickCallback(externalIdReport.id)
      return
    }

    // Just trigger the search key which will use normal search
    const searchQuery = localSearchKey.trim()
    // Clear Elasticsearch filters if search is empty
    if (!searchQuery) {
      setSelectedReportFilters((prevFilters) => {
        return prevFilters.filter(
          (filter) => filter.filterType !== ReportFilterType.ElasticSearchIds,
        )
      })
      setSearchKey('')
      return
    }

    setSearchKey(searchQuery)
    // Use Elasticsearch for main search if enabled

    if (elasticSearchEnabled && searchQuery) {
      try {
        let alertIds = []
        // Step 1: Get alert IDs from Elasticsearch
        if (cachedElasticsearchResults.query === searchQuery) {
          alertIds = cachedElasticsearchResults.alertIds
        } else {
          const { data } = await search(searchQuery, ES_DOCUMENT_LIMIT)
          alertIds = data?.alerts?.map((alert) => alert.id) || []
          setCachedElasticsearchResults({
            query: searchQuery,
            alertIds,
          })
        }

        if (alertIds.length > 0) {
          // Step 2: Use the alert IDs to filter in PostgreSQL
          // This allows all the other filters and sorting to be applied in PostgreSQL
          setSelectedAutocompleteReportId(null)

          // Add a special filter with the alert IDs
          setSelectedReportFilters((prevFilters) => {
            // Remove any existing ElasticSearchIds filters
            const filtersWithoutElasticSearchIds = prevFilters.filter(
              (filter) => filter.filterType !== ReportFilterType.ElasticSearchIds,
            )

            // Add the new ElasticSearchIds filter
            return [
              ...filtersWithoutElasticSearchIds,
              genReportFilter(
                ReportFilterType.ElasticSearchIds,
                JSON.stringify(alertIds),
                'elasticsearch_ids',
                true,
              ),
            ]
          })

          return
        }
      } catch (error) {
        // Silently handle Elasticsearch errors
      }
    }

    // Fall back to normal search if Elasticsearch is disabled or returned no results
    triggerSearchKey(searchQuery)
  }

  const triggerSearchKey = (key: string, reportId: string = null) => {
    setSearchKey(key.toLowerCase())
    setSelectedAutocompleteReportId(reportId)
    setSelectedReportFilters(
      upsertFilter(
        genReportFilter(ReportFilterType.Search, key.toLowerCase()),
        selectedReportFilters,
      ),
    )
  }

  const handleClickOutside = (event) => {
    const inputGroup = event.target.closest('.input-group')
    if (
      inputGroup ||
      (dropdownRef.current && dropdownRef.current.contains(event.target))
    ) {
      return
    }
    setShowAutocomplete(false)
  }

  const handleKeyDown = (event) => {
    if (event.keyCode === KeyCode.ArrowLeft || event.keyCode === KeyCode.ArrowRight) {
      event.stopPropagation()
    }
    if (event.keyCode === KeyCode.Enter) {
      triggerSearch()
    }
    if (event.keyCode === KeyCode.Escape) {
      setShowAutocomplete(false)
    }
  }

  // Add click outside listener on mount
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, false)
    return () => {
      document.removeEventListener('click', handleClickOutside, false)
    }
  }, [])

  const autocompleteReportsList = [
    ...(elasticSearchReportsData?.spoofing_reports || []).map((report) => ({
      ...report,
      source: 'elasticsearch',
      matched_fields:
        esHits?.find((hit) => hit.alert_id === report.id)?.matched_fields || null,
    })),
    ...(spoofingReportsData?.search_spoofing_reports || [])
      .filter(
        (report) =>
          !elasticSearchReportsData?.spoofing_reports?.some(
            (esReport) => esReport.id === report.id,
          ),
      )
      .map((report) => ({
        ...report,
        source: 'normal_search',
      }))
      .slice(
        0,
        Math.max(0, 5 - (elasticSearchReportsData?.spoofing_reports?.length || 0)),
      ),
  ]
  if (externalIdReport) {
    autocompleteReportsList.unshift({ ...externalIdReport, source: 'external_id' })
  }

  return (
    <Flex width={SEARCH_BAR_WIDTH} position="relative">
      <InputGroup
        className="input-group"
        size="md"
        onFocus={() => {
          const value = localSearchKey.trim()
          if (value) {
            handleSearch(value)
          }
          setShowAutocomplete(true)
        }}
      >
        <Input
          fontSize={13}
          onChange={(e) => {
            const value = e.target.value
            setLocalSearchKey(value)
            handleSearch(value.trim())
          }}
          onKeyDown={handleKeyDown}
          placeholder="Search"
          pr="4.5rem"
          value={localSearchKey}
        />

        <InputRightElement right="5px">
          <IconButton
            aria-label="Search"
            backgroundColor={DOPPEL_DARK_SECONDARY}
            fontSize={12}
            h="1.75rem"
            icon={<IoSearch />}
            onClick={() => {
              triggerSearch()
            }}
          ></IconButton>
        </InputRightElement>
      </InputGroup>

      <Flex
        borderRadius="8px"
        direction="column"
        overflow="hidden"
        position="absolute"
        top="100%"
        left="0"
        zIndex="1000"
        marginTop="5px"
      >
        {showAutocomplete &&
          autocompleteReportsList.map((report, index) => {
            return (
              <Box
                _hover={{
                  background: DOPPEL_SECURE,
                }}
                backgroundColor={DOPPEL_DARK_SECONDARY}
                cursor="pointer"
                key={index}
                onClick={() => {
                  autocompleteClickCallback(report.id)
                  setShowAutocomplete(false)
                }}
                padding="12px"
                ref={dropdownRef}
                width={SEARCH_BAR_WIDTH + 200}
                zIndex="2"
              >
                <Flex>
                  <Text fontSize={13} fontWeight="semibold" noOfLines={1}>
                    {report.flagged_url}
                    {isEmployeeView &&
                      elasticSearchEnabled &&
                      report.source === 'elasticsearch' && (
                        <Tag fontSize={9} marginLeft={2} colorScheme="blue">
                          ES
                        </Tag>
                      )}
                  </Text>

                  <Spacer />

                  <Tag fontSize={9} marginLeft={2} paddingX={2} size="xs">
                    {getStatusLabel(report.report_status, selectedReportType)}
                  </Tag>
                </Flex>

                {/* Show matched fields from ES if available, otherwise fall back to spoof matches */}
                {report.source === 'elasticsearch' && esHits
                  ? // Handle ES matched fields
                    (() => {
                      const hit = esHits.find((h) => h.alert_id === report.id)
                      const matchedFields = hit?.matched_fields
                      if (matchedFields && Object.entries(matchedFields).length > 0) {
                        const [firstKey, firstValues] = Object.entries(matchedFields)[0]
                        return (
                          <DoppelLink
                            fontSize={11}
                            name={`${firstKey}: ${firstValues[0]}${
                              Object.entries(matchedFields).length > 1
                                ? ` +${Object.entries(matchedFields).length - 1}`
                                : ''
                            }`}
                            noOfLines={1}
                          />
                        )
                      }
                      return null
                    })()
                  : // Fall back to old spoof matches logic
                    report.spoof_matches.map(
                      (match) =>
                        getSpoofMatchUrl(match) != report.flagged_url && (
                          <DoppelLink
                            fontSize={11}
                            name={getSpoofMatchUrl(match)}
                            noOfLines={1}
                          />
                        ),
                    )}
              </Box>
            )
          })}
      </Flex>
    </Flex>
  )
}

export default ReportsSearchBar
