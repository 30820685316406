import React from 'react'
import { useDisclosure, Stack, MenuButton } from '@chakra-ui/react'
import DoppelDefaultButton from '../shared/doppel_default_button'
import { IoMdArrowUp } from 'react-icons/io'
import { ReportType } from '@/utils/constants'
import { useSelectedReportType } from '@/pages/reports'
import UploadBulkDarkWebCredentialLeakReportModal from './dark_web/upload_new_bulk_darkweb_credential_leak_report_modal'
import UploadBulkDarkWebCreditCardLeakReportModal from './dark_web/upload_new_bulk_darkweb_credit_card_report_modal'
import { useFeatureFlagEnabled } from 'posthog-js/react'
import DoppelIconButton from '../shared/doppel_icon_button'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { Dropdown } from '../doppel_design/dropdown'

export default function UploadNewBulkReportContainer({ refreshFunc }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedReportType] = useSelectedReportType()
  const filterImprovementsEnabled = useFeatureFlagEnabled('filter-improvements')

  if (selectedReportType !== ReportType.DARK_WEB) {
    return <></>
  }
  return (
    <>
      <Stack direction="row" spacing={4}>
        {filterImprovementsEnabled ? (
          <Dropdown
            customMenuButton={
              <MenuButton>
                <DoppelIconButton
                  borderLeftRadius={
                    selectedReportType === ReportType.DARK_WEB ? '0' : ''
                  }
                  h={'40px'}
                  icon={<ChevronDownIcon h={5} w={5} />}
                  onClick={onOpen}
                  w={5}
                />
              </MenuButton>
            }
            items={[
              {
                label: 'Upload Bulk Alerts',
                value: 'uploadBulkAlerts',
                isInternal: false,
              },
            ]}
            onSelect={() => onOpen()}
          />
        ) : (
          <DoppelDefaultButton leftIcon={<IoMdArrowUp size="12px" />} onClick={onOpen}>
            Upload Bulk Alerts
          </DoppelDefaultButton>
        )}

        <UploadBulkDarkWebCredentialLeakReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />

        <UploadBulkDarkWebCreditCardLeakReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />
      </Stack>
    </>
  )
}
