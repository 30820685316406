import {
  DOPPEL_BORDER_GREY,
  DOPPEL_BUTTON_GREY,
  DOPPEL_BUTTON_GREY_SHADE,
  DOPPEL_INTERNAL_PURPLE,
  DOPPEL_TEXT_WHITE,
  FONT_SIZE_MEDIUM,
} from '@/utils/style'
import { Box, Button, Flex, HStack } from '@chakra-ui/react'
import { ReactElement } from 'react'
import { FiX } from 'react-icons/fi'
import DoppelIconButton from '../shared/doppel_icon_button'

type DeletableButtonProps = {
  /** Function called when the delete button is clicked */
  onDelete?: () => void
  /** Icon to display on the button */
  icon?: ReactElement
  /** Label to display on the button */
  label?: string | JSX.Element
  /** Font size for the label */
  fontSize?: string
  /** Whether the button is internal */
  isInternal?: boolean
  /** Height of the button */
  height?: number
  /** Additional props to pass to the underlying button element */
  [key: string]: any
}

export const DeletableButton = ({
  onDelete,
  icon,
  label,
  fontSize = '12px',
  isInternal = false,
  height = 9,
  ...props
}: DeletableButtonProps) => {
  return (
    <HStack align="center" spacing={0}>
      <Button
        _active={{ bg: DOPPEL_BUTTON_GREY_SHADE }}
        alignItems="center"
        bg={isInternal ? DOPPEL_INTERNAL_PURPLE : DOPPEL_BUTTON_GREY}
        border={`0.5px solid ${DOPPEL_BORDER_GREY}`}
        borderRightRadius={0}
        display="flex"
        flexShrink={0}
        fontSize={FONT_SIZE_MEDIUM}
        height={height}
        justifyContent="flex-start"
        maxWidth="140px"
        minWidth="100px"
        px={3}
        textColor={DOPPEL_TEXT_WHITE}
        width="fit-content"
        {...props}
      >
        <Flex
          alignItems="center"
          gap={2}
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          width="100%"
        >
          {icon}

          <Box
            as="span"
            flex="1"
            fontSize={fontSize}
            overflow="hidden"
            textOverflow="ellipsis"
            whiteSpace="nowrap"
          >
            {label}
          </Box>
        </Flex>
      </Button>

      <DoppelIconButton
        bg={isInternal ? DOPPEL_INTERNAL_PURPLE : DOPPEL_BUTTON_GREY}
        borderLeftRadius={0}
        buttonSize={6}
        flexShrink={0}
        height={height}
        icon={<FiX />}
        onClick={onDelete}
      />
    </HStack>
  )
}
