import React from 'react'
import UploadNewReportModal from './upload_new_report_modal'
import { useDisclosure, Stack } from '@chakra-ui/react'
import UploadDarkWebCreditCardReportModal from './dark_web/upload_new_darkweb_credit_card_report_modal'
import DoppelDefaultButton from '../shared/doppel_default_button'
import { IoMdArrowUp } from 'react-icons/io'
import { DarkWebPlatforms, ReportType } from '@/utils/constants'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'
import { useAtom } from 'jotai'
import { useSelectedReportType } from '@/pages/reports'
import UploadDarkWebCredentialLeakReportModal from './dark_web/upload_new_darkweb_credential_leak_report_modal'
import { SmallAddIcon } from '@chakra-ui/icons'
import DoppelIconButton from '@/components/shared/doppel_icon_button'
import { useFeatureFlagEnabled } from 'posthog-js/react'

export default function UploadNewReportContainer({ refreshFunc }) {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)
  const filterImprovementsEnabled = useFeatureFlagEnabled('filter-improvements')
  if (
    selectedReportType === ReportType.DARK_WEB &&
    [DarkWebPlatforms.GENERIC].includes(selectedReportExposureType)
  ) {
    return <></>
  }
  return (
    <>
      <Stack direction="row" spacing={4}>
        {filterImprovementsEnabled ? (
          <DoppelIconButton
            borderRightRadius={selectedReportType === ReportType.DARK_WEB ? '0' : ''}
            h={'40px'}
            icon={<SmallAddIcon h={5} w={5} />}
            onClick={onOpen}
            w={'40px'}
          />
        ) : (
          <DoppelDefaultButton
            leftIcon={<IoMdArrowUp size="12px" />}
            marginRight={4}
            onClick={onOpen}
          >
            Upload Alert
          </DoppelDefaultButton>
        )}

        <UploadNewReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />

        <UploadDarkWebCreditCardReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />

        <UploadDarkWebCredentialLeakReportModal
          isOpen={isOpen}
          onClose={onClose}
          refreshFunc={refreshFunc}
        />
      </Stack>
    </>
  )
}
