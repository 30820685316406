import React, { useEffect, useState } from 'react'
import { DateRangePicker, createStaticRanges } from 'react-date-range'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import styles from './react_date_range_override.module.css'
import {
  Box,
  HStack,
  PlacementWithLogical,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react'
import { DOPPEL_WHITE, FONT_SIZE_SMALL, DOPPEL_BORDER_GREY } from '@/utils/style'
import {
  addDays,
  addHours,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  endOfYear,
  startOfDay,
  startOfMonth,
  startOfWeek,
  startOfYear,
} from 'date-fns'
import { IoMdCalendar } from 'react-icons/io'
import { DateOnlyRange } from '@/components/stats/date_range'
import DropdownButton from '@/components/doppel_design/dropdown_button'
import DoppelPrimaryButton from '@/components/shared/doppel_primary_button'

/**
 * Uses new design for dropdowns; v1 will be deprecated with table view refactor
 */

export enum DateFilterLabels {
  TODAY = 'Today',
  SINCE_YESTERDAY = 'Since Yesterday',
  THIS_WEEK = 'This Week',
  MONTH_TO_DATE = 'Month To Date',
  YEAR_TO_DATE = 'Year To Date',
  LAST_7_DAYS = 'Last 7 Days',
  LAST_30_DAYS = 'Last 30 Days',
  LAST_90_DAYS = 'Last 90 Days',
  LAST_6_MONTHS = 'Last 6 Months',
  OLDER_THAN_24_HOURS = 'Older than 24 Hours',
  OLDER_THAN_48_HOURS = 'Older than 48 Hours',
  OLDER_THAN_72_HOURS = 'Older than 72 Hours',
}

const GLOBAL_MIN_DATE = new Date(2022, 0, 1)

const lastNDaysRange = (n: number) => (now: Date) =>
  new DateOnlyRange(addDays(startOfDay(now), 1 - n), startOfDay(now))

const lastNMonthsRange = (n: number) => (now: Date) =>
  new DateOnlyRange(addMonths(startOfMonth(now), 1 - n), startOfDay(endOfMonth(now)))

const olderThanNHoursRange = (n: number) => (now: Date) =>
  new DateOnlyRange(GLOBAL_MIN_DATE, addHours(now, -n))

export const DATE_FILTER_STATIC_RANGES: Record<
  DateFilterLabels,
  (now: Date) => DateOnlyRange
> = {
  [DateFilterLabels.TODAY]: lastNDaysRange(1),
  [DateFilterLabels.SINCE_YESTERDAY]: lastNDaysRange(2),
  [DateFilterLabels.THIS_WEEK]: (now) =>
    new DateOnlyRange(startOfWeek(now), startOfDay(endOfWeek(now))),
  [DateFilterLabels.MONTH_TO_DATE]: lastNMonthsRange(1),
  [DateFilterLabels.YEAR_TO_DATE]: (now) =>
    new DateOnlyRange(startOfYear(now), startOfDay(endOfYear(now))),
  [DateFilterLabels.LAST_7_DAYS]: lastNDaysRange(7),
  [DateFilterLabels.LAST_30_DAYS]: lastNDaysRange(30),
  [DateFilterLabels.LAST_90_DAYS]: lastNDaysRange(90),
  [DateFilterLabels.LAST_6_MONTHS]: lastNMonthsRange(6),
  [DateFilterLabels.OLDER_THAN_24_HOURS]: olderThanNHoursRange(24),
  [DateFilterLabels.OLDER_THAN_48_HOURS]: olderThanNHoursRange(48),
  [DateFilterLabels.OLDER_THAN_72_HOURS]: olderThanNHoursRange(72),
}

type DateFilterDropdownProps = {
  currentDateRange?: DateOnlyRange // expects timestamps in local time
  staticRangesToShow?: string[]
  dropdownPosition?: PlacementWithLogical
  onSelect?: (selectedRange: DateOnlyRange, label?: DateFilterLabels) => void
  minDate?: Date
  maxDate?: Date
  allowClearSelection?: boolean
  customMenuButton?: React.ReactNode // temporary, until filter bar redesign is implemented
}

export default function DateFilterDropdown({
  currentDateRange,
  staticRangesToShow,
  dropdownPosition = 'bottom-start',
  onSelect = () => {},
  minDate,
  maxDate,
  allowClearSelection,
  customMenuButton,
}: DateFilterDropdownProps) {
  const { isOpen, onOpen, onToggle, onClose } = useDisclosure()
  const defaultRange =
    currentDateRange || DATE_FILTER_STATIC_RANGES[DateFilterLabels.TODAY](new Date())
  const [selectedRange, setSelectedRange] = useState<DateOnlyRange>(defaultRange)

  useEffect(() => {
    setSelectedRange(defaultRange)
  }, [currentDateRange])

  const handleSelect = (...args) => {
    const [ranges] = args
    const { startDate, endDate, label } = ranges.selection
    setSelectedRange(new DateOnlyRange(startDate, endDate, label))
  }

  const onClearSelection = () => {
    onSelect(null)
    onClose()
  }

  const onApply = () => {
    onSelect(selectedRange)
    onClose()
  }

  // format expected by date picker library
  const staticRangesProp = createStaticRanges(
    (staticRangesToShow || Object.keys(DATE_FILTER_STATIC_RANGES)).map((label) => ({
      label: label,
      range: () => {
        const dateOnlyRange = DATE_FILTER_STATIC_RANGES[label](new Date())
        return {
          startDate: dateOnlyRange.startDate,
          endDate: dateOnlyRange.endDate,
          label: label,
        }
      },
    })),
  )
  const selectedRangeProp = {
    startDate: selectedRange.startDate,
    endDate: selectedRange.endDate,
    key: 'selection',
  }

  return (
    <Popover
      isOpen={isOpen}
      onClose={onClose}
      onOpen={onOpen}
      placement={dropdownPosition}
    >
      <PopoverTrigger>
        {customMenuButton ? (
          <Box>{customMenuButton}</Box>
        ) : (
          <DropdownButton
            border={`0.5px solid ${DOPPEL_BORDER_GREY}`}
            fontSize={FONT_SIZE_SMALL}
            icon={<IoMdCalendar />}
            label={currentDateRange?.toLocalShortDisplay?.() || 'Date Range'}
            onClick={onToggle}
          />
        )}
      </PopoverTrigger>

      <PopoverContent width="fit-content">
        <PopoverBody
          backgroundColor={DOPPEL_WHITE}
          borderRadius={4}
          width="fit-content"
        >
          <DateRangePicker
            className={styles.dateRangePicker}
            date={addHours(new Date(), 24)}
            inputRanges={[]}
            maxDate={maxDate || endOfDay(new Date())}
            minDate={minDate || GLOBAL_MIN_DATE}
            onChange={handleSelect}
            ranges={[selectedRangeProp]}
            staticRanges={staticRangesProp}
          />

          <HStack justifyContent="right" mt={2}>
            {allowClearSelection && (
              <DoppelPrimaryButton onClick={onClearSelection}>
                Clear Selection
              </DoppelPrimaryButton>
            )}

            <DoppelPrimaryButton isPrimaryColor onClick={onApply}>
              Apply
            </DoppelPrimaryButton>
          </HStack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  )
}
