import {
  Button,
  FormControl,
  FormHelperText,
  FormLabel,
  Heading,
  Stack,
  Textarea,
  Collapse,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useState, useRef } from 'react'
import { useOrgID, getOrgData, useUserID } from '@/hooks/id_token_claims'
import SideDrawer from '../../shared/side_drawer'
import {
  BUTTON_COLOR_PRIMARY,
  BUTTON_COLOR_PRIMARY_SHADE,
  DOPPEL_ERROR,
  DOPPEL_SUCCESS,
  DOPPEL_TEXT_BLACK,
} from '../../../utils/style'
import { useSelectedReportType } from '@/pages/reports'
import { DarkWebPlatforms, ReportType } from '@/utils/constants'
import {
  PlatformName,
  PlatformSubtype,
  ReportClassification,
  ReportSource,
  ReportStatus,
} from '@/generated/enums'
import {
  Order_By,
  useCreateDarkWebCredentialLeakReportMutation,
  useGetBrandNamesQuery,
  useGetPlatformSubtypesByNameQuery,
} from '@/generated/graphql'
import DropdownMenu from '../../shared/forms/dropdown_menu'
import { IoMdImages } from 'react-icons/io'
import { v4 as uuidv4 } from 'uuid'
import { UploadedDarkwebReport } from '../form_components/uploaded_darkweb_report'
import { useAtom } from 'jotai'
import { reportPlatformTabAtom } from '@/hooks/dark_web_platforms'

export default function UploadBulkDarkWebCredentialLeakReportModal({
  isOpen,
  onClose,
  refreshFunc,
}) {
  const orgId = useOrgID()
  const [orgData] = getOrgData()
  const [userId] = useUserID()
  const [csvData, setCsvData] = useState('')
  const [selectedBrand, setSelectedBrand] = useState(null)
  const [isUploading, setIsUploading] = useState(false)
  const [errors, setErrors] = useState([])
  const [reports, setReports] = useState([])
  const firstField = useRef()
  const [selectedReportType] = useSelectedReportType()
  const [selectedReportExposureType, setSelectedReportExposureType] =
    useAtom(reportPlatformTabAtom)

  const [createDarkWebCredentialLeakReport] =
    useCreateDarkWebCredentialLeakReportMutation()
  const { data: subtypeData } = useGetPlatformSubtypesByNameQuery({
    variables: { name: PlatformSubtype.CRED_LEAKS },
  })
  const credLeaksPlatformSubtypeId = subtypeData?.platform_subtypes?.[0]?.id

  const { data: brands } = useGetBrandNamesQuery({
    variables: {
      where: { organization_id: { _eq: orgId } },
      order_by: [{ entity_name: Order_By.Asc }],
    },
  })

  const reset = () => {
    setCsvData('')
    setSelectedBrand(null)
    setIsUploading(false)
    setErrors([])
    setReports([])
  }

  const onSubmitDarkWebCredentialLeak = () => {
    if (!credLeaksPlatformSubtypeId) return
    setReports([])
    setErrors([])

    setIsUploading(true)

    const parsedData = csvData
      .split(/\n+/)
      .map((line) => {
        const [credentialUrl, email, password, flaggedUrl] = line
          .split(/[\t, ]+/)
          .map((item) => item.trim())

        return { credentialUrl, email, password, flaggedUrl: flaggedUrl || null }
      })
      .filter(
        ({ credentialUrl, email, password }) => credentialUrl && email && password,
      )

    const reportSubmissions = parsedData.map(
      ({ credentialUrl, email, password, flaggedUrl }) => {
        const darkWebId = uuidv4()

        const submitInput = {
          organization_id: orgData && orgData.id,
          brand_id: selectedBrand && selectedBrand.id,
          flagged_url: darkWebId.toString(),
          type: PlatformName.CRED_LEAKS,
          platform_subtype_id: credLeaksPlatformSubtypeId,
          source: ReportSource.UI_UPLOAD,
          report_status: ReportStatus.NEEDS_ACTION,
          spoofing_status: ReportClassification.ACTIVE,
          uploader_id: userId,
          suspicion_score: 10,
          classification: ReportClassification.ACTIVE,
          location: flaggedUrl,
          dark_web_id: darkWebId,
          dark_web_network: null, // TODO eventually categorize this correctly
          credentials: `${email}:${password}`,
          email: email,
          password: password,
          credential_url: credentialUrl,
        }

        return createDarkWebCredentialLeakReport({
          variables: submitInput,
        })
          .then((result) => {
            const resultingReport = result.data?.insert_spoofing_reports?.returning?.[0]
            if (resultingReport) {
              setReports((prev) => [...prev, resultingReport])
            } else if (result?.errors) {
              setErrors((prev) => [
                ...prev,
                { location: flaggedUrl || 'Unknown', error: result.errors[0].message },
              ])
            }
          })
          .catch((error) => {
            setErrors((prev) => [
              ...prev,
              {
                location: flaggedUrl || 'Unknown',
                error: error.message || 'Unknown error occurred',
              },
            ])
          })
      },
    )

    Promise.all(reportSubmissions).finally(() => {
      setIsUploading(false)
      if (errors.length === 0) {
        setCsvData('')
        setTimeout(() => {
          refreshFunc()
        }, 1500)
      }
    })
  }

  if (
    selectedReportType !== ReportType.DARK_WEB ||
    selectedReportExposureType !== DarkWebPlatforms.CRED_LEAKS
  ) {
    return <></>
  }

  return (
    <SideDrawer
      firstField={firstField}
      isOpen={isOpen}
      onClose={() => {
        reset()
        onClose()
      }}
    >
      <Stack spacing="24px">
        <Heading as="h2" size="md">
          Upload Dark Web Credential Leak Alert
        </Heading>

        <FormControl>
          <FormLabel>Brand</FormLabel>

          <DropdownMenu
            buttonDisplayFunction={(selectedItems) => selectedItems[0] || 'Brand'}
            icon={<IoMdImages size="18" />}
            isMultiSelect={false}
            items={
              brands ? brands.spoofing_entities.map((brand) => brand.entity_name) : []
            }
            placeholder={'Search Brands...'}
            selectedItems={[selectedBrand?.entity_name]}
            setSelectedItems={([brandName]) => {
              const selectedBrand = brands.spoofing_entities?.find(
                (b) => b.entity_name === brandName,
              )
              setSelectedBrand(selectedBrand)
            }}
            showSearchBar={true}
            w="100%"
          />

          <FormHelperText>Brand to action all entities under</FormHelperText>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>CSV Input</FormLabel>

          <Textarea
            onChange={(e) => setCsvData(e.target.value)}
            placeholder="credential_url, email, password, entity"
            ref={firstField}
            value={csvData}
          />

          <FormHelperText>
            Enter the CSV data with values in the following order: credential URL,
            email, password, entity. Each entry should be on a new line.
          </FormHelperText>
        </FormControl>

        <Button
          _hover={{ bgColor: BUTTON_COLOR_PRIMARY_SHADE }}
          bgColor={BUTTON_COLOR_PRIMARY}
          color={DOPPEL_TEXT_BLACK}
          isDisabled={isUploading || !csvData || !selectedBrand}
          isLoading={isUploading}
          loadingText="Uploading"
          onClick={onSubmitDarkWebCredentialLeak}
        >
          Upload
        </Button>

        <Collapse animateOpacity in={errors.length > 0}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_ERROR}
            borderRadius="lg"
            direction="column"
            marginTop={2}
            padding="4"
            textColor={'white'}
          >
            <Text fontSize="lg" fontWeight="bold" marginBottom={2}>
              Errors
            </Text>

            {errors.map((error) => (
              <Flex
                bgColor="rgba(255, 0, 0, 0.2)"
                borderRadius="md"
                direction="column"
                key={error.location}
                marginBottom={2}
                padding="4"
              >
                <Text fontWeight="bold">URL: {error.location}</Text>

                <Text>Error: {error.error}</Text>
              </Flex>
            ))}
          </Flex>
        </Collapse>

        <Collapse animateOpacity in={reports.length > 0}>
          <Flex
            align="flex-start"
            bgColor={DOPPEL_SUCCESS}
            borderRadius="lg"
            direction="column"
            padding="4"
            textColor={'white'}
          >
            <Text fontSize="lg" fontWeight="bold" marginBottom={2}>
              Successfully Uploaded Reports
            </Text>

            {reports.map((report) => (
              <UploadedDarkwebReport
                brand_id={report.original_entity_id}
                flagged_url={report.spoof_matches?.[0]?.dark_web?.location}
                id={report.id}
                key={report.id}
                platform={report.type}
                status={report.report_status}
              />
            ))}
          </Flex>
        </Collapse>
      </Stack>
    </SideDrawer>
  )
}
