import { Center, Text } from '@chakra-ui/react'
import React from 'react'
import { DOPPEL_WHITE_NEW, DOPPEL_PROFILE_BACKGROUND } from '@/utils/style'
import DropdownButton from './dropdown_button'

const PROFILE_CLICK_STYLES = {
  borderColor: DOPPEL_WHITE_NEW,
  borderWidth: '1px',
  bg: DOPPEL_PROFILE_BACKGROUND,
}

export type AvatarButtonProps = {
  /** The initials to display in the avatar (usually 1-2 characters) */
  initials: string
  /** Whether the button should be used as part of a menu */
  isMenuButton?: boolean
  /** Additional button props to customize the button */
  [key: string]: any
}

/**
 * A circular avatar button that displays initials (1-3 characters).
 * Can be used as a standalone button or as part of a dropdown menu.
 */
const AvatarButton: React.FC<AvatarButtonProps> = ({
  initials,
  isMenuButton = false,
  ...props
}: AvatarButtonProps) => {
  return (
    <DropdownButton
      _active={{ ...PROFILE_CLICK_STYLES }}
      _hover={{ ...PROFILE_CLICK_STYLES }}
      bg={DOPPEL_PROFILE_BACKGROUND}
      borderRadius="100%"
      display="flex"
      height="40px"
      label={
        <Center width="100%">
          <Text fontWeight={600}>{initials?.substring(0, 3)}</Text>
        </Center>
      }
      pl={0}
      pr={0}
      rightIcon={null}
      useMenuButton={isMenuButton}
      width="40px"
      {...props}
    />
  )
}

export default AvatarButton
