import { Box, Flex, MenuButton, Text } from '@chakra-ui/react'
import {
  BaseFilter,
  getFilterValues,
  upsertFilterListForType,
} from '@/hooks/base_filters'
import { useSelectedReportFilters } from '../../pages/reports'
import DropdownMenu from '../shared/forms/dropdown_menu'
import { ReportFilterType } from '@/utils/constants'
import { getTruncatedText } from '@/utils/string_utils'
import { useEffect } from 'react'
import { DeletableButton } from '../doppel_design/deletable_button'
import { BOOLEAN_FILTERS } from '../web2/spoof_reports_table_filter_bar'
import { BooleanFilterOptions } from '@/generated/enums'
import { removeFilterByType } from '@/hooks/report_table_filters'
import { FONT_SIZE_SMALL } from '@/utils/style'

export const SHOW_ALL_FILTER_VALUE = 'Show All'
const MAX_DISPLAY_LENGTH = 10

export const useSelectedContext = (context) => {
  const [selectedReportFilters, setSelectedReportFilters] = useSelectedReportFilters()

  if (context) {
    return context
  }

  return [selectedReportFilters, setSelectedReportFilters]
}

type Props<FilterType> = {
  filterType: FilterType
  dropdownIcon: JSX.Element
  filterOptions: BaseFilter<FilterType>[]
  alphabetizeFilterOptions?: boolean
  valueToDisplayFunction?: (value: string) => string
  textMaxWidth?: string
  w?: string
  isMultiSelect?: boolean
  showSearchBar?: boolean
  placeholder?: string // for search bar, if enabled
  isRequired?: boolean // only for single select; removes ability to clear
  disableNoneSelected?: boolean
  context?: [BaseFilter<FilterType>[], (filters: BaseFilter<FilterType>[]) => void]
  isInternal?: boolean
  onSelect?: (values: string[]) => void
  initialValues?: string[]
  alwaysEnableClearSelection?: boolean
  onClear?: () => void
  useNewButton?: boolean
  [key: string]: any
}

const FilterDropdown = <FilterType extends string>({
  filterType,
  dropdownIcon,
  filterOptions,
  alphabetizeFilterOptions = true,
  valueToDisplayFunction = (value) => {
    return value
  },
  textMaxWidth = null,
  isMultiSelect = false,
  showSearchBar = false,
  placeholder = null,
  isRequired = false,
  disableNoneSelected = !isMultiSelect,
  context = null,
  isInternal = false,
  maxDisplayLength = MAX_DISPLAY_LENGTH,
  disabled = false,
  w = 'fit-content',
  onSelect,
  initialValues = null,
  alwaysEnableClearSelection = false,
  onClear = null,
  useNewButton = false,
  ...props
}: Props<FilterType>) => {
  const [selectedFilters, setSelectedFilters] = useSelectedContext(context)

  const onSelectionChange = (values: string[]) => {
    // avoid interleaving issues by passing a function
    const shouldRemoveFilter =
      BOOLEAN_FILTERS.has(filterType as ReportFilterType) &&
      values.includes(BooleanFilterOptions.FALSE)

    setSelectedFilters((prevFilters) =>
      shouldRemoveFilter
        ? removeFilterByType(filterType as ReportFilterType, prevFilters)
        : upsertFilterListForType(filterType, values, prevFilters),
    )
    if (onSelect) onSelect(values)
  }

  // Set initial filter values
  useEffect(() => {
    if (initialValues) {
      onSelectionChange(initialValues)
    }
  }, []) // Empty dependency array ensures this only runs once on mount

  const displayFilterType = valueToDisplayFunction(filterType)

  const selectedFilterOptions = selectedFilters.filter(
    (filter: BaseFilter<FilterType>) => filter.filterType === filterType,
  )

  const buttonDisplayFunction = (selectedItems: any[]) => {
    let content: string
    if (selectedItems.length > 0) {
      if (isMultiSelect) {
        content = `${displayFilterType} (${selectedItems.length})`
      } else {
        content = valueToDisplayFunction(selectedItems[0])
      }
    } else {
      content = displayFilterType
    }
    content = getTruncatedText(content, maxDisplayLength)

    return (
      <Text fontSize={FONT_SIZE_SMALL} maxWidth={textMaxWidth}>
        {content}
      </Text>
    )
  }

  if (!filterOptions) {
    return null
  }

  const filterValues = getFilterValues(filterOptions)
  if (alphabetizeFilterOptions) {
    filterValues.sort((a, b) => {
      a = valueToDisplayFunction(a)
      b = valueToDisplayFunction(b)
      return a.localeCompare(b, 'en', { sensitivity: 'base' })
    })
  }

  const newCustomButton = useNewButton ? (
    <Box display="flex">
      <DeletableButton
        as={MenuButton}
        icon={dropdownIcon}
        isInternal={isInternal}
        label={buttonDisplayFunction(getFilterValues(selectedFilterOptions))}
        onDelete={() => {
          onClear && onClear()
          onSelectionChange([])
        }}
      />
    </Box>
  ) : null

  return (
    <Flex w={w} {...props}>
      <DropdownMenu
        alwaysEnableClearSelection={alwaysEnableClearSelection}
        buttonDisplayFunction={buttonDisplayFunction}
        clearSelectionOptionText={disableNoneSelected ? null : SHOW_ALL_FILTER_VALUE}
        customButton={newCustomButton}
        disableClearSelection={isRequired}
        disabled={disabled}
        icon={dropdownIcon}
        isInternal={isInternal}
        isMultiSelect={isMultiSelect}
        itemDisplayFunction={(item) => valueToDisplayFunction(item)}
        items={filterValues}
        placeholder={placeholder ?? `Search for ${displayFilterType.toLowerCase()}...`}
        selectedItems={getFilterValues(selectedFilterOptions)}
        setSelectedItems={onSelectionChange}
        showSearchBar={showSearchBar}
        w={w}
      />
    </Flex>
  )
}

export const ReportFilterDropdown = FilterDropdown<ReportFilterType>
export default FilterDropdown
